import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { IoTrashOutline } from "react-icons/io5";
import DeleteCheckModal from "../Modal/DeleteCheckModal";
import { setLogVisibility } from "../../model/LogModel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DeleteLogButton({ logId }: { logId: string | undefined }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="outline-secondary"
        className="w-100"
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <IoTrashOutline size="30"></IoTrashOutline> Excluir mergulho
      </Button>

      <DeleteCheckModal
        shouldShow={showDeleteModal}
        message={t("delete_log_check")}
        setShowDeleteCheck={setShowDeleteModal}
        proceed={() => {
          setLogVisibility(logId, "deleted");
          setShowDeleteModal(false);
          navigate("/");
        }}
      ></DeleteCheckModal>
    </>
  );
}

export default DeleteLogButton;
