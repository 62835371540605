import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/NavBar/NavBar";
import { Badge, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baptismDives } from "../data/dives";
import { Dive } from "../interfaces/dive";
import DiveCard from "../components/Cards/DiveCard";
import DiveBookingInfo from "../components/BookingInfo/DiveBookingInfo";
import Footer from "../components/Footer/Footer";
import { TbCertificateOff } from "react-icons/tb";
import { GiSwimfins } from "react-icons/gi";
import ContactCard from "../components/Cards/ContactCard";

function Baptism() {
  const [dives, setDives] = useState<Array<Dive>>(baptismDives);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setDives(baptismDives);
  }, []);

  return (
    <>
      <Helmet>
        <title>Mergulho de Batismo e Discovery Scuba - GDN</title>
        <meta
          name="description"
          content="Mergulhe pela primeira vez com cilindro com nossos mergulhos de batismo e atividades de Discovery Scuba Diving."
        />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/" className="no-style-link">
              {" "}
              Voltar ao início
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Batismo de mergulho</h3>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xl={6} className="mb-2">
            <Card className="shadow">
              <Card.Img
                src="/images/articles/baptism/baptism-3.jpeg"
                variant="top"
                className="rounded-1"
              />
              <div className="card-img-overlay start-0">
                <Badge>Discovery scuba</Badge>
              </div>
            </Card>
          </Col>
          <Col xl={6} className="text-justify mb-2">
            <h4 className="px-3 mb-3">O que é o mergulho de batismo?</h4>
            <p className="px-3">
              Mergulhar é uma atividade incrível que permite aos participantes
              explorar um mundo completamente novo debaixo d'água. No entanto,
              para pessoas que nunca mergulharam antes, pode ser um pouco
              assustador mergulhar pela primeira vez. É aí que entra o mergulho
              de batismo.
            </p>
            <p className="px-3">
              O mergulho de batismo e discovery scuba são atividades de mergulho
              projetadas especificamente para pessoas que nunca mergulharam com
              cilindro antes ou que ainda não possuem nenhuma certificação de
              mergulho. É uma oportunidade para conhecer as maravilhas do mundo
              subaquático sem nenhuma preocupação, com a ajuda de um instrutor
              experiente, que irá fornecer todas as informações necessárias
              sobre o equipamento e acompanhará você durante toda a atividade.
            </p>
            <p className="px-3">
              Nós oferecemos mergulhos de batismo e discovery somente em pontos
              de mergulho que julgamos serem capazes de mudar sua maneira de ver
              o oceano e a vida marinha, atravéz de uma experiência única e
              inesquecível.
            </p>
            <p className="px-3">
              Conheça os locais que indicamos e saiba mais sobre sua primeira
              experiência de mergulho:
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3>Nossos mergulhos de batismo e discovery</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3 mb-5">
          {dives?.map((d, i) => (
            <Col>
              <DiveCard dive={d} showLocation={true} />
            </Col>
          ))}
        </Row>

        <Row className="mb-5">
          <Col xl={12} className="text-justify mb-2">
            <hr />
            <article>
              <h4 className="px-3 mb-3 mt-4">
                Porque fazer um Mergulho de Batismo ou Discovery?
              </h4>
              <p className="px-3">
                O mergulho de batismo e o discovery scuba proporcionam à pessoas
                sem formação em mergulho a oportunidade de conhecer um mundo
                novo cheio de cores, vida e beleza. É muito comum que seja a
                porta de entrada para uma vida inteira de aventuras e viagens
                incríveis, mesmo que por muitas vezes aconteça quase que por
                acaso, despretenciosamente, durante uma viagem de férias em
                família ou com amigos.
              </p>
              <p className="px-3">
                Ambas atividades são uma ótima maneira de experimentar o
                mergulho com cilindro pela primeira vez e ver se é algo que você
                gostaria de continuar a fazer. Recomendamos sempre a realização
                de pelo menos uma destas atividades antes do ingresso em um
                curso ou treinamento de mergulho.
              </p>
              <p className="px-3">
                São atividades seguras, divertidas e permitem que os
                participantes aprendam algo completamente novo e façam uma coisa
                muito emocionante pela primeira vez.
              </p>

              <hr />

              <h4 className="px-3 mb-3 mt-4">
                Qual é a diferença entre Batismo e Discovery?
              </h4>
              <p className="px-3">
                O Discovery Scuba é uma experiência mais completa que pode ter
                dentre suas atividades, o mergulho de batismo.
              </p>
              <p className="px-3">
                No discovery, os participantes têm uma aula prática de mergulho
                em piscina com os mesmos equipamentos utilizados no oceano. O
                objetivo desta aula é fazer uma introdução aos equipamentos e
                técnicas básicas de mergulho.
              </p>
              <p className="px-3">
                O batismo, propriamente dito, é o primeiro mergulho com cilindro
                no oceano. Sempre com o acompanhamento de um mergulhador
                profissional e após as devidas instruções.
              </p>
              <p className="px-3">
                Recomendamos que pessoas que se sentirem um pouco mais ansiosas
                ao pensarem em mergulhar pela primeira vez optem pelo discovery.
                A primeira aula em piscina é bastante tranquila e pode ajudar a
                gerar a confiança necessária para aproveitar ao máximo o batismo
                no oceano.
              </p>

              <hr />
              <h4 className="px-3 mb-3 mt-4">Como é o Mergulho de Batismo?</h4>
              <p className="px-3">
                Antes de começar o mergulho, o instrutor irá fornecer uma breve
                aula de segurança e orientação sobre como usar o equipamento de
                mergulho. Isso incluirá como respirar debaixo d'água, como
                controlar a flutuabilidade e como se comunicar com seu parceiro
                de mergulho. Em seguida, é hora de entrar na água.
              </p>
              <p className="px-3">
                Durante o mergulho, o instrutor estará com você o tempo todo,
                guiando-o e fornecendo assistência se necessário. O mergulho
                geralmente dura cerca de 20 a 30 minutos e é realizado em águas
                rasas, geralmente com uma profundidade de até 12 metros. Isso
                permite que os participantes desfrutem da vida marinha sem se
                preocupar em atingir profundidades maiores.
              </p>
              <p className="px-3">
                Depois do mergulho, é hora de subir de volta à superfície e
                descarregar o equipamento. O instrutor irá conversar com você
                sobre o mergulho e responder a quaisquer perguntas que você
                possa ter.
              </p>

              <hr />
              <h4 className="px-3 mb-3 mt-4">
                Quem pode fazer um Mergulho de Batismo?
              </h4>
              <p className="px-3">
                É importante lembrar que, embora o mergulho de batismo seja uma
                atividade segura, indicada para pessoas com pouca ou nenhuma
                experiência com mergulho, é sempre importante seguir as
                instruções do instrutor e seguir as regras de segurança do
                mergulho.
              </p>
              <p className="px-3">
                É também importante notar que algumas pessoas podem ter
                condições médicas que podem impedir a realização de mergulho,
                então é importante consultar um médico antes de iniciar qualquer
                tipo de treinamento ou atividade de mergulho.
              </p>
            </article>
            <hr />
          </Col>
        </Row>

        <Row>
          <Col>
            <h4 className="ps-3">Informações importantes:</h4>
          </Col>
        </Row>
        <ListGroup className="mb-5">
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <GiSwimfins size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">Todos equipamentos incluídos</div>
                  Todos os equipamentos necessários para a realização desta
                  atividade já estão incluídos no preço e estarão disponíveis
                  para o mergulhador no dia do mergulho, caso necessite. <br />
                  Os equipamentos disponibilizados são:
                  <ul>
                    <li>Máscara;</li>
                    <li>Nadadeiras;</li>
                    <li>Roupa de neoprene para mergulho;</li>
                    <li>Regulador;</li>
                    <li>Colete equilibrador;</li>
                    <li>Lastro.</li>
                  </ul>
                  Após a confirmação da reserva, entraremos em contato para
                  coletar suas informações para que possamos determinar o
                  tamanho ideal dos equipamentos e reservá-los para você.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <TbCertificateOff size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">
                    Não é necessária experiência prévia
                  </div>
                  Você não precisa ter mergulhado nenhuma vez antes e nenhum
                  treinamento prévio, curso de mergulho ou certificação é
                  necessária para a realização desta atividade.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <DiveBookingInfo />
        <Row>
          <Col>
            <ContactCard />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Baptism;
