import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillWarning } from "react-icons/ai";

function DeleteCheckModal({
  shouldShow,
  message,
  setShowDeleteCheck,
  proceed,
}: {
  shouldShow: boolean;
  message: string;
  setShowDeleteCheck: Function;
  proceed: Function;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      show={shouldShow}
      onHide={() => {
        setShowDeleteCheck(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <Row className="mt-4 mb-4">
          <Col>
            <AiFillWarning size={100} className="text-danger" />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>{message}</Col>
        </Row>
        <hr></hr>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="m-2 mx-3"
              onClick={() => proceed()}
            >
              {t("delete")}
            </Button>
            <Button
              variant="primary"
              className="m-2 mx-3"
              onClick={() => setShowDeleteCheck(false)}
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteCheckModal;
