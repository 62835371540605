export const FAQ_PT = {
  faq: "Perguntas frequentes",
  back: "voltar ao início",
  description:
    "Respostas para perguntas frequentes realcionadas a atividades de mergulho, cursos de mergulho, público e batismo de mergulho.",
  q1: "Precisa de experiência prévia ou curso para mergulhar?",
  a1: "Para o mergulho de batismo não é necessária experiência anterior nem curso. Durante o mergulho de batismo o instrutor credenciado ficará junto a você, garantindo segurança e tranquilidade para sua diversão. \n\nPara o mergulho credenciado é exigida sua certificação de mergulhador e especializações (quando for o caso), de acordo com o ponto de mergulho desejado.",
  q2: "Qual a diferença entre mergulho de batismo, mergulho credenciado e curso de mergulho?",
  a2: "Mergulho de batismo é uma modalidade para iniciantes ou pessoas sem certificação e por isso sem exigência de experiência e com acompanhamento integral de um instrutor. \n\nMergulho credenciado são mergulhos que requerem uma certificação de mergulho. Curso de mergulho é o processo para se adquirir uma certificação de mergulho, contemplando níveis, experiência e avaliações.",
  q3: "Preciso saber nada para mergulhar de cilindro?",
  a3: "Não é preciso saber nadar para mergulhar; o equipamento possui um sistema de flutuabilidade próprio e você estará acompanhado de um instrutor/divemaster.",
  q4: "Qual a idade mínima para mergulhar?",
  a4: "A idade mínima oficial para a primeira experiência de mergulhar e também realizar cursos de certificações é de 10 anos.",
  q5: "Mergulhar de cilindro é perigoso?",
  a5: "Apesar de todas as medidas de segurança, o mergulho é um esporte e requer algumas atenções. Se seguidas as boas práticas, repassadas as informações corretas sobre o estado de sua saúde e respeitadas as orientações dadas no briefing, o risco de acidentes é muito baixo.",
  q6: "Há restrições ou quadro clínico de saúde que pode inviabilizar a atividade de mergulho?",
  a6: "Doenças cardíacas, respiratórias (incluindo resfriados, gripes e sinusites), perfuração de tímpano, gestantes, convulsões ou cirurgias recentes inviabilizam a prática do mergulho. O ideal é consultar um médico especializado e ser honesto no questionário de saúde que será solicitado no ato da reserva.",
  q7: "Como funciona a compra online de mergulho?",
  a7: "Ao completar a reserva online e efetuar o pagamento, você receberá todas as informações necessárias via e-mail no mesmo momento. Através do e-mail solicitaremos as informações/documentos necessários ao mergulho, e deverão ser entregues até 48h antes de data/horário agendados para a atividade de mergulho.",
  q8: "Posso levar acompanhante?",
  a8: "São permitidos acompanhantes, tendo um valor especial para acompanhar a operação. Em alguns casos há opção do acompanhante que não tiver snorkel já alugar e aproveitar o passeio de uma forma mais interativa (quando o ambiente permitir, tendo em primeiro lugar a segurança).",
  q9: "Todos os equipamentos são fornecidos?",
  a9: "Para as atividades de batismo/discovery e cursos/treinamentos estão inclusos todos os equipamentos; \nPara o mergulho credenciado estará especificado em cada um o que está incluso ou não.",
};
