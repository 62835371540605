import React from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function LoadingModal({ shouldShow }: { shouldShow: boolean }) {
  const { t } = useTranslation();

  return (
    <Modal centered backdrop="static" keyboard={false} show={shouldShow}>
      <Modal.Body className="text-center">
        <Row className="mt-4">
          <Col>
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>{t("loading")}...</Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;
