import React from "react";
import NavBar from "../components/NavBar/NavBar";
import { Container, Row, Col } from "react-bootstrap";
import { BsLifePreserver } from "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";

function NotFound() {
  return (
    <>
      <NavBar></NavBar>
      <Container className="text-center mt-5">
        <Helmet>
          <title>Página não encontrada - GDN</title>
          <meta
            name="description"
            content="A página que você está tentando acessar não existe."
          />
        </Helmet>
        <Row>
          <Col className="mt-3">
            <h1>Página não encontrada</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <BsLifePreserver size="100" />
            <div className="mt-3">
              Vamos <Link to="/">voltar</Link> para o barco?
            </div>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default NotFound;
