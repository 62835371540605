import React from "react";
import { Comment } from "../../interfaces/interfaces";
import { ListGroup } from "react-bootstrap";
import CommentItem from "./CommentItem";

function CommentsList({ comments }: { comments: Comment[] }) {
  const commentItems = comments?.map((c, i) => (
    <CommentItem comment={c} index={i} key={i}></CommentItem>
  ));

  return <ListGroup>{commentItems}</ListGroup>;
}

export default CommentsList;
