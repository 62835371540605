import React from "react";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

function EmailVerification() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("verify_email")} - Global Divers Network</title>
        <meta
          name="description"
          content={t("verify_email") + " - Global Divers Network" || ""}
        />
      </Helmet>
      <Modal centered show={true}>
        <Modal.Body className="text-center">
          <h3>{t("verify_email")}</h3>
          <hr></hr>
          <p>
            {t("sent_verification_link")}
            <br />
            {t("to_finish_registration")}
          </p>
          <p>
            {t("if_not_found")}, <br />
            {t("check_spam")}.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EmailVerification;
