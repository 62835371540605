import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function RangeDatePicker({
  startInXDays,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startInXDays?: number;
  startDate: Date;
  endDate: Date | null;
  setStartDate: Function;
  setEndDate: Function;
}) {
  const startIn = startInXDays
    ? new Date(Date.now() + startInXDays * 24 * 60 * 60 * 1000)
    : new Date();

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      minDate={startIn}
      selectsRange
      inline
    />
  );
}

export default RangeDatePicker;
