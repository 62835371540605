import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import PlacesInput from "../../components/Autocomplete/PlacesInput";
import NavBar from "../../components/NavBar/NavBar";
import { useIMask } from "react-imask";
import { DiveCenterInitialState } from "../../interfaces/interfaces";
import { BsInstagram } from "react-icons/bs";
import { GrFacebook } from "react-icons/gr";
import { FaTripadvisor, FaYoutube } from "react-icons/fa";
import { auth, db } from "../../config/firebase";
import FileUploader from "../../components/FileUploader/FileUploader";
import { omitBy, isNil } from "lodash";
import { now } from "moment";
import { collection, doc, setDoc } from "firebase/firestore";
import { RiseLoader } from "react-spinners";

function Registration() {
  const location = useLocation();
  const initialState = location.state ? location.state : DiveCenterInitialState;
  const [state, setState] = useState(initialState);
  const [diveActivities, setDiveActivities] = useState<Array<string>>(
    state.diveActivities ? state.diveActivities : []
  );
  const [courses, setCourses] = useState<Array<string>>([]);
  const [certifiers, setCertifiers] = useState<Array<string>>([]);
  const [photo, setPhoto] = useState(state.image || "");
  const [opts, setOpts] = useState({ mask: "(00)00000-0000" });
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { ref, value } = useIMask(opts);

  useEffect(() => {
    if (!auth.currentUser) navigate("/login");
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.name === "phone") {
      setState({ ...state, phone: e.target.value });
      return;
    }

    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  function handleChangeDives(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setDiveActivities([...diveActivities, e.target.name]);
    } else {
      const dives = diveActivities.filter((t: string) => t !== e.target.name);
      setDiveActivities(dives);
    }
  }

  function handleCourses(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setCourses([...courses, e.target.name]);
    } else {
      const filtered = courses.filter((t: string) => t !== e.target.name);
      setCourses(filtered);
    }
  }

  function handleCertifier(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setCertifiers([...certifiers, e.target.name]);
    } else {
      const filtered = certifiers.filter((t: string) => t !== e.target.name);
      setCertifiers(filtered);
    }
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      window.scrollTo(0, 0);
      alert("Por favor, preencha os campos obrigatórios antes de prosseguir.");
      return;
    }

    setLoading(true);

    const properties = {
      createdBy: {
        userId: auth.currentUser?.uid,
        userName: auth.currentUser?.displayName,
        userPicture: auth.currentUser?.photoURL,
      },
      createdAt: now(),
      name: state.name,
      address: state.address,
      phone: state.phone,
      email: state.email,
      instagram: state?.instagram,
      facebook: state?.facebook,
      youtube: state?.youtube,
      tripAdvisor: state?.tripAdvisor,
      divingActivities: diveActivities,
      diveSites: state?.diveSites,
      courses,
      certifiers,
      about: state?.about,
      otherInfo: state?.otherInfo,
      image: photo,
    };

    const definedProperties = omitBy(properties, isNil);

    try {
      const diveCenterRef = doc(collection(db, "diveCenters"));

      await setDoc(diveCenterRef, definedProperties);
      alert("Informações salvas com sucesso. Entraremos em contato em breve.");
      navigate("/dive-center/summary", { state: definedProperties });
    } catch (err) {
      alert("Houve um erro e a requisição não pode ser concluída.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <NavBar></NavBar>
      <Helmet>
        <title>Cadastro de Operadora de Mergulho</title>
        <meta
          name="description"
          content="Formulário para cadastro de novas operadoras de mergulho."
        />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <Card className="mt-3 p-3">
              <Card.Body>
                <h1 className="display-6">Cadastro de Operadora de Mergulho</h1>
                <Form.Text className="text-muted">
                  Os dados informados neste formulário serão utilizados como
                  fonte para o cadastro de possíveis operadoras parceiras do
                  nosso clube de mergulho. O preenchimento destas informações
                  não representa a imediata participação no clube; entraremos em
                  contato para discutir os termos e seguir com o processo de
                  adesão. <br /> Prezamos pela qualidade do serviço e segurança
                  de nossos assinantes, e por isso estamos convidando somente
                  operadoras que conhecemos e/ou que nos foram indicadas por
                  escolas de mergulho com as quais já temos parceria, em locais
                  que avaliamos como os mais buscados por nosso perfil de
                  usuário. Por esse motivo, pedimos que não compartilhem este
                  link com nenhuma pessoa ou organização. <br />
                  Somente as informações de contato são obrigatórias, mas
                  lembre-se de que quanto maior for o número e a qualidade das
                  respostas, menor será o tempo e esforço nas próximas etapas de
                  cadastro.
                </Form.Text>
                <hr></hr>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Card>
                    <Card.Body>
                      <h3>Informações de contato</h3>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Nome da Operadora
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={state.name}
                              onChange={handleChange}
                              required
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Endereço completo
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <PlacesInput
                              name="address"
                              state={state}
                              setState={setState}
                              className="form-control"
                              types={["address"]}
                              placeholder="Pesquisar"
                            ></PlacesInput>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              WhatsApp<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="phone"
                              value={value || state.phone}
                              ref={ref}
                              placeholder="(00)00000-0000"
                              onChange={handleChange}
                              required
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Email<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={state.email}
                              placeholder="example@email.com"
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>
                      <h3>Redes sociais</h3>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Instagram:</Form.Label>
                            <InputGroup>
                              <InputGroup.Text id="btnGroupAddon">
                                <BsInstagram />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="instagram"
                                value={state.instagram}
                                onChange={handleChange}
                              ></Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Facebook:</Form.Label>
                            <InputGroup>
                              <InputGroup.Text id="btnGroupAddon">
                                <GrFacebook />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="facebook"
                                value={state.facebook}
                                onChange={handleChange}
                              ></Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Youtube:</Form.Label>
                            <InputGroup>
                              <InputGroup.Text id="btnGroupAddon">
                                <FaYoutube />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="youtube"
                                value={state.youtube}
                                onChange={handleChange}
                              ></Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Trip Advisor:</Form.Label>
                            <InputGroup>
                              <InputGroup.Text id="btnGroupAddon">
                                <FaTripadvisor />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="tripAdvisor"
                                value={state.tripAdvisor}
                                onChange={handleChange}
                              ></Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>
                      <h3>Mergulhos regulares</h3>
                      <Form.Text className="text-muted">
                        Selecione somente as atividades realizadas regularmente
                        nas suas operações de mergulho, desde sua sede, em
                        pontos de mergulho próximos.
                        <br />
                        Estas informações serão utilizadas como filtro adicional
                        ao geográfico para que mergulhadores possam escolher sua
                        operadora para relizar os mergulhos do clube.
                      </Form.Text>
                      <hr></hr>
                      <Row>
                        <Col md={6}>
                          <Form.Group onChange={handleChangeDives}>
                            <Form.Check
                              type="checkbox"
                              name="baptism"
                              label="Batismo"
                            />
                            <Form.Check
                              type="checkbox"
                              name="deep"
                              label="Mergulho Profundo"
                            />
                            <Form.Check
                              type="checkbox"
                              name="night"
                              label="Mergulho Noturno"
                            />
                            <Form.Check
                              type="checkbox"
                              name="wreck"
                              label="Naufrágio"
                            />
                            <Form.Check
                              type="checkbox"
                              name="drift"
                              label="Drift"
                            />
                            <Form.Check
                              type="checkbox"
                              name="cave"
                              label="Mergulho em Caverna"
                            />
                            <Form.Check
                              type="checkbox"
                              name="altitude"
                              label="Mergulho em Altitude"
                            />
                            <Form.Check
                              type="checkbox"
                              name="free"
                              label="Mergulho Livre"
                            />
                            <Form.Check
                              type="checkbox"
                              name="tech"
                              label="Mergulho Técnico"
                            />
                            <Form.Check
                              type="checkbox"
                              name="snorkel"
                              label="Snorkel"
                            />
                            <Form.Check
                              type="checkbox"
                              name="shore"
                              label="Saída de Praia"
                            />
                            <Form.Check
                              type="checkbox"
                              name="boat"
                              label="Saída Embarcadas"
                            />
                            <Form.Check
                              type="checkbox"
                              name="lake"
                              label="Mergulho em lago"
                            />
                            <Form.Check
                              type="checkbox"
                              name="nitrox"
                              label="Nitrox/EANx"
                            />
                            <Form.Check
                              type="checkbox"
                              name="shark"
                              label="Mergulho com tubarões"
                            />
                            <Form.Check
                              type="checkbox"
                              name="sideMount"
                              label="Sidemount"
                            />
                            <Form.Check
                              type="checkbox"
                              name="rebreather"
                              label="Rebreather"
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col md={6}></Col> */}
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>
                      <h3>Pontos de mergulho</h3>
                      <Form.Text className="text-muted">
                        Liste os pontos de mergulho mais interessantes ou
                        frequentemente visitados em suas operações. Isso ajuda,
                        principalmente, a atrair mergulhadores que estiveram
                        anteriormente na região.
                      </Form.Text>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="diveSites"
                          value={state.diveSites}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Row>
                        <Col md={6}></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>
                      <h3>Certificações</h3>
                      <Form.Text className="text-muted">
                        Selecione a(s) certificadora(s) vinculada(s) à sua
                        operadora e que a autorizam a ministrar cursos e emitir
                        certificações, caso se aplique. <br />
                        Selecione todos os cursos que podem ser ministrados. Não
                        se preocupe com o nome exato e inclua cursos com
                        conteúdo e nível equivalentes. O nome exato será
                        vinculado à certificadora posteriormente.
                      </Form.Text>
                      <hr></hr>
                      <Row>
                        <Col md={6}>
                          <h5>Unidade(s) certificadora(s)</h5>
                          <Form.Group onChange={handleCertifier}>
                            <Form.Check
                              type="checkbox"
                              name="padi"
                              label="PADI"
                            />
                            <Form.Check
                              type="checkbox"
                              name="ssi"
                              label="SSI"
                            />
                            <Form.Check
                              type="checkbox"
                              name="sdi"
                              label="SDI"
                            />
                            <Form.Check
                              type="checkbox"
                              name="tdi"
                              label="TDI"
                            />
                            <Form.Check
                              type="checkbox"
                              name="cmas"
                              label="CMAS"
                            />
                            <Form.Check
                              type="checkbox"
                              name="bsac"
                              label="BSAC"
                            />
                            <Form.Check
                              type="checkbox"
                              name="naui"
                              label="NAUI"
                            />
                            <Form.Check
                              type="checkbox"
                              name="raid"
                              label="RAID"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <h5>Cursos</h5>
                          <Form.Group onChange={handleCourses}>
                            <Form.Check
                              type="checkbox"
                              name="openWater"
                              id="openWater"
                              label="Open water"
                            />
                            <Form.Check
                              type="checkbox"
                              name="advancedOpenWater"
                              id="advancedOpenWater"
                              label="Advanced Open Water"
                            />
                            <Form.Check
                              type="checkbox"
                              name="nitrox"
                              id="nitrox"
                              label="Nitrox/EANx"
                            />
                            <Form.Check
                              type="checkbox"
                              id="rescue"
                              name="rescue"
                              label="Rescue"
                            />
                            <Form.Check
                              type="checkbox"
                              id="diveMaster"
                              name="diveMaster"
                              label="Dive Master"
                            />
                            <Form.Check
                              type="checkbox"
                              id="deep"
                              name="deep"
                              label="Mergulho Profundo"
                            />
                            <Form.Check
                              type="checkbox"
                              id="assistantInstructor"
                              name="assistantInstructor"
                              label="Instrutor Assistente"
                            />
                            <Form.Check
                              type="checkbox"
                              name="instructor"
                              id="instructor"
                              label="Instrutor"
                            />
                            <Form.Check
                              type="checkbox"
                              name="freeDiver"
                              id="freeDiver"
                              label="Mergulho Livre"
                            />
                            <Form.Check
                              type="checkbox"
                              name="nightDive"
                              id="nightDive"
                              label="Mergulho Noturno"
                            />
                            <Form.Check
                              type="checkbox"
                              name="navigation"
                              id="navigation"
                              label="Navegação"
                            />
                            <Form.Check
                              type="checkbox"
                              name="searchAndRescue"
                              id="searchAndRescue"
                              label="Busca e Recuperação"
                            />
                            <Form.Check
                              type="checkbox"
                              name="wreck"
                              id="wreck"
                              label="Naufrágio"
                            />
                            <Form.Check
                              type="checkbox"
                              name="cave"
                              id="cave"
                              label="Mergulho em Caverna"
                            />
                            <Form.Check
                              type="checkbox"
                              name="buoyancy"
                              id="buoyancy"
                              label="Flutuabilidade"
                            />
                            <Form.Check
                              type="checkbox"
                              name="photo"
                              id="photo"
                              label="Foto sub"
                            />
                            <Form.Check
                              type="checkbox"
                              name="video"
                              id="video"
                              label="Video sub"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>
                      <h3>Sobre</h3>
                      <Form.Text className="text-muted">
                        Inclua um texto de apresentação ao perfil da sua
                        operadora, caso assim o desejar:
                      </Form.Text>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="about"
                              value={state.about}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>
                      <h3>Outras informações</h3>
                      <Form.Text className="text-muted">
                        Você deseja adicionar outras informações ao seu perfil
                        que não foram contempladas neste formulário?
                      </Form.Text>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="otherInfo"
                              value={state.otherInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Row>
                    <Col md={6}>
                      <Card className="text-center mt-3">
                        <Card.Body>
                          <h3>Logo</h3>
                          <Form.Text className="text-muted">
                            Envie uma imagem do logo da operadora, ou outra
                            imagem que desejar, clicando na câmera abaixo:
                          </Form.Text>
                          <FileUploader
                            setPhoto={setPhoto}
                            setLoading={setLoading}
                            photo={photo}
                          ></FileUploader>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    disabled={loading}
                    className="mt-3 w-100"
                  >
                    {loading ? (
                      <RiseLoader size={5} color="#fff" />
                    ) : (
                      "Enviar formulário"
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Registration;
