import React, { ChangeEventHandler } from "react";
import { Row, Col, FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import { LoggerProps } from "../../interfaces/interfaces";

function LoggerAdditionalForm({
  handleChange,
  state,
}: {
  handleChange: ChangeEventHandler;
  state: LoggerProps;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col md={3}>
          {" "}
          <FloatingLabel label={t("starting_gas") + " (bar)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="startingGas"
              value={state.startingGas}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={3}>
          <FloatingLabel label={t("final_gas") + " (bar)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="finalGas"
              value={state.finalGas}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={3}>
          {" "}
          <FloatingLabel label={t("weighting") + " (Kg)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="weighting"
              value={state.weighting}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={3}>
          <FloatingLabel label={t("visibility") + " (m)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="visibility"
              value={state.visibility}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          {" "}
          <FloatingLabel label={t("average_depth") + " (m)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="averageDepth"
              value={state.averageDepth}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={3}>
          {" "}
          <FloatingLabel label={t("deco_time") + " (min)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="noDecoTime"
              value={state.noDecoTime}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={3}>
          {" "}
          <FloatingLabel label={t("temperature") + " (°C)"}>
            <Form.Control
              type="number"
              className="mt-2"
              name="temperature"
              value={state.temperature}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={3}>
          {" "}
          <FloatingLabel label={t("water_temperature") + " (°C)"}>
            <Form.Control
              type="number"
              className="mt-2"
              name="waterTemperature"
              value={state.waterTemperature}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
      </Row>

      <hr className="my-5"></hr>

      <Row>
        <Col md>
          <Form.Text muted>{t("notes")}</Form.Text>
          <Form.Control
            as="textarea"
            className="mt-2"
            name="notes"
            value={state.notes}
            onChange={handleChange}
            style={{ minHeight: "150px" }}
          />
        </Col>
      </Row>

      <Row className="my-3">
        <Col lg={3}>
          {" "}
          <Form.Text muted className="mx-1">
            {t("who_can_see")}
          </Form.Text>
          <Form.Group className="mt-1">
            <Form.Select
              name="logVisibility"
              onChange={handleChange}
              defaultValue="everyone"
            >
              <option value="everyone">{t("everyone")}</option>
              {/* <option value="followers">{t("followers")}</option> */}
              {/* <option value="owner">{t("only_me")}</option> */}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default LoggerAdditionalForm;
