import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function Padroes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="text-justify mt-4">
        <Row>
          <Col>
            <Helmet>
              <title>Padrões da Comunidade - GDN</title>
              <meta
                name="description"
                content="Documento que descreve os padrões esperados da comunidade virtual de mergulho Global Divers Network."
              />
            </Helmet>
            <h1>Padrões da comunidade Global Divers Network</h1>
            <small className="text-mutted">
              Data de vigência: 27 de Janeiro de 2023
            </small>
            <hr></hr>
            <p>
              Ser parte da comunidade Global Divers Network é estar comprometido
              a respeitar. Nós respeitamos uns aos outros, nós mesmos e as
              regras. Quando o respeito é compartilhado, todo mundo sai
              ganhando. A seguir descrevemos como devemos nos respeitar e o que
              é esperado dos membros da nossa comunidade.
            </p>
            <h3>Respeite-se</h3>
            <p>
              A segurança é a coisa mais importante em um mergulho. Sempre
              respeite seus limites, os limites impostos por seu nível de
              qualificação e de suas certificações. Sempre{" "}
              <strong>respeite os limites descompressivos</strong>.
              <br />
              Use o bom senso e considere todos os riscos antes de mergulhar.
              Busque sempre a companhia de profissionais qualificados,
              experientes e em quem você confie. Não se exponha a riscos
              desnecessários.
            </p>

            <h3>Direito a privacidade</h3>
            <p>
              Você tem o controle do que compartilha na Global Divers Network.
              Utilize nossas ferramentas para compartilhar somente o que desejar
              e mantenha somente para si, em privado, toda a atividade que
              desejar, antes que ela seja publicada.
            </p>
            <h2>Respeito mútuo</h2>
            <hr></hr>
            <h3>Seja gentil</h3>
            <p>
              Trate todas as pessoas bem, virtualmente ou não. Diga oi. Deixe
              seus likes. Comente. É bem provável que você fará muitas novas
              amizades por aqui :D
            </p>
            <h3>Não ao conteúdo ofensivo</h3>
            <p>
              Nós contamos com todos em nossa comunidade para ajudar a
              identificar conteúdo impróprio. Se você identificou algum conteúdo
              que não considera adequado, entre em contato conosco através do
              email contato@globaldiversnetwork.com ou avise as autoridades
              locais.
            </p>
            <h3>Poste conteúdo original</h3>
            <p>
              Nós adoramos ver seus mergulhos e respeitamos todo o conteúdo
              compartilhado em nossa comunidade. Não poste conteúdo alheio sem
              autorização e entre em contato caso seu conteúdo tenha sido usado
              de forma inadequada.
            </p>
            <h3>Não ao racismo. Sim a inclusão</h3>
            <p>
              Nós combatemos o racismo e a discriminação ativamente em nossa
              comunidade. Nos ajude denunciando qualquer conteúdo ou atitude
              imprópria. Tomaremos ações duras para combater qualquer ato
              racista ou discriminatório.
            </p>
            <h3>Não ao discurso de ódio</h3>
            <p>
              Discurso de ódio é um ataque direto baseado na raça, idade, etnia,
              origem, nacionalidade, religião, orientação sexual, casta, sexo,
              gênero, identidade de gênero, doenças, deficiências, corpo, entre
              tantos outros. Removeremos qualquer tipo de discurso de ódio da
              nossa plataforma e tomaremos todas as medidas cabíveis para coibir
              atos desta natureza.
            </p>
            <h2>Respeite as regras</h2>
            <hr></hr>
            <h3>Obedeça a lei</h3>
            <p>
              Nós esperamos que usuários da Global Divers Network respeitem
              todas as leis. Podemos mergulhar em lugares muitos diversos e com
              legislações diferente. Informe-se da lei local e respeite. Sempre.
            </p>
            <h3>Siga as instruções das operadoras</h3>
            <p>
              Busque informações na comunidade sobre operadoras de mergulho. É
              importante certificar-se da competência e qualidade da operação
              para a sua segurança e das outras pessoas envolvidas. Sempre
              obedeça as instruções e siga o plano.
            </p>
            <h3>Respeite a natureza</h3>
            <p>Preserve. Mantenha as praias e o oceano limpos.</p>
            <h3>Compartilhe conhecimento</h3>
            <p>
              Ajude as pessoas da comunidade na sua evolução compartilhando seu
              conhecimento e experiência. Todo mundo tem o que ensinar e
              queremos aprender juntos.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default Padroes;
