import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { Dive } from "../../interfaces/dive";
import {
  TbCertificate,
  TbCertificateOff,
  TbSailboat,
  TbScubaMask,
  TbSpeedboat,
} from "react-icons/tb";
import { CiApple } from "react-icons/ci";
import { MdOutlineBeachAccess } from "react-icons/md";
import {
  GiScubaTanks,
  GiSwimfins,
  GiThreeFriends,
  GiWoodenPier,
} from "react-icons/gi";
import { includes } from "lodash";

function DivePropsList({ dive }: { dive: Dive }) {
  const entering = () => {
    switch (dive.entering) {
      case "boat":
        return (
          <>
            <ListGroup.Item
              as="li"
              className="justify-content-between align-items-start py-3"
            >
              <Row>
                <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                  <TbSailboat size="35" className="ms-2 mt-1" />
                </Col>
                <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                  <div className="ms-1 me-4 text-justify">
                    <div className="fw-bold">Mergulho embarcado</div>
                    Passeio de barco até o ponto de mergulho incluído no preço.
                    O tempo total de navegação depende do(s) ponto(s)
                    escolhidos, das condições climáticas, condição do oceano e
                    da maré. Entre em contato conosco para uma previsão mais
                    precisa proximo à data do seu mergulho. Estamos sempre a
                    disposição para esclarecer suas dúvidas.
                  </div>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="justify-content-between align-items-start py-3"
            >
              <Row>
                <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                  <CiApple size="35" className="ms-2 mt-1" />
                </Col>
                <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                  <div className="ms-1 me-4 text-justify">
                    <div className="fw-bold">Comida e bebida na embarcação</div>
                    Snacks(frutas, biscoitos, guloseimas, entre outros),
                    refrigerantes e água disponíveis para consumo durante a
                    navegação e paradas de superfície entre os mergulhos.
                  </div>
                </Col>
              </Row>
            </ListGroup.Item>
          </>
        );
      case "shore":
        return (
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <MdOutlineBeachAccess size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">Saída de praia</div>O ponto de
                  encontro deste mergulho é a beira mar e a atividade tem
                  início, entrada e equipagem na praia.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        );
      case "pier":
        return (
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <GiWoodenPier size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">Saída de píer</div>Este mergulho tem
                  ponto de encontro e início a partir de um píer.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        );
      case "dinghy":
        return (
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <TbSpeedboat size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">Saída em bote</div>Passeio em bote
                  até o ponto de mergulho incluído no preço. O tempo total de
                  navegação depende do(s) ponto(s) escolhidos, das condições
                  climáticas, condição do oceano e da maré. Entre em contato
                  conosco para uma previsão mais precisa proximo a data do seu
                  mergulho. Estamos sempre a disposição para esclarecer suas
                  dúvidas.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        );
    }
  };

  const companions = () => {
    if (dive.accompanyAllowed)
      return (
        <>
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <GiThreeFriends size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">
                    Traga sua família e amigos para o passeio
                  </div>
                  Este passeio não é destinado apenas a mergulhadores. Traga sua
                  família, amigos e quem mais desejar para aproveitar o dia
                  junto com você. Basta selecionar o número total de
                  acompanhantes antes de efetuar a reserva.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="justify-content-between align-items-start py-3"
          >
            <Row>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
                <TbScubaMask size="35" className="ms-2 mt-1" />
              </Col>
              <Col xs={10} sm={10} md={11} lg={11} xl={11}>
                <div className="ms-1 me-4 text-justify">
                  <div className="fw-bold">Snorkel disponível</div>
                  Proporcione ainda mais emoção ao passeio da sua família e
                  amigos com a atividade de mergulho de superfície com snorkel.
                  Disponibilizamos o equipamento completo individual, mediante
                  reserva. Basta selecionar o número completo de acompanhantes
                  que farão a atividade antes de efetuar a compra.
                </div>
              </Col>
            </Row>
          </ListGroup.Item>{" "}
        </>
      );
  };

  const certificate = () => {
    if (dive.requiredCertification === "none") {
      return (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              <TbCertificateOff size="35" className="ms-2 mt-1" />
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">
                  Não é necessária experiência prévia
                </div>
                Você não precisa ter mergulhado nenhuma vez antes e nenhum
                treinamento prévio, curso de mergulho ou certificação é
                necessária para a realização desta atividade.
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      );
    }
    if (dive.requiredCertification === "Open Water") {
      return (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              <TbCertificate size="35" className="ms-2 mt-1" />
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">
                  Para mergulhadores com certificação
                </div>
                Este mergulho é exclusivo para mergulhadores credenciados em uma
                entidade reconhecida. A certificação mínima necessária é a de
                mergulho autônomo em águas abertas. Ex: Open Water Diver, Open
                Water Scuba Diver, One Star Diver ou similar de igual ou maior
                nível.
                <br />
                <strong>
                  {" "}
                  É obrigatória a apresentação de documento ou carteirinha
                  física/digital que comprove a aptidão do mergulhador.
                </strong>
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      );
    }
    if (dive.requiredCertification === "Advanced Open Water") {
      return (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              <TbCertificate size="35" className="ms-2 mt-1" />
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">
                  Para mergulhadores com certificação avançada
                </div>
                Este mergulho é exclusivo para mergulhadores de nível avançado
                credenciados em uma entidade reconhecida. A certificação mínima
                necessária é a de mergulho autônomo avançado em águas abertas.
                Ex: Avanced Open Water Diver, Advanced Adventure Diver, Two Star
                Diver ou similar de igual ou maior nível.
                <br />
                <strong>
                  {" "}
                  É obrigatória a apresentação de documento ou carteirinha
                  física/digital que comprove a aptidão do mergulhador para a
                  atividade.
                </strong>
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      );
    }
  };

  const cylinders = () => {
    if (dive.cylinders === 1) {
      return (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              <GiScubaTanks size="35" className="ms-2 mt-1" />
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">Mergulho com cilindro</div>
                Esta a atividade inclui no total 1(um) cilindro de ar comprimido
                para mergulho.
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      );
    }
    if (dive.cylinders === 2) {
      return (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              <GiScubaTanks size="35" className="ms-2 mt-1" />
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">Mergulho com cilindro</div>
                Esta a atividade inclui no total 2(dois) cilindros de ar
                comprimido para mergulho.
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      );
    }
  };

  const equipment = () => {
    if (includes(dive.equipment, "all"))
      return (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              <GiSwimfins size="35" className="ms-2 mt-1" />
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">Todos equipamentos incluídos</div>
                Todos os equipamentos necessários para a realização desta
                atividade já estão incluídos no preço e estarão disponíveis para
                o mergulhador no dia do mergulho, caso necessite. <br />
                Os equipamentos disponibilizados são:
                <ul>
                  <li>Máscara;</li>
                  <li>Nadadeiras;</li>
                  <li>Roupa de neoprene para mergulho;</li>
                  <li>Regulador;</li>
                  <li>Colete equilibrador;</li>
                  <li>Lastro.</li>
                </ul>
                Após a confirmação da reserva, entraremos em contato para
                coletar suas informações para que possamos determinar o tamanho
                ideal dos equipamentos e reservá-los para você.
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      );
  };

  return (
    <>
      <Row>
        <Col>
          <h4 className="ps-3">Sobre o mergulho:</h4>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <ListGroup className="shadow">
            {entering()}
            {equipment()}
            {certificate()}
            {companions()}
            {cylinders()}
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}

export default DivePropsList;
