import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <Container className="mt-5">
        <footer className="py-4">
          <Row>
            <Col md={6} lg={4} xl={3} className="mb-3">
              <h5>GDN</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to="/where-we-are" className="no-style-link">
                    Onde estamos
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/dive/baptism" className="no-style-link">
                    Batismo de mergulho
                  </Link>
                </li>
                {/* <li className="nav-item mb-2">
                  <Link to="/dashboard" className="no-style-link">
                    Experiências de Mergulho
                  </Link>
                </li> */}
                <li className="nav-item mb-2">
                  <Link to="/courses/open-water" className="no-style-link">
                    Open water scuba diver
                  </Link>
                </li>
                {/* <li className="nav-item mb-2">
                  <Link to="/logger" className="no-style-link">
                    Logbook de mergulho
                  </Link>
                </li> */}
                <li className="nav-item mb-2">
                  <Link to="/faq" className="no-style-link">
                    Perguntas frequentes
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/contact-us" className="no-style-link">
                    Fale conosco
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={6} lg={4} xl={3} className="mb-3">
              {/* <Link to="/articles/pt" className="no-style-link">
                <h5>{t("diving_articles")}</h5>
              </Link>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link
                    to="/articles/pt/mergulho-com-tubaroes-no-brasil"
                    className="no-style-link"
                  >
                    Mergulho com tubarões
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/articles/pt/mergulho-de-batismo"
                    className="no-style-link"
                  >
                    Mergulho de batismo
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/articles/pt/mergulho-naufragios-pernambuco"
                    className="no-style-link"
                  >
                    Naufrágios de Pernambuco
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/articles/pt/mergulho-naufragio-vapor-bahia"
                    className="no-style-link"
                  >
                    Naufrágio Vapor Bahia
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/articles/pt/naufragio-virgo"
                    className="no-style-link"
                  >
                    Naufrágio Virgo
                  </Link>
                </li>
              </ul> */}
            </Col>

            <Col md={6} lg={4} xl={3} className="mb-3">
              <h5>{t("policies")}</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link
                    to="/policies/pt/padroes-da-comunidade"
                    className="no-style-link"
                  >
                    Normas da comunidade
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/policies/pt/termos-de-uso"
                    className="no-style-link"
                  >
                    {" "}
                    Termos de uso e serviço
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/policies/pt/sales-contract"
                    className="no-style-link"
                  >
                    {" "}
                    Contrato de serviço
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/policies/pt/politica-de-privacidade"
                    className="no-style-link"
                  >
                    Política de privacidade
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/policies/pt/politica-de-cookies"
                    className="no-style-link"
                  >
                    Política de cookies
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/policies/pt/bases-juridicas"
                    className="no-style-link"
                  >
                    Base jurídica
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>

          <div className="d-flex justify-content-between py-4 mt-4 border-top">
            <p>&copy; 2023 Global Divers Network. All rights reserved.</p>
            <ul className="list-unstyled d-flex">
              <li className="me-2">
                <a
                  href="https://www.youtube.com/channel/UCqnsI1UXYAfh5iXyATEV6-g"
                  target="_blank"
                  rel="noreferrer"
                  className="no-style-link"
                >
                  <BsYoutube size="35" />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/gdn.brasil?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                  className="no-style-link"
                >
                  <BsInstagram size="30" />
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </Container>
    </>
  );
}

export default Footer;
