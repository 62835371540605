import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FiShare } from "react-icons/fi";
import { Log } from "../../interfaces/interfaces";
import WhatsAppBtn from "../ShareButtons/WhatsAppBtn";
import { MdOutlineContentCopy } from "react-icons/md";

function ShareModal({
  shouldShow,
  resource,
  setShouldShow,
}: {
  shouldShow: boolean;
  resource: Log;
  setShouldShow: Function;
}) {
  const { t } = useTranslation();

  function copyLink() {
    navigator.clipboard.writeText(
      `https://globaldiversnetwork.com/?redirect-to=log&id=${resource.logId}`
    );
    alert(t("link") + " " + t("copied").toLowerCase());
  }

  return (
    <Modal
      centered
      show={shouldShow}
      onHide={() => {
        setShouldShow(false);
      }}
    >
      <Modal.Header closeButton>
        {" "}
        <FiShare size="30" className="me-2" />{" "}
        <strong>{t("share") + " " + t("dive").toLowerCase()}</strong>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Row className="mt-4 mb-4">
          <Col>
            <QRCodeSVG
              value={`https://globaldiversnetwork.com/?redirect-to=log&id=${resource.logId}`}
              size={256}
              includeMargin={false}
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>{t("share_this_dive")}</Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>
            <WhatsAppBtn variant="share-log" resource={resource}></WhatsAppBtn>
          </Col>
        </Row>
        <Row className="mt-2 mb-4 fs-5">
          <Col>
            <Button variant="dark" size="lg" onClick={() => copyLink()}>
              <MdOutlineContentCopy size="30" className="mx-2" />
              {t("copy") + " " + t("link").toLowerCase()}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ShareModal;
