import React from "react";
import { BsGlobe } from "react-icons/bs";

function GDNy({ className, size }: { className?: string; size?: string }) {
  return (
    <div className={`text-danger text-center gdn fs-5 ${className}`}>
      <BsGlobe className="text-danger" size={size || "100"} /> <br />
      Global Divers Network
    </div>
  );
}

export default GDNy;
