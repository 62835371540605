export const LOGBOOK_TAGS = [
  "Scuba",
  "night_dive",
  "baptism",
  "checkout",
  "drift",
  "deep_dive",
  "wreck",
  "cave_dive",
  "free_dive",
  "technical_dive",
  "ice_dive",
  "altitude",
  "snorkeling",
  "shore_diving",
  "boat_dive",
  "lake",
  "tide",
  "nitrox",
  "dive",
  "buoyancy",
  "navigation",
  "dry_suit",
  "photo_video",
  "shark_dive",
  "sidemount",
  "spearfishing",
];

export const DIVER_TAGS = [
  "diver_to_be",
  "open_water",
  "advanced_open_water",
  "freediver",
  "advanced_freediver",
  "specialty_diver",
  "dive_master",
  "rescue_diver",
  "assistant_instructor",
  "freediving_instructor",
  "instructor",
  "specialty_instructor",
  "instructor_trainer",
  "tech_diver",
  "tech_instructor",
  "diving_agency_owner",
  "travel_agency_owner",
  "hotel_owner",
];
