import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoadingModal({ shouldShow }: { shouldShow: boolean }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function redirect(url: string) {
    navigate(url);
  }

  return (
    <Modal
      centered
      show={shouldShow}
      onHide={() => {
        redirect("/");
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <Row className="mt-4 mb-4">
          <Col>
            <GiCheckMark size={100} className="text-success" />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>{t("successfully_processed")}...</Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;
