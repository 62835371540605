import React from "react";
import { Comment } from "../../interfaces/interfaces";
import { ListGroup } from "react-bootstrap";
import ImageCircle from "../Images/ImageCircle";

function CommentItem({ comment, index }: { comment: Comment; index: number }) {
  return (
    <ListGroup.Item className="d-flex justify-content-between text-start border-0 px-0">
      <ImageCircle image={comment.userPicture} size="40"></ImageCircle>
      <div className="bg-light mx-2 w-100 pt-1 border rounded-2 p-2">
        <p className="px-2 text-box">{comment.messageText}</p>
      </div>
    </ListGroup.Item>
  );
}

export default CommentItem;
