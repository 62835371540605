import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { Course } from "../../interfaces/course";
import { mergulhoMagico } from "../../data/courses";
import CourseCard from "../../components/Cards/CourseCard";

function CourseSearch() {
  const [courses, setCourses] = useState<Array<Course> | null>(null);
  useEffect(() => {
    setCourses(mergulhoMagico);
  }, []);

  return (
    <>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3">
          {courses?.map((c, i) => (
            <Col>
              <CourseCard course={c} />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default CourseSearch;
