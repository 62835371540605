import React from "react";
import { Button } from "react-bootstrap";

function Counter({
  total,
  maxTotal,
  state,
  setState,
}: {
  total: number;
  maxTotal: number;
  state: number;
  setState: Function;
}) {
  const disableSubtract = () => {
    return state === 0 ? true : false;
  };

  const disableAdd = () => {
    return total >= maxTotal ? true : false;
  };

  return (
    <div>
      <Button
        variant="outline-primary"
        size="sm"
        disabled={disableSubtract()}
        onClick={() => setState(state - 1)}
      >
        -
      </Button>
      <span className="mx-3">{state}</span>
      <Button
        variant="outline-primary"
        size="sm"
        disabled={disableAdd()}
        onClick={() => setState(state + 1)}
      >
        +
      </Button>
    </div>
  );
}

export default Counter;
