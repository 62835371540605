import React, { useEffect, useState } from "react";
import { find } from "lodash";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import {
  DIVELIST,
  MAX_AVAILABLE_PLACES,
  MAX_BOOKING_ADVANCE,
} from "../../data/dives";
import { Link, useParams } from "react-router-dom";
import { Dive } from "../../interfaces/dive";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Counter from "../../components/Counter/Counter";
import moment from "moment";
import DiveBookingInfo from "../../components/BookingInfo/DiveBookingInfo";
import DivePropsList from "../../components/DiveDetails/DivePropsList";
import ContactCard from "../../components/Cards/ContactCard";

function DiveDetails() {
  const { id } = useParams();
  const [dive, setDive] = useState<Dive | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18next.language === "pt" ? "pt" : "en";
  const minDate = new Date(
    Date.now() + (dive?.minBookingAdvance || 3) * 24 * 60 * 60 * 1000
  );
  const [date, setDate] = useState<Date | null>(minDate);
  const [totalDivers, setTotalDivers] = useState(1);
  const [totalAccompaniers, setTotalAccompaniers] = useState(0);
  const [totalSnorkel, setTotalSnorkel] = useState(0);
  const [totalPeople, setTotalPeople] = useState(1);
  const [maxPeople, setMaxPeople] = useState(MAX_AVAILABLE_PLACES);
  const [placesAvailable, setPlacesAvailable] = useState(maxPeople);
  const [diversPrice, setDiversPrice] = useState(0);
  const [accompaniersPrice, setAccompaniersPrice] = useState(0);
  const [snorkelPrice, setSnorkelPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const findDive = find(DIVELIST, { id });
    findDive ? setDive(findDive) : navigate("/not-found");
  }, []);

  useEffect(() => {
    if (dive && dive.maximumSeats) setMaxPeople(dive.maximumSeats);
    if (dive && dive.price) {
      setDiversPrice(totalDivers * dive.price);
      setTotalPrice(totalDivers * dive.price);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [dive]);

  useEffect(() => {
    setTotalPeople(totalDivers + totalAccompaniers + totalSnorkel);
    if (dive && dive.price) setDiversPrice(totalDivers * dive.price);
    if (dive && dive.accompanyAllowed && dive.accompanyPrice)
      setAccompaniersPrice(totalAccompaniers * dive.accompanyPrice);
    if (dive && dive.accompanyAllowed && dive.accompanySnorkelPrice)
      setSnorkelPrice(totalSnorkel * dive.accompanySnorkelPrice);
  }, [totalDivers, totalAccompaniers, totalSnorkel]);

  useEffect(() => {
    setTotalPrice(diversPrice + accompaniersPrice + snorkelPrice);
  }, [diversPrice, accompaniersPrice, snorkelPrice]);

  useEffect(() => {
    setPlacesAvailable(maxPeople - totalPeople);
  }, [totalPeople]);

  const handleSubmit = () => {
    const participants = `${
      totalDivers > 0 ? totalDivers + "x " + t("divers") + "." : ""
    } ${
      totalAccompaniers > 0
        ? totalAccompaniers + "x " + t("accompaniers") + "."
        : ""
    } ${
      totalSnorkel > 0
        ? totalSnorkel + "x " + t("accompaniers") + " + Snorkel."
        : ""
    }`;

    const productDescription =
      dive?.productDescription[lang] +
      ". " +
      t("date") +
      ": " +
      moment(date).format("DD-MM-YYYY") +
      ". " +
      participants;

    const items = [
      {
        id: dive?.id,
        title: productDescription,
        description: productDescription,
        picture_url:
          "https://lh6.googleusercontent.com/f2dWePJVIYrpCfCm746TtfpbGphbZA2DAyLhje-1xmWxFWZ2lgQuVCNEEhHtSq1bZBs=w2400",
        currency_id: "BRL",
        category_id: "dive",
        quantity: 1,
        unit_price: totalPrice,
      },
    ];

    navigate("/login?redirect-to=checkout/mp", {
      state: {
        items,
        productInfo: {
          id,
          category: "dive",
          date: moment(date).format(),
          totalDivers,
          totalAccompaniers,
          totalSnorkel,
          totalPrice,
          img: dive?.img,
          productDescription,
          title: dive?.title[lang],
        },
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${dive?.title[lang]} ${t("in")} ${
          dive?.location
        } - GDN`}</title>
        <meta
          name="description"
          content={`${dive?.description[lang]} ${t("in")} ${
            dive?.location
          } - GDN`}
        />
        <meta name="keywords" content={`${t("dive")} ${dive?.location}`}></meta>
      </Helmet>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/where-we-are" className="no-style-link">
              {" "}
              {t("where_we_are")}
            </Link>{" "}
            /{" "}
            <Link
              to={`/where-we-are/${dive?.locationId}`}
              className="no-style-link"
            >
              {dive?.location}
            </Link>{" "}
            /{" "}
            <Link
              to={`/where-we-are/${dive?.locationId}/dive`}
              className="no-style-link"
            >
              {t("dives")}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              {dive?.title[lang]} {t("in")} {dive?.location}
            </h3>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={6}>
            <img
              src={dive?.img}
              alt={dive?.title[lang]}
              className="img-fluid rounded-2 shadow mb-4"
            />
          </Col>
          <Col lg={6}>
            <Card className="bg-light border-light shadow mb-3">
              <Card.Body className="px-4 pt-4 pb-2">
                <Row>
                  <Col>
                    <div className="fw-bold fs-4">
                      {dive?.title[lang]}
                      <span className="ms-1 text-muted fs-6">
                        {" "}
                        {dive?.location}
                      </span>
                    </div>
                    <hr></hr>
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} className="text-center">
                    <span className="form-text">
                      Escolha a data do seu mergulho:
                    </span>

                    <DatePicker
                      selected={date}
                      minDate={minDate}
                      maxDate={
                        new Date(
                          Date.now() + MAX_BOOKING_ADVANCE * 24 * 60 * 60 * 1000
                        )
                      }
                      onChange={(date) => setDate(date)}
                      inline
                    />
                  </Col>
                  <Col lg={5} className="text-center">
                    <span className="form-text">Número de pessoas:</span>
                    <Card className="p-2 mb-1">
                      <Card.Title className="text-small fs-6">
                        <small className="form-text">Mergulhadores:</small>
                      </Card.Title>
                      <Counter
                        total={totalPeople}
                        maxTotal={maxPeople}
                        state={totalDivers}
                        setState={setTotalDivers}
                      />
                    </Card>
                    <Card
                      className={dive?.accompanyAllowed ? "p-2 mb-1" : "d-none"}
                    >
                      <Card.Title className="text-small fs-6">
                        <small className="form-text">Acompanhantes:</small>
                      </Card.Title>
                      <Counter
                        total={totalPeople}
                        maxTotal={maxPeople}
                        state={totalAccompaniers}
                        setState={setTotalAccompaniers}
                      />
                    </Card>
                    <Card className={dive?.accompanyAllowed ? "p-2" : "d-none"}>
                      <Card.Title className="text-small fs-6">
                        <small className="form-text">
                          Acompanhantes com Snorkel:
                        </small>
                      </Card.Title>
                      <Counter
                        total={totalPeople}
                        maxTotal={maxPeople}
                        state={totalSnorkel}
                        setState={setTotalSnorkel}
                      />
                    </Card>
                    <div className="form-text text-danger">
                      {totalPeople === 0 &&
                        "É necessário pelo menos uma pessoa na operação"}
                      {totalPeople === maxPeople &&
                        "O número máximo de pessoas permitido foi atingido."}
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <div className="p-3">
                      <ListGroup variant="flush">
                        {totalDivers > 0 && (
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <span className="me-auto">
                              {totalDivers}
                              <small className="text-muted me-2">x </small>
                              {t("divers")}
                            </span>
                            <span>R${diversPrice},00</span>
                          </ListGroup.Item>
                        )}

                        {totalAccompaniers > 0 && (
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <span className="me-auto">
                              {totalAccompaniers}
                              <small className="text-muted me-2">x </small>
                              {t("accompaniers")}
                            </span>
                            <span>R${accompaniersPrice},00</span>
                          </ListGroup.Item>
                        )}

                        {totalSnorkel > 0 && (
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <span className="me-auto">
                              {totalSnorkel}
                              <small className="text-muted me-2">x </small>
                              {t("accompaniers")} + snorkel
                            </span>
                            <span>R${snorkelPrice},00</span>
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item className="d-flex justify-content-between align-items-start">
                          <strong className="me-auto">{t("total")}</strong>
                          <span>R${totalPrice},00</span>
                        </ListGroup.Item>
                      </ListGroup>
                      <Button
                        variant="danger"
                        size="lg"
                        className="mt-3 w-100"
                        disabled={totalPrice === 0}
                        onClick={() => handleSubmit()}
                      >
                        Comprar
                      </Button>
                      <div className="me-5 mt-2 text-small">
                        Ao comprar ou reservar um mergulho, você atesta que leu
                        e está de acordo com nosso{" "}
                        <Link to="/policies/pt/sales-contract">
                          Contrato de Serviço
                        </Link>
                        .
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {dive && (
          <Row>
            <Col>
              <DivePropsList dive={dive} />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {dive?.additionalPaymentInfo ? (
              <DiveBookingInfo additionalInfo={dive.additionalPaymentInfo} />
            ) : (
              <DiveBookingInfo />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <ContactCard />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default DiveDetails;
