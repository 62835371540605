import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import DownloadInfoBtn from "../../components/DownloadInfo/DownloadInfoBtn";
import Footer from "../../components/Footer/Footer";
import DeleteAccount from "../../components/ManageProfile/DeleteAccount";
import NavBar from "../../components/NavBar/NavBar";

function PoliticaDePrivacidade() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="text-justify mt-4">
        <Helmet>
          <title>Política de Privacidade - GDN</title>
          <meta
            name="description"
            content="Documento que descreve a política de privacidade adotada pela rede social para mergulhadores Global Divers Network."
          />
        </Helmet>
        <Row>
          <Col>
            <h1>Política de privacidade da Global Divers Network</h1>
            <small className="text-mutted">
              Data de vigência: 20 de Janeiro de 2023
            </small>
            <hr></hr>
            <h3>Introdução</h3>
            <p>
              Nossa política de privacidade (a “Política de privacidade”)
              explica as informações que coletamos, como as usamos e
              compartilhamos, como gerenciar os seus controles de privacidade e
              os seus direitos com relação aos nossos sites e aos aplicativos
              móveis e serviços relacionados (coletivamente, os “Serviços”).
              Leia também nossos{" "}
              <Link to="/policies/pt/termos-de-uso">Termos uso e serviço</Link>,
              que definem os termos que regem os Serviços.
            </p>
            <p>
              A Global Divers Network é sediada no Balneário Cassino, em Rio
              Grande, no estado do Rio Grande do Sul, Brasil, e nossos Serviços
              são fornecidos a você pela Global Divers Network. Se você for
              residente do Espaço Econômico Europeu (“EEE”), a Global Divers
              Network. é a controladora dos seus dados pessoais para os fins da
              lei de proteção de dados da EEE.
            </p>
            <p>
              Esta política foi escrita em português. Na medida em que uma
              versão traduzida seja conflitante com a versão em português, a
              versão em português prevalecerá. A menos que seja indicado de
              outra forma, esta Política de privacidade não se aplica a produtos
              ou serviços de terceiros ou às práticas de empresas que não
              possuímos nem controlamos, inclusive outras empresas com as quais
              você possa interagir nos Serviços ou por meio deles.
            </p>
            <p>
              Perguntas e comentários sobre esta Política de privacidade podem
              ser enviados por correio para o endereço abaixo ou via email para:
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>
            <address>
              <strong>Global Divers Network</strong>
              <br />
              Rua Álamo, 148 - Balneário Cassino
              <br />
              Rio Grande - RS, Brasil
              <br />
              <strong>Email</strong>
              <br />
              <a href="mailto:#">contato@globaldiversnetwork.com</a>
            </address>
            <h3>Informações que a Global Divers Network coleta</h3>
            <p>
              A Global Divers Network coleta informações sobre você, inclusive
              informações que o identifiquem direta ou indiretamente, se você ou
              outros usuários escolherem compartilhá-las com a Global Divers
              Network. Recebemos informações de algumas formas diferentes,
              inclusive quando você rastreia, conclui ou faz upload de
              atividades usando os Serviços. A Global Divers Network também
              coleta informações sobre como você utiliza os Serviços. Além
              disso, há várias outras oportunidades para você compartilhar
              informações sobre si mesmo, seus amigos e as sua atividade com a
              Global Divers Network. Por exemplo:
            </p>
            <h3>Conta, perfil, atividade e informações do usuário</h3>
            <p>
              Nós coletamos <strong>informações básicas de conta</strong>, como
              nome, nome completo, endereço de e-mail, data de nascimento, sexo,
              peso, nome de usuário e senha, que ajudam a proteger e fornecer a
              você acesso aos nossos Serviços.
            </p>
            <p>
              <strong>Informações de perfil</strong>, atividade dentro do site
              ou aplicativo, informação dos logs enviados para a Global Divers
              Network e dados de utilização são coletadas quando você opta por
              fazer upload de uma foto, atividade, log (incluindo informações de
              data, hora e geolocalização, bem como dados referentes as suas
              atividades esportivas, recreativas, aquáticas ou físicas e log) ou
              publicar, adicionar seu uso de equipamento, visualizar ou
              interagir com atividades ou logs de outras pessoas ou, de outra
              forma, utilizar os Serviços da Global Divers Network.
            </p>
            <p>
              Nós usamos suas <strong>informações de contato </strong>para
              podermos responder às suas solicitações de suporte e comentários.
            </p>
            <h3>Informações de localização</h3>
            <p>
              <strong>
                Coletamos e processamos informações de localização{" "}
              </strong>
              quando você se inscreve e usa os Serviços. Nós não rastreamos a
              localização do seu dispositivo enquanto você não está usando a
              Global Divers Network, mas, para fornecer os Serviços básicos da
              Global Divers Network, é necessário rastrear a localização do
              dispositivo enquanto você usa a Global Divers Network. Se desejar
              interromper o rastreamento de localização de dispositivo, você
              pode ajustar as configurações do seu dispositivo a qualquer
              momento.
            </p>
            <h3>Conteúdo compartilhado por você</h3>
            <p>
              Coletamos informações de fotos, publicações, comentários, elogios,
              likes, curtidas, avaliações e{" "}
              <strong>
                quaisquer outros conteúdos que você compartilhar nos Serviços da
                Global Divers Network{" "}
              </strong>{" "}
              , inclusive quando você participar de eventos de parceiros,
              participar de atividades em grupo ou em qualquer atividade
              patrocinada ou organizada pela Global Divers Network.
            </p>
            <h3>Informações sobre atividades de Mergulho</h3>
            <hr></hr>
            <p>
              A Global Divers Network pode coletar informações sobre as suas
              atividades de mergulho para oferecer as funcionalidades básicas do
              sitema. Como parte desta funcionalidade você também compartilhará
              estas informações com outros usuários da Global Divers Networks,
              salvo quando escolher não compartilhar estas atividades{" "}
              <strong>antes</strong> de enviá-las para a Global Divers Networks.
              Você pode fazer isso selecionando a opção "somente eu" no controle
              de múltipla seleção localizado na parte inferior da página para a
              criação e edição de logs logo abaixo da pergunta "Quem pode ver
              este log?". Estas informações incluem data de realização, local e
              outros dados referentes a atividade, entre eles o tempo de
              mergulho, informações sobre equipamentos e desempenho, climáticas
              e{" "}
              <strong>
                quaisquer outras informações que você escolha compartilhar
              </strong>
              .
            </p>
            <h3>Informações técnicas e arquivos de registro</h3>
            <p>
              Coletamos informações do seu navegador, computador ou dispositivo
              móvel, o que nos fornece informações técnicas quando você acessa
              ou usa os Serviços. Essas informações técnicas incluem informações
              sobre o dispositivo e rede, cookies, arquivos de registro e
              informações de análise. Saiba mais sobre como usamos cookies e
              como gerenciar suas preferências lendo nossa{" "}
              <Link to="/policies/pt/politica-de-cookies">
                {" "}
                Política de Cookies
              </Link>
              .
            </p>
            <p>
              Os Serviços utilizam <strong>arquivos de registro</strong>. As
              informações armazenadas nesses arquivos incluem endereços IP, tipo
              de navegador, provedor de serviços de Internet (ISP), páginas de
              referência/saída, tipo de plataforma, carimbo de data/hora e
              número de cliques. Essas informações são usadas para analisar
              tendências, administrar e proteger os Serviços, acompanhar o
              movimento do usuário nos dados agregados e reunir informações
              demográficas amplas para uso agregado. Endereços IP podem ser
              vinculados a IDs de sessão, IDs de usuário e identificadores de
              dispositivo.
            </p>
            <h3>Outras informações</h3>
            <p>
              Nós podemos{" "}
              <strong>
                coletar informações sobre você por meio de terceiros
              </strong>
              , como quando coletamos seu feedback em pesquisas e dados sobre a
              utilização de nossos serviços.
            </p>
            <p>
              Também podemos{" "}
              <strong>coletar informações sobre você de outros usuários</strong>
              , como quando eles enviam elogios, likes, curtidas, fazem
              comentários sobre suas atividades e quando adicionam vocês às suas
              atividades.
            </p>
            <h3>Como a Global Divers Network utiliza as informações</h3>
            <p>
              A Global Divers Network usa as informações que coletamos e
              recebemos conforme descrito abaixo.
            </p>
            <h3>Para Prestar os Serviços</h3>
            <p>
              Usamos as informações que coletamos e recebemos para prestar os
              Serviços, inclusive para que você possa:
            </p>
            <ul>
              <li>
                <strong>
                  Registrar suas atividades, logs de mergulho e analisar seu
                  desempenho
                </strong>
                . Por exemplo, para comparar seu consumo de gás em atividades e
                logs anteriores, analisar seu tempo de mergulho, lastro
                utilizado e localização para que outros usuários conheçam pontos
                de mergulho e que você tenha o registro de onde ocorreram.
              </li>
              <li>
                <strong>Interagir com outros usuários</strong>. Por exemplo, ser
                seguido e seguir outros usuários, receber ou dar likes ou
                curtidas e usar recursos que ajudam os outros usuários a
                interagir uns com os outros, como atividades em grupo ou dicas e
                idéias para novas atividades recreativas ou profissionais.
              </li>
              <li>
                <strong>Acompanhar seu progresso</strong>. Por exemplo, para
                saber quantos mergulhos você já realizou, se passou a utilizar
                um lastro menor conforme foi evoluiindo no mergulho, se seu
                consumo de gás diminuiu ou aumentou, sua profundidade máxima
                atingida entre outras métricas obtidas através dos dados
                compartilhados por você.
              </li>
              <li>
                <strong>Conhecer ou explorar novos locais</strong>. Por exemplo,
                encontrar ou auxiliar outros usuários a encontrar novos pontos
                de mergulho interessantes ou atrativos, inclusive, também, para
                outros tipos de atividades recreativas ou profissionais.
              </li>
              <li>
                Para{" "}
                <strong>visualizar seus logs, mergulhos e atividades</strong> de
                novas maneiras. Por exmplo, visualizando métricas de desempenho
                frequência de mergulhos, logs ou outras atividades.
              </li>
            </ul>
            <p>
              Conforme as informações disponibilizadas por voçê na{" "}
              <Link to="/manage-profile/edit-profile">
                {" "}
                página de edição de perfil{" "}
              </Link>
              , as suas informações, incluindo partes do seu perfil, nome de
              usuário, fotos, usuários que você segue e que seguem você, clubes
              ou operadoras as quais pertence, suas atividades ou logs, os
              dispositivos que você usa e elogios, likes, curtidas e comentários
              que você faz e recebe, serão compartilhadas na Global Divers
              Network para que você possa participar dos Serviços, por exemplo,
              para mostrar a sua posição em um ranking. Determinadas{" "}
              <Link to="/manage-profile/edit-profile">informações</Link>(por
              exemplo, seu nome e algumas informações de perfil) também estão
              disponíveis para não usuários na Web. Suas informações precisas de
              localização, como onde você mergulha, também podem ser
              compartilhadas na Global Divers Network ou para não usuários, de
              acordo com seus controles de privacidade.
            </p>
            <h3>Para personalizar sua experiência</h3>
            <p>
              Utilizamos as informações que coletamos sobre você, seus
              seguidores, seus logs e sua atividade na Global Divers Network
              para personalizar a experiência. Por exemplo, podemos sugerir
              locais e pontos mergulho, equipamentos, operadoras e agencias de
              mergulho e turismo que possam interessar a você, usuários que você
              possa ter interesse em seguir ou novos recursos que queira
              experimentar. Se soubermos que você gosta de um tipo específico de
              mergulho, podemos oferecer informações relacionadas a este
              modalidade. Também podemos mostrar conteúdo patrocinado com base
              nos dados fornecidos por você e a maneira como utiliza nossos
              serviços. Se observarmos que você mergulha em uma determinada
              área, podemos sugerir novos pontos, usuários que também mergulham
              na área, operadoras, lojas, hotéis e outros estabelecimentos e
              negócios próximos.
            </p>
            <h3>Para proteger você e os Serviços</h3>
            <p>
              Usamos as informações que coletamos para proteger os usuários,
              fazer cumprir nossos{" "}
              <Link to="/policies/pt/termos-de-uso"> Termos de serviço</Link>e{" "}
              <Link to="/policies/pt/padroes-da-comunidade">
                {" "}
                Padrões da Comunidade{" "}
              </Link>{" "}
              e promover a segurança. Por exemplo, encontramos e removemos
              conteúdo que viole nossos termos, como discurso de ódio ou spam,
              bem como suspendemos ou encerramos contas que compartilham tal
              conteúdo.
            </p>
            <h3>Para melhorar nossos serviços</h3>
            <p>
              Também usamos as informações que coletamos para analisar,
              desenvolver e aprimorar os Serviços. Para fazer isso, a Global
              Divers Network pode usar provedores de análise de terceiros para
              obter informações sobre como os nossos Serviços são usados e para
              nos ajudar a aprimorar os Serviços.
            </p>
            <h3>Para a comunicação com você</h3>
            <p>
              Usamos as informações que coletamos para fornecer suporte em
              resposta a suas solicitações e comentários.
            </p>
            <p>
              Além disso, podemos usar as informações que coletamos para
              comercializar e promover os Serviços, as atividades e os eventos
              no Timmeo, Global Divers Network e outros produtos ou serviços
              comerciais. Isso pode incluir marketing e comunicações push, em
              que você não tenha optado por não receber tais mensagens e
              notificações.
            </p>
            <h3>Para processar sua assinatura</h3>
            <p>
              Usamos as informações que coletamos para processar sua assinatura.
            </p>
            <h3>Informações agregadas</h3>
            <p>
              <strong>Nós não vendemos suas informações pessoai</strong>. A
              Global Divers Network pode agregar as informações que você e
              outras pessoas disponibilizam com relação aos Serviços e
              publicá-las ao público ou compartilhá-las com terceiros. Exemplos
              do tipo de informações que podemos agregar incluem informações
              sobre equipamento, uso, demografia, cidade, pontos mergulho e
              desempenho. A Global Divers Network pode usar, vender, licenciar e
              compartilhar essas informações agregadas a terceiros para
              pesquisa, negócios e outros fins, como melhoria na qualidade de
              serviços relacionados a atividade de mergulho ou para ajudar
              nossos parceiros a entender mais sobre os mergulhadores e
              usuários, inclusive as pessoas que usam seus produtos e serviços.
            </p>
            <h3>Como as informações são compartilhadas</h3>
            <p>
              Além de compartilhar dados agregados sobre nossos usuários da
              forma descrita acima, também compartilhamos informações pessoais
              de acordo com suas preferências, à medida que for necessário para
              realizar nossos negócios e fornecer os Serviços, e quando
              necessário para fins jurídicos, conforme determinado abaixo.
            </p>
            <h3>Provedores de serviços</h3>
            <p>
              Podemos compartilhar suas informações com terceiros que prestam
              serviços a Global Divers Network, tais como suporte,
              aprimoramento, promoção e proteção dos Serviços, processamento de
              pagamentos ou atendimento de pedidos. Esses provedores de serviços
              somente têm acesso às informações necessárias para realizar essas
              funções limitadas em nosso nome e são obrigados a proteger e
              resguardar as suas informações. Também podemos usar provedores de
              serviços para coletar informações sobre seu uso dos Serviços ao
              longo do tempo em nosso nome, para possibilitar a promoção da
              Global Divers Network ou a exibição de informações que possam ser
              relevantes a seus interesses nos Serviços ou em outros websites ou
              serviços.
            </p>
            <h3>Informações publicamente disponíveis</h3>
            <p>
              Quando você se cadatra na Global Divers Network, seu perfil e
              atividades são{" "}
              <strong>
                definidos como visualizáveis por todos, por padrão
              </strong>
              . Seu nome e outras informações de perfil são visíveis por outros
              usuários da Global Divers Network e publicamente e, conforme os
              seus controles de privacidade, informações e conteúdos adicionais
              que você compartilha também podem ser visíveis. Por exemplo, suas
              fotos e locais de mergulho podem ser acessadas por outros usuários
              e não usuários da Global Divers Network ou visíveis em páginas da
              Global Divers Network publicamente acessíveis ou em resultados de
              mecanismos de busca. Nós oferecemos{" "}
              <Link to="/manage-profile/edit-profile">ferramentas </Link> para
              controlar o compartilhamento das suas informações e incentivamos
              nossos usuários a ajustar seus controles de acordo com a
              experiência desejada.
            </p>
            <h3>Compartilhamento de informações, atividades e logs</h3>
            <p>
              Como uma rede social, compartilhamos suas informações com outros
              usuários da Global Divers Network e o público, de acordo com os
              seus controles de privacidade. Você pode alterar a visibilidade do
              que compartilha na Global Divers Network através de nossas
              ferramentas. Além disso, você também pode compartilhar suas
              atividades com seus contatos por mensagem de texto, e-mail ou em
              aplicativos de rede social como Facebook, Instagram e Twitter. Se
              você vincular sua conta da Global Divers Network a outras redes
              sociais e compartilhar suas atividades, elas poderão ser
              visualizadas em tais plataformas de terceiros, inclusive suas
              informações de localização. Tenha cuidado ao compartilhar
              informações por meio de terceiros e leia atentamente as práticas
              de privacidade destes terceiros.
            </p>
            <h3>Negócios de terceiros por API ou outras integrações</h3>
            <p>
              Nós permitimos que você compartilhe as suas informações e conteúdo
              com aplicativos, plugins ou websites de terceiros que se integrem
              com os Serviços, bem como com terceiros que trabalham com a Global
              Divers Network para oferecer um recurso integrado, como
              patrocinadores de eventos, equipamentos ou serviços. Você pode
              escolher compartilhar seus dados de perfil e dados de atividade
              (incluindo atividades privadas). As informações coletadas por
              estes terceiros estão sujeitas aos seus termos e políticas. A
              Global Divers Network não se responsabilizam por termos e
              políticas de terceiros.
            </p>
            <h3>Afiliadas e adquirentes de nossos negócios ou ativos</h3>
            <p>
              Podemos compartilhar suas informações com afiliadas que estejam
              sob controle comum conosco e que devem cumprir os termos desta
              Política de privacidade com relação às suas informações. Se a
              Global Divers Network estivere envolvida em uma combinação de
              empresas, oferta de valores mobiliários, falência, reorganização,
              dissolução ou outra transação semelhante, poderemos compartilhar
              ou transferir suas informações por conta de tal transação.
            </p>
            <h3>Requisitos legais</h3>
            <p>
              Podemos preservar e compartilhar suas informações com terceiros,
              inclusive com órgãos de cumprimento da lei, agências públicas ou
              governamentais ou litigantes privados, dentro ou fora do seu país
              de residência, se determinarmos que a divulgação é permitida por
              lei ou necessária para o cumprimento da lei, incluindo responder a
              ordens judiciais, mandados, intimações ou outros processos legais
              ou regulatórios. Também podemos reter, preservar ou divulgar suas
              informações se determinarmos que isso seja necessário ou
              apropriado para evitar a morte ou lesão corporal grave de qualquer
              pessoa, para tratar de questões de segurança nacional ou outras
              questões de relevância pública, para evitar ou detectar violações
              de nossos
              <Link to="/policies/pt/termos-de-uso">Termos uso e serviço</Link>,
              ou fraude ou abuso da Global Divers Network ou de seus usuários,
              ou para proteger nossas operações ou nossa propriedade ou outros
              direitos legais, inclusive por meio de divulgação à nossa
              assessoria jurídica ou a outros consultores e terceiros com
              relação a litígios reais ou potenciais.
            </p>
            <h3>Notificações da DMCA</h3>
            <p>
              Podemos compartilhar suas informações com terceiros ao encaminhar
              notificações da lei DMCA (Lei dos Direitos autorais do Milênio
              Digital), que serão encaminhadas conforme enviada a nós, sem
              exclusões.
            </p>
            <h3>Como protegemos as informações</h3>
            <p>
              Tomamos diversas medidas para proteger a coleta, transmissão e
              armazenamento dos dados que coletamos. Empregamos proteções
              razoáveis para suas informações que sejam apropriadas a sua
              confidencialidade. Os Serviços utilizam a tecnologia Secure
              Sockets Layer (SSL) padrão do setor para possibilitar a
              criptografia de informações pessoais e números de cartão de
              crédito. A Global Divers Network utiliza fornecedores de serviços
              de tecnologia que são líderes do mercado para fortalecer a
              segurança dos nossos Serviços. Os Serviços são registrados junto a
              autoridades de identificação de sites para que seu navegador possa
              confirmar a identidade da Global Divers Network antes do envio de
              quaisquer informações pessoais.
            </p>
            <h3>Gerenciamento das suas configurações</h3>
            <h4>Controles de privacidade</h4>
            <p>
              A Global Divers Network oferece vários recursos e configurações
              para ajudar você a gerenciar sua privacidade e compartilhar suas
              atividades e logs. A maioria dos controles de privacidade está
              localizada na{" "}
              <Link to="/manage-profile/edit-profile">
                página de edição de perfil
              </Link>
              , mas algumas são específicas a determinada atividade,logs ou
              usuários. A Global Divers Network oferece a você a opção de fazer
              com que seus logs sejam privados. Você pode fazer isso
              selecionando a opção "somente eu" no controle de múltipla seleção
              localizado na parte inferior da página para a criação e edição de
              logs logo abaixo da pergunta "Quem pode ver este log?".
            </p>
            <h3>Ajuste de preferências de notificação e e-mail </h3>
            <p>
              A Global Divers Network oferece várias maneiras de gerenciar as
              notificações que você recebe. Você pode escolher interromper o
              recebimento de determinados e-mails e notificações indicando a sua
              preferência na
              <Link to="/manage-profile/edit-profile">
                página de edição de perfil
              </Link>
              . Você também pode cancelar a assinatura seguindo as instruções
              contidas no final de cada e-mail de marketing ou promocional. Os
              e-mails administrativos ou relacionados a serviços (para confirmar
              uma compra ou uma atualização nesta Política de privacidade ou em
              nossos Termos de serviço etc.) geralmente não oferecem a opção de
              cancelar o recebimento, visto que são necessários para fornecer os
              Serviços solicitados.
            </p>
            <h3>Atualização de informações da conta</h3>
            <p>
              Você pode, a qualquer momento, corrigir, retificar ou atualizar
              informações de perfil ou conta ajustando tais informações em suas
              configurações da conta. Se precisar de assistência para corrigir
              informações imprecisas, entre em contato com a Global Divers
              Network através do email:{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a>
            </p>
            <h3>Exclusão de informações e contas e download dos seus dados</h3>
            <p>
              Você pode excluir sua conta ou fazer o download dos seus dados
              usando nossas ferramentas de autoatendimento.{" "}
              <DownloadInfoBtn label="Clique aqui"></DownloadInfoBtn>
              para fazer o download dos seus dados, incluindo seus arquivos de
              atividades. Para solicitar que a sua conta seja excluída, clique
              no link a seguir:
              <DeleteAccount></DeleteAccount>.
            </p>
            <p>
              Depois que você fizer uma solicitação de exclusão, nós
              excluiremos, de forma permanente e irreversível, os seus dados
              pessoais de nossos sistemas, incluindo backups. Após a exclusão,
              os seus dados, inclusive a sua conta, atividades, logs e métricas
              e quaisquer outras informações vinculada a sua conta, não poderão
              ser restabelecidos. Após sua exclusão da conta, poderá levar até
              90 dias para a exclusão das suas informações pessoais e registros
              dos nossos sistemas. Além disso, nós podemos{" "}
              <strong>
                {" "}
                reter informações nos casos em que solicitações de exclusão
                tenham sido feitas em cumprimento da lei e tomar outras medidas
                permitidas pela lei.
              </strong>
            </p>
            <p>
              Observe que o conteúdo compartilhado com outras pessoas, como
              fotos, ou que outras pessoas tenham copiado também poderá ficar
              visível após você ter excluído a sua conta ou excluído informações
              específicas do seu perfil. O seu perfil público poderá ser exibido
              em resultados de mecanismos de pesquisa e outros serviços até que
              o cache destes seja atualizado.
            </p>
            <p>
              A Global Divers Network também oferece a opção de remover
              atividades e logs individuais que você tiver publicado, sem
              excluir sua conta. Depois de removidas, a atividade ou seus logs
              podem permanecer nos sistemas da Global Divers Network até que
              você exclua sua conta definitivamente.
            </p>
            <h3>Seus direitos e nossas bases jurídicas</h3>
            <p>
              Nós fornecemos o mesmo conjunto de ferramentas e controle de
              privacidade para todos os nossos usuários mundialmente. Direitos
              especifícos podem estar disponíveis para você, se você residir em
              certos locais, como EEE, Brasil ou Califórnia.
              <Link to="/policies/pt/bases-juridicas">Saiba mais</Link> sobre os
              seus direitos e como exercê-los.
            </p>
            <h3>Seus direitos jurídicos no EEE</h3>
            <p>
              Se você for habitualmente localizado(a) no EEE, tem o direito de
              acessar, retificar, baixar ou apagar suas informações, bem como o
              direito de restringir e opor-se a determinados processamentos de
              suas informações.{" "}
              <Link to="/policies/pt/bases-juridicas">Saiba mais</Link>.
            </p>
            <h3>Nossas bases jurídicas</h3>
            <p>
              A Global Divers Network invoca diversas bases jurídicas para
              coletar, utilizar, compartilhar e processar as informações que
              temos sobre você para os fins descritos nesta Política de
              privacidade, inclusive:
            </p>
            <ul>
              <li>
                Conforme necessário para fornecer os Serviços e cumprir nossas
                obrigações de acordo com os{" "}
                <Link to="/policies/pt/termos-de-uso">
                  Termos uso e serviço
                </Link>
                . Por exemplo, nós não podemos fornecer os Serviços, exceto se
                coletarmos ou utilizarmos as suas informações de localização;
              </li>
              <li>onde você consentiu o processamento;</li>
              <li>
                onde necessário para cumprir uma obrigação legal, um mandado
                judicial ou para exercer ou defender reivindicações legais;
              </li>
              <li>
                para proteger seus interesses vitais, ou os de outras pessoas,
                como em caso de emergência; e
              </li>
              <li>
                onde necessário para os fins dos interesses legítimos da Global
                Divers Network e de terceiros, conforme descrito nas{" "}
                <Link to="/policies/pt/bases-juridicas">bases jurídicas</Link>,
                como nossos interesses em proteger nossos usuários, os
                interesses de nossos parceiros em colaborar com nossos usuários
                e nossos interesses comerciais em garantir a sustentabilidade
                dos Serviços.
              </li>
            </ul>
            <h3>Transferências</h3>
            <p>
              A operação dos Serviços é realizada no Brasil e utilizamos
              serviços e servidores nos Estados Unidos. Se estiver localizado
              fora do Brasil ou dos Estados Unidos e escolher usar os Serviços
              ou nos fornecer informações, você reconhece e entende que as suas
              informações serão transferidas, processadas e armazenadas no
              Brasil e nos Estados Unidos, conforme for necessário para fornecer
              os Serviços e executar os{" "}
              <Link to="/policies/pt/termos-de-uso">
                {" "}
                Termos de uso e de serviço
              </Link>{" "}
              . As leis de privacidade do Brasil e dos Estados Unidos podem não
              oferecer o mesmo grau de proteção daquelas na sua jurisdição.
            </p>
            <h3>Retenção de informações</h3>
            <p>
              Mantemos informações pelo tempo que for necessário para fornecer
              os Serviços para você e outras pessoas, sujeito a quaisquer
              obrigações jurídicas de reter tais informações por mais tempo. As
              informações relacionadas com a sua conta serão, em geral, mantidas
              até que não sejam mais necessárias para o fornecimento dos
              Serviços ou até a exclusão da sua conta. Além disso, você pode
              excluir algumas informações (por exemplo, informações de perfil) e
              remover atividades individuais da exibição nos Serviços sem
              excluir a sua conta. Após sua exclusão da conta, poderá levar até
              90 dias para a exclusão total das suas informações pessoais e
              registros dos nossos sistemas. Também podemos reter informações
              para cumprir a lei, prevenir fraude, cobrar taxas, resolver
              disputas, solucionar problemas, auxiliar em investigações, aplicar
              os{" "}
              <Link to="/policies/pt/termos-de-uso">
                {" "}
                Termos de uso e de serviço
              </Link>{" "}
              e tomar outras medidas permitidas por lei. As informações retidas
              serão tratadas de acordo com esta Política de privacidade.
            </p>
            <p>
              As informações associadas a você que não forem mais necessárias e
              relevantes para o fornecimento dos nossos Serviços serão
              descaracterizadas ou agregadas com outros dados não pessoais para
              fornecer informações que sejam comercialmente valiosas para a
              Global Divers Network, como estatísticas de uso dos Serviços. Por
              exemplo, dados de geolocalização para identificar pontos de
              mergulho registrados nos seus logs. Essas informações serão
              desassociadas do seu nome e de outros identificadores.
            </p>
            <h3>Informações sobre a política de privacidade</h3>
            <p>
              A Global Divers Network reserva-se o direito de modificar esta
              Política de privacidade a qualquer momento. Revise-a
              periodicamente. Se a Global Divers Network fizer alterações nesta
              Política de privacidade, a Política de privacidade atualizada será
              publicada nos Serviços em momento oportuno e, se fizermos
              alterações substanciais, forneceremos uma notificação proeminente.
              Se você não concordar com qualquer das alterações nesta Política
              de privacidade, deverá interromper o uso dos Serviços e
              <strong> excluir </strong>a sua conta clicando no link a seguir:{" "}
              <DeleteAccount></DeleteAccount>.
            </p>
            <p>© 2023 Global Divers Network</p>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default PoliticaDePrivacidade;
