import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import {
  BsPiggyBank,
  BsCloudLightningRain,
  BsCreditCard,
  BsCalendarCheck,
  BsCalendarPlus,
} from "react-icons/bs";
import { times } from "lodash";
import { useTranslation } from "react-i18next";

function CourseBookingInfo() {
  const numberOfTopics = 5;
  const { t } = useTranslation(["courseBookingInfo"]);
  const icons = [
    <BsCalendarCheck size="30" className="ms-2 mt-1" />,
    <BsCreditCard size="30" className="ms-2 mt-1" />,
    <BsCloudLightningRain size="30" className="ms-2 mt-1" />,
    <BsCalendarPlus size="30" className="ms-2 mt-1" />,
    <BsPiggyBank size="33" className="ms-2 mt-1" />,
  ];

  return (
    <ListGroup className="shadow mb-3">
      {times(numberOfTopics, (i) => (
        <ListGroup.Item
          as="li"
          className="justify-content-between align-items-start py-3"
        >
          <Row>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-center">
              {icons[i]}
            </Col>
            <Col xs={10} sm={10} md={11} lg={11} xl={11}>
              <div className="ms-1 me-4 text-justify">
                <div className="fw-bold">{t(`h${i + 1}`)}</div>
                {t(`i${i + 1}`)}
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default CourseBookingInfo;
