import React, { useState, useEffect } from "react";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { db } from "../../config/firebase";
import { getAuth } from "firebase/auth";
import { includes } from "lodash";
import { Like } from "../../interfaces/interfaces";

function LikeButton({
  resourceId,
  resourceUserId,
  resourceLikes,
  setLikesState,
}: {
  resourceId: string;
  resourceUserId: string;
  resourceLikes: Like[];
  setLikesState: Function;
}) {
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (resourceLikes) {
      resourceLikes.forEach((l) => {
        if (includes(l, getAuth().currentUser?.uid)) setLiked(true);
      });
    }
  }, [resourceLikes]);

  async function updateLikes(liked: boolean) {
    const user = getAuth().currentUser;
    if (!user || user.uid === resourceUserId) return;

    const docRef = doc(db, "logbook", resourceId);
    const objToUpdate = {
      userId: user.uid,
      userName: user.displayName,
      userPicture: user.photoURL,
    };

    try {
      setLoading(true);
      await updateDoc(docRef, {
        likes: liked ? arrayUnion(objToUpdate) : arrayRemove(objToUpdate),
      });
      liked
        ? setLikesState([...resourceLikes, objToUpdate])
        : setLikesState((resourceLikes: Like[]) =>
            resourceLikes.filter((like) => like.userId !== objToUpdate.userId)
          );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function handleClick() {
    if (loading) return;
    setLiked(!liked);
    updateLikes(!liked);
  }

  const likeIcon = liked ? (
    <FaHeart
      size={28}
      onClick={() => handleClick()}
      className="text-danger mt-2 pointer"
    ></FaHeart>
  ) : (
    <FaRegHeart
      size={28}
      onClick={() => handleClick()}
      className="mt-2 pointer"
    ></FaRegHeart>
  );

  return <>{likeIcon}</>;
}

export default LikeButton;
