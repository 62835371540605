import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";
import { BsCheck } from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";
import { owPrerequisites } from "../../data/courses";
import { openWater } from "../../data/courses";
import CourseBookingInfo from "../../components/BookingInfo/CourseBookingInfo";
import CourseLocationCard from "../../components/Cards/CourseLocationCard";

function OpenWater() {
  const { t } = useTranslation();
  const [courses, setCourses] = useState(openWater);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setCourses(openWater);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cursos Open Water Scuba Diver - GDN</title>
        <meta
          name="description"
          content="Cursos de certificação de mergulho em águas abertas open water scuba diver PADI, SSI, SDI, válidos em todo o mundo."
        />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            {/* <Link to="/courses" className="no-style-link"> */} {t("course")}
            s{/* </Link>{" "} */}/ Open water
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{t("course")}s open water scuba diver</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-2">
            <Card className="shadow">
              <Card.Img
                src="/images/courses/open-water-scuba-diver-class.jpeg"
                variant="top"
                className="rounded-1"
              />
              <div className="card-img-overlay start-0">
                <Badge>iniciante</Badge>
              </div>
            </Card>
          </Col>
          <Col lg={6} className="text-justify mb-2">
            <p className="px-3">
              Os cursos de mergulho autônomo em águas abertas são destinados a
              qualquer pessoa que tenha interesse em aprender a mergulhar e
              explorar o mundo subaquático. Ao concluir o curso com sucesso,
              você receberá uma certificação internacional e estará habilitado a
              mergulhar em qualquer lugar do planeta. O curso é dividido em três
              partes:
            </p>

            <ListGroup as="ol" variant="flush">
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Parte teórica</div>
                  Aborda conceitos teóricos como fisiologia do mergulho,
                  equipamentos de mergulho, planejamento de mergulho, segurança
                  e meio ambiente subaquático.
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Prática em águas confinadas</div>
                  Prática, em piscina, de habilidades básicas de mergulho, como
                  respiração subaquática, controle de flutuabilidade, uso de
                  equipamentos, técnicas de comunicação e resolução de
                  problemas.
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Prática em águas abertas</div>
                  Pelo menos quatro mergulhos em <strong>mar aberto</strong>,
                  onde os alunos praticam as habilidades aprendidas e exploram o
                  ambiente oceânico.
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h3>Oferecemos este curso nas seguintes localidades</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={2} xl={3} className="gx-4 gy-3">
          {courses?.map((c, i) => (
            <Col>
              <CourseLocationCard course={c} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xl={12} className="mt-5">
            <CourseBookingInfo />
            <Card className="bg-light border-0 shadow mb-3">
              <Card.Body>
                <strong className="m-1">Pré-requisitos:</strong>
                <ListGroup variant="flush" className="rounded-2 mt-5">
                  {owPrerequisites.map((p) => (
                    <ListGroup.Item>
                      <BsCheck size="25" /> {p}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={12} className="text-justify mb-3">
            <Card className="shadow">
              <Card.Body className="py-3 px-5">
                <h4 className="my-2">
                  Sobre a abordagem de ensino SSI e PADI nos cursos open water
                  diver
                </h4>
                <p>
                  Tanto a PADI (Professional Association of Diving Instructors)
                  quanto a SSI (Scuba Schools International) são organizações de
                  treinamento de mergulho reconhecidas mundialmente e oferecem
                  cursos de mergulho Open Water.{" "}
                </p>
                <p>
                  A principal diferença entre os cursos de mergulho Open Water
                  da PADI e da SSI está na abordagem e metodologia de ensino.{" "}
                </p>
                <p>
                  A PADI é conhecida por sua abordagem estruturada de ensino, em
                  que os alunos aprendem em uma sequência lógica, passando por
                  cada módulo antes de avançar para o próximo. O curso de
                  mergulho Open Water da PADI consiste em três seções
                  principais: conhecimento teórico, treinamento em piscina e
                  mergulhos em águas abertas. O curso pode ser concluído em
                  quatro a cinco dias.
                </p>{" "}
                <p>
                  {" "}
                  A SSI, por outro lado, é conhecida por sua abordagem mais
                  flexível de ensino, em que os alunos podem progredir em seu
                  próprio ritmo e passar mais tempo em áreas que acham mais
                  desafiadoras. O curso de mergulho Open Water da SSI também
                  inclui conhecimento teórico, treinamento em piscina e
                  mergulhos em águas abertas, mas o tempo para conclusão pode
                  ser mais longo ou mais curto, dependendo da habilidade e
                  velocidade de aprendizado de cada aluno.
                </p>
                <p>
                  {" "}
                  Em termos de reconhecimento, ambos os cursos são amplamente
                  aceitos em todo o mundo e são reconhecidos pela comunidade de
                  mergulho internacional. A escolha entre os cursos de mergulho
                  Open Water da PADI e da SSI pode depender das preferências
                  pessoais e das opções de treinamento disponíveis em sua
                  região.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default OpenWater;
