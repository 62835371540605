import React from "react";
import { Button } from "react-bootstrap";
import { BookableTicket } from "../../interfaces/ticket";
import { MAX_TICKETS_ON_SALE } from "../../config/constants";
import { sumBy } from "lodash";

function TicketsCounter({
  state,
  setState,
  type,
  index,
}: {
  state: Array<BookableTicket>;
  setState: Function;
  type: string;
  index: number;
}) {
  const disableSubtract = () => {
    return state[index].quantity === 0 ? true : false;
  };

  return (
    <div>
      <Button
        variant="outline-primary"
        size="sm"
        disabled={disableSubtract()}
        onClick={() =>
          setState(
            state.map((i) => {
              if (i.type === type) {
                return { ...i, quantity: i.quantity - 1 };
              }
              return i;
            })
          )
        }
      >
        -
      </Button>
      <span className="mx-3">{state[index].quantity}</span>
      <Button
        variant="outline-primary"
        size="sm"
        disabled={sumBy(state, "quantity") >= MAX_TICKETS_ON_SALE}
        onClick={() =>
          setState(
            state.map((i) => {
              if (i.type === type) {
                return { ...i, quantity: i.quantity + 1 };
              }
              return i;
            })
          )
        }
      >
        +
      </Button>
    </div>
  );
}

export default TicketsCounter;
