import React, { FormEvent, useRef, useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { SlCamera } from "react-icons/sl";
import RiseLoader from "react-spinners/RiseLoader";
import { v4 as uuidv4 } from "uuid";
import ErrorModal from "../Modal/ErrorModal";

function FileUploader({ setPhoto, setLoading, photo }: any) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(false);

  const handleClick = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  function handleFileChange(e: FormEvent) {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (!files) return;
    const file = files[0];
    if (file) {
      const element = document.getElementById("imgPreview") as HTMLImageElement;
      element.src = URL.createObjectURL(file);
      handleImageUpload(file);
    }
  }

  function handleImageUpload(file: File) {
    if (!file) console.log("No file selected");

    const storageRef = ref(storage, `/images/${uuidv4()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setIsLoading(true);
        setLoading(true);
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        setPercent(percent);
      },
      (err) => {
        setError(true);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setPhoto(url);
          setIsLoading(false);
          setLoading(false);
        });
      }
    );
  }

  return (
    <div className="text-center">
      <img
        src={photo}
        id="imgPreview"
        height="75px"
        className="img-thumbnail img-fluid my-3"
        alt=""
      ></img>

      <Button
        onClick={handleClick}
        variant="outline-dark"
        className="w-100"
        disabled={isLoading}
      >
        {isLoading ? (
          <RiseLoader size={5} color="#000" />
        ) : (
          <SlCamera id="cameraIcon" size={35} />
        )}
      </Button>
      <Form.Control
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={hiddenFileInput}
        disabled={isLoading}
        className="w-200 d-none"
      />
      <ProgressBar
        now={percent}
        className={`my-2 ${percent >= 97 ? "progress-bar bg-success" : ""}`}
      />
      <ErrorModal shouldShow={error} redirectTo="/"></ErrorModal>
    </div>
  );
}

export default FileUploader;
