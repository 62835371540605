import React, { useState } from "react";
import { FiShare } from "react-icons/fi";
import { Log } from "../../interfaces/interfaces";
import ShareModal from "../Modal/ShareModal";

function ShareLogSmBtn({ resource }: { resource: Log }) {
  const [showShareModal, setShowShareModal] = useState(false);

  return (
    <>
      <FiShare
        size="30"
        className="pointer mt-2"
        onClick={() => setShowShareModal(true)}
      />

      <ShareModal
        shouldShow={showShareModal}
        resource={resource}
        setShouldShow={setShowShareModal}
      ></ShareModal>
    </>
  );
}

export default ShareLogSmBtn;
