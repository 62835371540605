import React from "react";
import "./images.css";

function ImageCircle({ image, size }: { image: string; size: string }) {
  return (
    <img
      src={image || ""}
      className="cropped-circle border border-1 border-dark"
      width={`${size}px`}
      height={`${size}px`}
      alt=""
    />
  );
}

export default ImageCircle;
