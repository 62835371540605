import React, { useState } from "react";
import { Comment } from "../../interfaces/interfaces";
import {
  Accordion,
  Card,
  Button,
  useAccordionButton,
  Badge,
} from "react-bootstrap";
import CommentTxtArea from "./CommentTxtArea";
import { BiMessageDetail } from "react-icons/bi";
import CommentsList from "./CommentsList";

function CustomToggle({
  children,
  eventKey,
}: {
  children: JSX.Element;
  eventKey: string;
}) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    //TODO: customize
  });

  return (
    <Button
      style={{ border: "0" }}
      onClick={decoratedOnClick}
      variant="outline-dark"
    >
      {children}
    </Button>
  );
}

function CommentsAccordion({
  logId,
  comments,
  opened,
}: {
  logId: string;
  comments: Comment[];
  opened: boolean;
}) {
  const [commentsState, setCommentsState] = useState(comments);

  return (
    <Accordion flush defaultActiveKey={opened ? "0" : ""}>
      <Card className="border-0">
        <Card.Header className="text-end bg-white border-0">
          <CustomToggle eventKey="0">
            <>
              <BiMessageDetail
                size="35"
                className="z-index-1"
              ></BiMessageDetail>
              <Badge bg="success rounded-pill" className="comments-badge">
                {commentsState?.length > 0 && commentsState?.length}
              </Badge>
            </>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <CommentTxtArea
              logId={logId}
              setCommentsState={setCommentsState}
              commentsState={commentsState}
            ></CommentTxtArea>
            <CommentsList comments={commentsState}></CommentsList>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
export default CommentsAccordion;
