import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import NavBar from "../components/NavBar/NavBar";
import FileUploader from "../components/FileUploader/FileUploader";
import { LOGBOOK_TAGS } from "../components/Tags/data";
import { loggerInitialState } from "../interfaces/interfaces";
import Tags from "../components/Tags/Tags";
import LoggerBasicForm from "../components/LoggerForm/LoggerBasicForm";
import LoggerAdditionalForm from "../components/LoggerForm/LoggerAdditionalForm";
import { RiseLoader } from "react-spinners";
import { omitBy, isNil, values } from "lodash";
import { useTranslation } from "react-i18next";
import LoadingModal from "../components/Modal/LoadingModal";
import { useLocation } from "react-router-dom";
import { now } from "moment";
import ErrorModal from "../components/Modal/ErrorModal";
import { IoIosTrash } from "react-icons/io";
import DeleteCheckModal from "../components/Modal/DeleteCheckModal";
import InfoModal from "../components/Modal/InfoModal";
import ValidationModal from "../components/Modal/ValidationModal";
import { Helmet } from "react-helmet";
import "../locales/i18n";

function Logger() {
  const location = useLocation();

  const log = location.state ? location.state.log : null;
  const initialState = log ? log : loggerInitialState;
  const photoInitalState = log ? log.photo : "";
  const [photo, setPhoto] = useState(photoInitalState);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [error, setError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [logId, setLogId] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  function checkTags() {
    if (!log || !log.tags) return;
    const tags = values(log.tags);
    if (tags) {
      tags.forEach((t: string) => {
        const element = document.getElementById(t) as HTMLInputElement;
        if (element) {
          if (!element.checked) element.click();
        }
      });
    }
  }

  useEffect(() => {
    if (!auth.currentUser) navigate("/login");
    checkTags();
    setState({
      ...state,
      logVisibility: "everyone",
    });
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      window.scrollTo(0, 0);
      alert("Por favor, preencha os campos obrigatórios antes de prosseguir.");
      return;
    }
    setLoading(true);

    const checkedTags = omitBy(
      LOGBOOK_TAGS.map((e) =>
        (document.getElementById(e) as HTMLInputElement).checked
          ? (document.getElementById(e) as HTMLInputElement).value
          : null
      ),
      isNil
    );

    const user = auth.currentUser;
    if (!user) {
      navigate("/login");
      return;
    }

    const properties = {
      userId: user.uid,
      userName: user.displayName,
      userPicture: user.photoURL,
      created: now(),
      photo,
      title: state.title || t("dive"),
      date:
        state.date ||
        (document.getElementById("dateInput") as HTMLInputElement).value,
      tags: checkedTags,
      location: state.location,
      diveSite: state.diveSite,
      temperature: state.temperature,
      waterTemperature: state.waterTemperature,
      visibility: state.visibility,
      startingGas: state.startingGas,
      finalGas: state.finalGas,
      maximumDepth: state.maximumDepth,
      diveTime: state.diveTime,
      noDecoTime: state.noDecoTime,
      averageDepth: state.averageDepth,
      weighting: state.weighting,
      notes: state.notes,
      logVisibility: state.logVisibility || "everyone",
      latLng: state.latLng,
    };

    const definedProperties = omitBy(properties, isNil);

    try {
      const userRef = doc(db, "users", user.uid);
      const logRef = log
        ? doc(db, "logbook", log.logId)
        : doc(collection(db, "logbook"));

      await setDoc(logRef, {
        ...definedProperties,
        logId: logRef.id,
      });

      await updateDoc(userRef, {
        dives: arrayUnion(logRef.id),
      });

      setLogId(logRef.id);
      setShowValidationModal(true);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavBar></NavBar>
      <Container className="border rounded-1" fluid>
        <Helmet>
          <title>Logbook de mergulhos - GDN</title>
          <meta
            name="description"
            content="Logbook de mergulhos virtual que permite o cadastro e compartilhamento de registros de mergulhos."
          />
        </Helmet>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mt-3">
            <div className="text-center">
              <h5>{t("register_dive")}</h5>
              <span className="form-text">{t("select_dive_options")}</span>
            </div>
            <Col md={12}>
              <Tags tagList={LOGBOOK_TAGS} checkedTags={log?.tags}></Tags>
            </Col>
          </Row>

          <hr className="my-4"></hr>

          <LoggerBasicForm
            handleChange={handleChange}
            state={state}
            setState={setState}
          ></LoggerBasicForm>

          <hr className="my-4"></hr>

          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <FileUploader
                setPhoto={setPhoto}
                setLoading={setLoading}
                photo={photo}
              ></FileUploader>
              {photo !== "" && (
                <Button
                  variant="outline-secondary"
                  className="mt-3"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  <IoIosTrash size="23"></IoIosTrash>{" "}
                  <span>{t("delete_photo")}</span>
                </Button>
              )}
            </Col>
            <Col md={2}></Col>
          </Row>

          <hr className="my-5"></hr>

          <LoggerAdditionalForm
            handleChange={handleChange}
            state={state}
          ></LoggerAdditionalForm>
          <Row className="mt-3">
            <Col md className="text-begin">
              <Button
                className="my-4 w-100"
                variant="dark"
                disabled={loading}
                type="submit"
                size="lg"
              >
                {loading ? <RiseLoader size={5} color="#fff" /> : t(`save`)}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <ErrorModal shouldShow={error} redirectTo="/"></ErrorModal>
      <ValidationModal
        shouldShow={showValidationModal}
        log={log || loggerInitialState}
        setShouldShow={setShowValidationModal}
      ></ValidationModal>
      <LoadingModal shouldShow={loading}></LoadingModal>
      <DeleteCheckModal
        shouldShow={showDeleteModal}
        message={t("delete_photo_check")}
        setShowDeleteCheck={setShowDeleteModal}
        proceed={() => {
          setPhoto("");
          setShowDeleteModal(false);
          setShowInfoModal(true);
        }}
      ></DeleteCheckModal>
      <InfoModal
        shouldShow={showInfoModal}
        message={t("click_save_to_confirm")}
        setShowInfoModal={setShowInfoModal}
      ></InfoModal>
    </>
  );
}

export default Logger;
