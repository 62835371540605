import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { useNavigate, useParams, Link } from "react-router-dom";
import { COURSES } from "../../data/courses";
import { Course } from "../../interfaces/course";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import RangeDatePicker from "../../components/DatePicker/RangeDatePicker";
import { BsCheck, BsGlobe } from "react-icons/bs";
import moment from "moment";
import CourseBookingInfo from "../../components/BookingInfo/CourseBookingInfo";

function CourseBooking() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState<Course | undefined>(undefined);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() + 5 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const courseExists = COURSES.some((c) => c.id === id);
    if (!courseExists) navigate("/courses");
    setCourse(COURSES.filter((c) => c.id === id)[0]);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [course]);

  const dateRange = () => {
    return moment(endDate).diff(moment(startDate), "days") | 0;
  };

  const validDates = () => {
    return dateRange() > 1;
  };

  const handleSubmit = (totalAmount: boolean) => {
    const items = [
      {
        id: course?.id,
        title: `${totalAmount ? "" : "Reserva: "}${course?.description}`,
        description: `${totalAmount ? "" : "Reserva: "}${course?.description}`,
        picture_url:
          "https://lh6.googleusercontent.com/f2dWePJVIYrpCfCm746TtfpbGphbZA2DAyLhje-1xmWxFWZ2lgQuVCNEEhHtSq1bZBs=w2400",
        currency_id: "BRL",
        category_id: "course",
        quantity: 1,
        unit_price: totalAmount ? course?.price : course?.reservationPrice,
      },
    ];

    navigate("/login?redirect-to=checkout/mp", {
      state: {
        items,
        productInfo: {
          id,
          category: "course",
          startDate: moment(startDate).format(),
          endDate: moment(endDate).format(),
          totalPrice: totalAmount ? course?.price : course?.reservationPrice,
          productDescription: `${totalAmount ? "" : "Reserva: "}${
            course?.description
          }`,
          title: `${totalAmount ? "" : "Reserva: "}${course?.name}`,
          img: course?.photo,
        },
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${course?.name} - ${course?.location}`}</title>
        <meta name="description" content={course?.description} />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            {t("course")}s /{course?.name}/{" "}
            <Link
              to={`/where-we-are/${course?.locationId}`}
              className="no-style-link"
            >
              {course?.location}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              {t("course")} {course?.name}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-3">
            <Card className="bg-light border-light shadow">
              <Card.Body className="px-4 pt-4 pb-2">
                <Row>
                  <Col>
                    <img
                      src={`/images/logos/${course?.certifiedBy.toLowerCase()}.png`}
                      width="200px"
                      alt={course?.certifiedBy}
                    />
                    <div className="fw-bold fs-4">
                      {course?.name}
                      <span className="ms-1 text-muted fs-6">
                        {" "}
                        {course?.location}
                      </span>
                    </div>
                    <hr></hr>
                  </Col>
                </Row>
                <Row>
                  <Col md={7} className="text-center">
                    <span className="form-text">
                      Datas previstas de início e conclusão
                    </span>
                    <RangeDatePicker
                      startInXDays={5}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    ></RangeDatePicker>
                    <div>
                      {dateRange() >= 2 ? (
                        <p className="text-success text-center mt-2">
                          <strong>Curso disponível para reserva</strong>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={5} className="text-center">
                    <div className="text-danger gdn fs-5 mt-5">
                      <BsGlobe className="text-danger" size="100" /> <br />
                      Global Divers Network
                    </div>
                    <Button
                      size="lg"
                      variant="danger"
                      className="mt-2"
                      disabled={!validDates()}
                      onClick={() => handleSubmit(false)}
                    >
                      {validDates()
                        ? "Fazer uma reserva"
                        : "Selecione as datas"}
                    </Button>
                    <div className="form-text">
                      pague somente{" "}
                      <strong className="fs-6">
                        R$
                        {course?.reservationPrice},00{" "}
                      </strong>
                      para reservar
                    </div>
                  </Col>
                </Row>
                <div>
                  {dateRange() >= 2 ? (
                    <Row>
                      <div className="me-5">
                        Reserva válida para o curso{" "}
                        <strong>
                          {course?.certifiedBy}&#174; {course?.name}{" "}
                        </strong>
                        entre os dias {moment(startDate).format("DD/MM/YY")} e{" "}
                        {moment(endDate).format("DD/MM/YY")} em{" "}
                        {course?.location}. Garanta sua vaga e venha mergulhar
                        com a gente!
                        <br />
                      </div>
                      <div className="me-5 text-small">
                        Ao comprar ou reservar nossos cursos, você atesta que
                        leu e está de acordo com nosso{" "}
                        <Link to="/policies/pt/sales-contract">
                          Contrato de Serviço
                        </Link>
                        .
                      </div>
                      {validDates() ? (
                        <Button
                          className="mt-2"
                          onClick={() => handleSubmit(false)}
                        >
                          Reservar por apenas R${course?.reservationPrice},00
                        </Button>
                      ) : (
                        ""
                      )}
                    </Row>
                  ) : (
                    ""
                  )}

                  <div className="small mt-4 px-2 text-justify">
                    <strong>Atenção: </strong>
                    Este curso possui atividades presenciais obrigatórias e
                    precisamos conhecer sua disponibilidade máxima. Por favor,
                    selecione um intervalo de tempo disponível para verificarmos
                    a possibilidade de realização do curso. Considere ainda que
                    a reserva ou o pagamento total não garante que o curso será
                    realizado, e que ainda poderá ocorrer o reagendamento de
                    atividades em virtude de:
                    <br />
                    <ul className="mt-1">
                      <li>
                        Questões operacionais das atividades teóricas/práticas;
                      </li>
                      <li>
                        Inviabilidade de realização ao não atingir o número
                        mínimo de pessoas necessárias;
                      </li>
                      <li>
                        Condições climáticas que impeçam a navegação e/ou
                        mergulho e coloquem em risco a embarcação e/ou as
                        pessoas envolvidas.
                      </li>
                    </ul>
                  </div>
                </div>{" "}
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="mb-3">
            <Card className="bg-light border-0 mb-3 shadow">
              <Card.Body>
                <Card className="border border-light mt-0">
                  <Card.Body className="text-center">
                    <h6>
                      Certificação {course?.certifiedBy}&#174; homologada,
                      internacional e vitalícia
                    </h6>
                    <div className="display-4 mb-3 mt-4">
                      <small className="fs-5">R$</small>
                      {course?.price}
                      <small className="text-muted fs-5">,00</small>
                      <div className="small text-muted fs-6">
                        de investimento total <br /> reservas por apenas
                        <strong> R${course?.reservationPrice},00</strong>
                      </div>
                    </div>
                    <ListGroup variant="flush" className="rounded-2">
                      <ListGroup.Item>
                        <BsCheck size="25" /> Pagamento em até 12x
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <BsCheck size="25" />
                        Escolha entre diversas formas de pagamento
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <BsCheck size="25" />
                        Aceitamos pagamento com mais de um cartão
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <BsCheck size="25" />
                        Pague com segurança através do MercadoPago
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <BsCheck size="25" />
                        Cancelamento com devolução total por 7 dias
                      </ListGroup.Item>
                    </ListGroup>

                    <div className="mt-3">Confira nossas opções</div>
                    <Button
                      size="lg"
                      variant="danger"
                      className="mt-1"
                      disabled={!validDates()}
                      onClick={() => handleSubmit(true)}
                    >
                      {validDates()
                        ? "Pagar totalmente online"
                        : "Selecione datas para o curso"}
                    </Button>
                    <div className="form-text mb-3">
                      e aproveite toda a praticidade e segurança
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CourseBookingInfo />
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default CourseBooking;
