import React, { useState } from "react";
import { Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useUser } from "../../pages/ManageProfile";
import { RiseLoader } from "react-spinners";
import DeleteAccount from "./DeleteAccount";

function EditProfile() {
  const { user, handleChange } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col>
          <h1 className="display-6 fs-2">{t("edit_profile")}</h1>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <FloatingLabel label={t("email")}>
            <Form.Control
              className="mt-2"
              name="email"
              value={user?.email}
              plaintext
              readOnly
            />
          </FloatingLabel>
          <FloatingLabel label={t("name")}>
            <Form.Control
              className="mt-2"
              type="text"
              name="displayName"
              maxLength={50}
              value={user?.displayName}
              onChange={handleChange}
              required
            />
          </FloatingLabel>

          <FloatingLabel label={t("where_do_you_live")}>
            <Form.Control
              className="mt-2"
              type="text"
              name="home"
              maxLength={50}
              value={user?.home}
              onChange={handleChange}
            />
          </FloatingLabel>

          <Form.Group className="my-2">
            <Form.Check
              type="checkbox"
              label="Receber e-mails de notificação"
            />
          </Form.Group>

          <Button
            className="my-4 w-100"
            variant="dark"
            disabled={isLoading}
            type="submit"
            size="lg"
          >
            {isLoading ? <RiseLoader size={5} color="#fff" /> : t(`send`)}
          </Button>
        </Col>
      </Row>
      <hr className="my-5"></hr>
      <Row>
        <Col xl={12}>
          <DeleteAccount></DeleteAccount>
        </Col>
      </Row>
    </>
  );
}

export default EditProfile;
