import React from "react";
import { ListGroup } from "react-bootstrap";
import { IoIosCheckboxOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { LeanUser } from "../../interfaces/interfaces";

function ValidationChecklist({ collection }: { collection: LeanUser[] }) {
  const list: any = collection.map((l, i) => (
    <ListGroup.Item key={i}>
      <IoIosCheckboxOutline size="30" />
      <Link className="no-style-link" to={{ pathname: `/diver/${l.id}` }}>
        <span className="ms-2">{l.name}</span>
      </Link>
    </ListGroup.Item>
  ));

  return <ListGroup variant="flush">{list}</ListGroup>;
}

export default ValidationChecklist;
