export const TICKETS_BOOKER = {
  pick_the_date: "Escolha a data:",
  number_of_people: "Número de pessoas:",
  at_least_one: "É necessário pelo menos uma pessoa na operação.",
  max_reached: "O número máximo de pessoas permitido foi atingido.",
  contract_disclaimer:
    "Ao comprar ou reservar, você atesta que leu e está de acordo com nosso",
  contract: "Contrato de Serviço",
  total: "Total",
  buy: "Comprar",
  date: "Data",
};
