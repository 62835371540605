import React, { useEffect, useState } from "react";
import { Row, Col, Card, Badge, Container } from "react-bootstrap";
import NavBar from "../components/NavBar/NavBar";
import { Link, useParams } from "react-router-dom";
import {
  BsWater,
  BsStopwatch,
  BsEye,
  BsThermometer,
  BsThermometerHalf,
} from "react-icons/bs";
import { RxLapTimer } from "react-icons/rx";
import { GiSpeedometer, GiWeight } from "react-icons/gi";
import { IoMdSpeedometer } from "react-icons/io";
import { BiWater } from "react-icons/bi";
import { values } from "lodash";
import { useTranslation } from "react-i18next";
import LikeButton from "../components/Likes/LikeButton";
import TextBoard from "../components/TextBoard/TextBoard";
import LikesUsersList from "../components/Likes/LikesUsersList";
import CommentsAccordion from "../components/Comments/CommentsAccordion";
import { getLog } from "../model/LogModel";
import LoadingModal from "../components/Modal/LoadingModal";
import { Log } from "../interfaces/interfaces";
import ShareLogBtn from "../components/Buttons/ShareLogBtn";
import moment from "moment";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import ValidationChecklist from "../components/List/ValidationChecklist";
import { Helmet } from "react-helmet";
import { auth } from "../config/firebase";
import WhatsAppBtn from "../components/ShareButtons/WhatsAppBtn";
import CopyLinkBtn from "../components/Buttons/CopyLinkBtn";
import EditLogBtn from "../components/Buttons/EditLogBtn";
import ValidateLogBtn from "../components/Buttons/ValidateLogBtn";
import DeleteLogButton from "../components/LoggerForm/DeleteLogButton";
import { QRCodeSVG } from "qrcode.react";

function LogDetails() {
  const [log, setLog] = useState<Log>();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [likesState, setLikesState] = useState([]);
  const { t } = useTranslation();
  moment().locale("pt");

  async function fetchLog() {
    if (id) {
      const logData = await getLog(id, setLoading);
      if (logData) setLog(logData);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchLog();
  }, []);

  const diveTags = values(log?.tags).map((tag, i) => (
    <Badge key={i} bg="warning" text="dark" className="fs-6 m-1">
      {t(tag)}
    </Badge>
  ));

  return (
    <>
      <NavBar></NavBar>
      <Container className="my-3">
        <Helmet>
          <title>Detalhes do mergulho - GDN</title>
          <meta
            name="description"
            content="Página que mostra em detalhes um mergulho registrado no logbook virtual com todas as informações sobre o mergulho."
          />
        </Helmet>
        <Row>
          <Col
            xl={3}
            className="order-3  order-sm-3 order-md-3 order-lg-3 order-xl-1 order-xxl-1"
          >
            <Card className="text-center">
              <Card.Title className="mt-2">{t("diver")}</Card.Title>
              <Link
                className="no-style-link"
                to={{ pathname: `/diver/${log?.userId}` }}
              >
                <Card.Img
                  variant="top"
                  src={log?.userPicture}
                  className="p-2 mb-1"
                />
              </Link>
              <Card.Title className="mt-1">
                {" "}
                <Link
                  className="no-style-link"
                  to={{ pathname: `/diver/${log?.userId}` }}
                >
                  {log?.userName}
                </Link>
              </Card.Title>
            </Card>
            <Row>
              <Col className="text-center my-5">
                {log && (
                  <QRCodeSVG
                    value={`https://globaldiversnetwork.com/?redirect-to=log&id=${log.logId}`}
                    size={256}
                    includeMargin={false}
                  />
                )}
              </Col>
            </Row>

            {auth.currentUser &&
              log &&
              auth.currentUser.uid === log?.userId && (
                <DeleteLogButton logId={log.logId}></DeleteLogButton>
              )}
          </Col>
          <Col
            xl={6}
            className="order-1  order-sm-1 order-md-1 order-lg-1 order-xl-2 order-xxl-2"
          >
            <Row className="mb-2">
              <Col>
                {log && (
                  <WhatsAppBtn
                    variant="share-log"
                    resource={log}
                    label="Compartilhar"
                    size="sm"
                    iconSize="25"
                    className="rounded-2"
                  />
                )}
              </Col>
              <Col>
                <CopyLinkBtn
                  link={`https://globaldiversnetwork.com/?redirect-to=log&id=${log?.logId}`}
                  className="w-100"
                  size="sm"
                  iconSize="25"
                />
              </Col>
            </Row>
            {auth.currentUser &&
              log &&
              auth.currentUser.uid === log?.userId && (
                <Row className="mb-2">
                  <Col>
                    <EditLogBtn
                      log={log}
                      className="w-100"
                      size="sm"
                      iconSize="25"
                    ></EditLogBtn>
                  </Col>
                  <Col>
                    <ValidateLogBtn
                      log={log}
                      size="sm"
                      iconSize="25"
                    ></ValidateLogBtn>
                  </Col>
                </Row>
              )}
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={10}>
                    <div>
                      <h1>{log?.title}</h1>
                      {log && moment(log.date).format("DD [de] MMMM [de] YYYY")}
                    </div>
                    <div>
                      {log?.location}, {log?.diveSite}
                    </div>
                  </Col>
                  <Col xs={2} className="text-end">
                    <div>
                      {log?.userId === auth.currentUser?.uid
                        ? ""
                        : log && (
                            <LikeButton
                              resourceId={log?.logId}
                              resourceUserId={log?.userId}
                              resourceLikes={log?.likes}
                              setLikesState={setLikesState}
                            ></LikeButton>
                          )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12}>{diveTags}</Col>
                </Row>
              </Card.Body>
              <Row>
                <Col xs={12}>
                  {log?.video?.length && log?.video?.length > 0 ? (
                    <iframe
                      width="100%"
                      height="400"
                      src={`https://www.youtube.com/embed/${log?.video}?modestbranding=1&showinfo=0&autoplay=1&loop=1&playlist=${log?.video}&mute=1`}
                      title="YouTube video player"
                      frameBorder="0"
                      data-allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <Card.Img
                      variant="bottom"
                      src={log?.photo}
                      className="img-fluid"
                    />
                  )}
                </Col>
              </Row>
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    {log?.notes && (
                      <TextBoard
                        initValue={log.notes}
                        label={t("notes")}
                      ></TextBoard>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mt-3 mb-3">
              <Card.Body>
                {log && (
                  <CommentsAccordion
                    logId={log?.logId}
                    comments={log?.comments || []}
                    opened={true}
                  ></CommentsAccordion>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col
            xl={3}
            className="order-2 order-sm-2 order-md-2 order-lg-2 order-xl-3 order-xxl-3"
          >
            {log?.validatedBy && (
              <Card className="mb-2">
                <Row>
                  <Col>
                    <Card.Header className="bg-success text-white border-0">
                      <HiOutlineBadgeCheck size="25" className="ms-1 me-2" />
                      <strong>
                        {t("dive")} {t("validated").toLowerCase()}{" "}
                      </strong>
                    </Card.Header>

                    <ValidationChecklist
                      collection={log?.validatedBy}
                    ></ValidationChecklist>
                  </Col>
                </Row>
              </Card>
            )}
            {log && <ShareLogBtn resource={log}></ShareLogBtn>}
            <Card className="mb-2">
              <Card.Body className="text-start">
                <Row className="mb-3">
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <GiSpeedometer size={30} className="text-dark me-1" />
                      {log?.startingGas
                        ? " " + log.startingGas + "bar"
                        : " - bar"}
                    </span>
                    <br />
                    <small className="fs-6 mx-3">{t("starting_gas")}</small>
                  </Col>
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <IoMdSpeedometer size={30} className="text-dark me-1" />
                      {log?.finalGas ? log.finalGas + "bar" : " - bar"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("final_gas")}</span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <GiWeight size={30} className="text-dark me-1" />
                      {log?.weighting ? log.weighting + "Kg" : " - Kg"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("weighting")}</span>
                  </Col>
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <BsEye size={30} className="text-dark me-1" />
                      {log?.visibility ? log.visibility + "m" : " - m"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("visibility")}</span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <BsWater size={30} className="text-dark me-1" />
                      {log?.maximumDepth
                        ? " " + log.maximumDepth + "m"
                        : " - m"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("max_depth_abbr")}</span>
                  </Col>
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <BiWater size={30} className="text-dark me-1" />
                      {log?.averageDepth
                        ? " " + log.averageDepth + "m"
                        : " - m"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("average_depth_abbr")}</span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <BsStopwatch size={30} className="text-dark me-1" />
                      {log?.diveTime ? log.diveTime + "min" : " - min"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("time")}</span>
                  </Col>
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <RxLapTimer size={30} className="text-dark me-1" />
                      {log?.noDecoTime ? log.noDecoTime + "min" : " - min"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("deco_time_abbr")}</span>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <BsThermometer size={30} className="text-dark me-1" />
                      {log?.waterTemperature
                        ? " " + log.waterTemperature + "°C"
                        : " - °C"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">
                      {t("water_temperature_abbr")}
                    </span>
                  </Col>
                  <Col xs={6}>
                    <span className="fs-5 m-3">
                      <BsThermometerHalf size={30} className="text-dark me-1" />
                      {log?.temperature ? log.temperature + "°C" : " - °C"}
                    </span>
                    <br />
                    <span className="fs-6 mx-3">{t("temperature_abbr")}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {log?.likes && (
              <Card className="my-2">
                <Card.Header className="text-end bg-white">
                  <Row>
                    <Col xs={10} className="text-start">
                      <strong>{t("likes")} </strong>
                    </Col>
                    <Col xs={2}>
                      {log?.likes && log?.likes.length > 0 ? (
                        <Badge pill bg="dark" text="light">
                          {log?.likes.length}
                        </Badge>
                      ) : (
                        " "
                      )}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <LikesUsersList likes={log?.likes || []}></LikesUsersList>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>

      <LoadingModal shouldShow={loading}></LoadingModal>
    </>
  );
}

export default LogDetails;
