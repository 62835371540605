import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FileUploader from "../FileUploader/FileUploader";
import { useUser } from "../../pages/ManageProfile";
import { getAuth, updateProfile } from "firebase/auth";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";
import ErrorModal from "../Modal/ErrorModal";
import LoadingModal from "../Modal/LoadingModal";
import SuccessModal from "../Modal/SuccessModal";
import DeleteCheckModal from "../Modal/DeleteCheckModal";
import { USER_AVATAR } from "../../config/constants";

function ProfilePhoto() {
  const { user, setUser } = useUser();
  const [photo, setPhoto] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDeleteCheck, setShowDeleteCheck] = useState(false);
  const { t } = useTranslation();

  async function deletePhoto() {
    const userAvatar = USER_AVATAR;
    const auth = getAuth();
    const currentUser: any = auth.currentUser;

    const userRef = doc(db, "users", currentUser.uid);
    updateProfile(currentUser, {
      photoURL: userAvatar,
    })
      .then(() => {
        updateDoc(userRef, { photoUrl: userAvatar });
      })
      .then(() => {
        setUser({ ...user, photoUrl: userAvatar });
        alert(t("photo_deleted"));
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => setShowDeleteCheck(false));
  }

  useEffect(() => {
    if (!photo) return;
    persistPhoto();
  }, [photo]);

  async function persistPhoto() {
    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser: any = auth.currentUser;
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        ...user,
        photoUrl: photo || user.photoUrl,
      });
      await updateProfile(currentUser, { photoURL: photo || user.photoUrl });
      setUser({ ...user, photoUrl: photo || user.photoUrl });
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
      setError(false);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h1 className="display-6 fs-2">{t("profile_picture")}</h1>
          <hr></hr>
          <span>{t("select_profile_picture")}</span>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <FileUploader
            setPhoto={setPhoto}
            setLoading={setLoading}
            photo={user?.photoUrl}
          ></FileUploader>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <hr></hr>
          <Button
            variant="outline-secondary mt-3"
            size="sm"
            onClick={() => setShowDeleteCheck(true)}
          >
            {t("delete_photo")}
          </Button>
        </Col>
      </Row>

      <SuccessModal shouldShow={success}></SuccessModal>
      <LoadingModal shouldShow={loading}></LoadingModal>
      <ErrorModal shouldShow={error} redirectTo="/"></ErrorModal>
      <DeleteCheckModal
        shouldShow={showDeleteCheck}
        message={t("delete_photo_check")}
        setShowDeleteCheck={setShowDeleteCheck}
        proceed={deletePhoto}
      ></DeleteCheckModal>
    </>
  );
}

export default ProfilePhoto;
