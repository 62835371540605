import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function PoliticaDeCookies() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="text-justify">
        <Helmet>
          <title>Política de cookies - GDN</title>
          <meta
            name="description"
            content="Documento que apresenta a política de cookies adotada no site da rede social para mergulhadores Global Divers Network."
          />
        </Helmet>
        <Row className="mt-2">
          <Col>
            <h1>Política de cookies da Global Divers Network</h1>
            <small className="text-mutted">
              Data de vigência: 27 de Janeiro de 2023
            </small>
            <hr></hr>
            <h3>O que é um cookie?</h3>
            <p>
              Um cookie é um dado que pode ser armazenado no navegador que você
              usa para acessar a Global Divers Network e que permite recursos e
              funcionalidades. Também permite que nosso site "lembre" de suas
              atividades ou preferências durante um período de tempo ou durante
              uma sessão específica. Se você está registrado na Global Divers
              Network, os cookies nos permitem mostrar as informações corretas e
              personalizar a sua experiência.
              <br />
              Alguns dos cookies que utilizamos foram desenvolvidos e
              implementados para facilitar a nossa oferta de serviços. Estes são
              conhecidos por cookies originais. Nós também usamos cookies de
              terceiros, criados pelos nossos parceiros terceirizados e usados
              no nosso site, conforme descrito em detalhes abaixo.
            </p>
            <h3>Como a Global Divers Network utiliza cookies?</h3>
            <p>
              Usamos cookies de sessão e cookies persistentes. Cookies de sessão
              são cookies temporários que não são armazenados no seu navegador
              depois da sessão em questão. Eles são usados para fins de
              autenticação e segurança e para garantir o funcionamento eficaz e
              necessário das funções da Global Divers Network que você utiliza.
              Também utilizamos cookies de sessão para lembrar as suas
              preferências quando acessa o site. Estes cookies de sessão são
              excluídos quando você sai do navegador.
              <br />
              Cookies persistentes são aqueles ativados no seu navegador por um
              período predeterminado quando você visita o site.
            </p>
            <h3>Cookies originais</h3>
            <p>
              Desenvolvemos e utilizamos cookies em nosso site para autenticar
              usuários, bloquear o uso inadequado de suas credenciais de login e
              proteger os dados contra acessos não autorizados. Também
              desenvolvemos e utilizamos cookies para coletar informações sobre
              o site e o uso do aplicativo, a fim de aprofundar nossos
              conhecimentos e melhorar a Global Divers Network. Estes cookies
              também nos ajudam a entender como o site e o aplicativo funcionam
              em diferentes locais e a identificar quaisquer problemas na
              operação e no fornecimento de nossos produtos.
            </p>
            <h3>Cookies de terceiros</h3>
            <p>
              A configuração de cookies de terceiros também é permitida em nosso
              site. Estes cookies nos ajudam a avaliar e entender como os nossos
              produtos são usados e como eles podem ser otimizados. Também
              podemos receber outras informações analíticas destes terceiros.
              <br />
              Alguns desses cookies estão relacionados à publicidade e os usamos
              para exibir anúncios relevantes para quem já visitou o nosso site.
              Os cookies que usamos para fins publicitários são do Google.
              <br />
              Outros cookies são usados para determinar o desempenho e os dados
              analíticos ou para realizar pesquisas comerciais. Os cookies que
              usamos para avaliar o desempenho e para fins analíticos e de
              pesquisa são do Google.
            </p>
            <h3>Gerenciar e recusar o uso de cookies</h3>
            <p>
              A maioria dos navegadores permite que você escolha se aceita ou
              não cookies. Os vários navegadores fornecem ferramentas de
              controle diferentes e, portanto, indicamos abaixo os links para as
              instruções fornecidas pelos navegadores mais usados sobre como
              fazer essa escolha. Como se tratam de links para serviços
              externos, não podemos garantir sua disponibilidade ou atualização.
              Em geral, seu navegador oferece a possibilidade de aceitar,
              rejeitar ou excluir todos os cookies ou cookies de terceiros em um
              site ou os de sites específicos. No entanto, se você limitar nossa
              capacidade de ativar cookies, é possível que você também limite as
              funcionalidades da Global Divers Network e, portanto, não poderá
              usar alguns de nossos serviços e recursos.
            </p>
            <ul>
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Chrome
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank"
                  rel="noreferrer"
                >
                  Internet Explorer
                </a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/pt-BR/kb/impeca-que-sites-armazenem-cookies-e-dados-no-fire"
                  target="_blank"
                  rel="noreferrer"
                >
                  Firefox
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                  target="_blank"
                  rel="noreferrer"
                >
                  Safari
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/pt-br/HT201265"
                  target="_blank"
                  rel="noreferrer"
                >
                  Safari no iPhone, iPad ou iPod
                </a>
              </li>
              <li>
                <a
                  href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Opera
                </a>
              </li>
            </ul>
            <h3>Do Not Track (DNT)</h3>
            <p>
              Pode acontecer que não reconheçamos os sinais do recurso Do Not
              Track (“DNT”) do navegador ou dispositivo que você usa. Isto
              ocorre porque ainda não existe um padrão universalmente aceito
              sobre como interpretar e responder aos sinais DNT, que geralmente
              entram em conflito com outras configurações. Se e quando um padrão
              for estabelecido, revisaremos como podemos responder adequadamente
              aos sinais DNT.
            </p>
            <h3>Alterações a esta Política</h3>
            <p>
              Esta política pode ser alterada periodicamente, por isso sugerimos
              que você a consulte com frequência para ter acesso às informações
              mais atuais sobre o uso de cookies. Iremos notificá-lo sempre que
              fizermos alterações significativas nesta página.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default PoliticaDeCookies;
