export type LoggerProps = {
  title: string;
  date: string;
  maximumDepth?: number;
  diveTime?: number;
  diveSite?: string;
  location: string;
  finalGas?: number;
  averageDepth?: number;
  temperature?: number;
  noDecoTime?: number;
  weighting?: number;
  waterTemperature?: number;
  startingGas?: number;
  visibility?: number;
  notes?: string;
  logId?: string;
  latLng?: string;
};

export const loggerInitialState = {
  title: "",
  date: "",
  location: " ",
  diveSite: undefined,
  temperature: undefined,
  waterTemperature: undefined,
  visibility: undefined,
  startingGas: undefined,
  finalGas: undefined,
  maximumDepth: undefined,
  diveTime: undefined,
  noDecoTime: undefined,
  averageDepth: undefined,
  weighting: undefined,
  notes: undefined,
};

export type User = {
  id: string;
  displayName: string;
  dives: Array<string>;
  authProvider: string;
};

export type AuthUser = {
  uid: string;
  displayName: string;
  email: string;
  photURL?: string;
};

export type Variant =
  | "outline-success"
  | "outline-warning"
  | "outline-danger"
  | "outline-warning"
  | "outline-primary";

export type Tag = {
  tags: Array<string>;
  variant?: Variant;
  style?: string;
};

export type Like = {
  resourceId: string;
  userId: string;
  userName: string;
  userPicture: string;
};

export interface Likes {
  likes: Like[];
}

export type LogVisibility =
  | "deleted"
  | "hidden"
  | "owner"
  | "followers"
  | "everyone";

export type UserStatus = "active" | "deleted";

export type Comment = {
  userId: string;
  userName: string;
  userPicture: string;
  messageText: string;
  length: number;
  timeStamp: number;
};

export type Log = {
  userId: string;
  userPicture: string;
  userName: string;
  logId: string;
  title: string;
  date: string;
  diveSite: string;
  location: string;
  photo: string;
  video: string;
  tags: any;
  likes: Like[];
  validatedBy: LeanUser[];
  comments: Comment[];
  logVisibility: LogVisibility;
  temperature: number;
  waterTemperature: number;
  noDecoTime: number;
  diveTime: number;
  averageDepth: number;
  maximumDepth: number;
  visibility: number;
  weighting: number;
  finalGas: number;
  startingGas: number;
  notes: string;
  latLng: string;
};

export type LeanUser = {
  id: string;
  photo: string;
  name: string;
};

export type Chat = {
  userPairs: Object;
  messages: Message[];
};

export type Message = {
  sender: LeanUser;
  text: string;
  read: boolean;
  createdAt: number;
};

export type Size = "sm" | "lg" | undefined;

export interface DiveCenter {
  name: string;
  address: string;
  phone: string;
  email: string;
  instagram?: string;
  facebook?: string;
  youtube?: string;
  tripAdvisor?: string;
  divingActivities: Array<string>;
  diveSites: string;
  about: string;
  otherInfo: string;
}

export const DiveCenterInitialState = {
  name: "",
  address: "",
  phone: "",
  email: "",
  instagram: undefined,
  facebook: undefined,
  youtube: undefined,
  tripAdvisor: undefined,
  divingActivities: [],
  diveSites: undefined,
  about: undefined,
  otherInfo: undefined,
};

export type CourseCardData = {
  title: string;
  img: string;
  text: string;
  level?: string;
  link?: string;
};

export interface Course {
  id: string;
  name: string;
  category: string;
  location: string;
  locationPhoto: string;
  certifiedBy: string;
  diveCenter: string;
  price: number;
  reservationPrice: number;
  description: string;
  daysPresenceRequired: number;
  booking_init_point?: string;
  init_point?: string;
}

export interface Place {
  id: string;
  name: string;
  fullName: string;
  state: string;
  img: string;
}
