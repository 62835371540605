import React, { FormEvent, useState } from "react";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import { RiseLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import "../locales/i18n";
import "./login.css";
import GDNy from "../components/GDNbrand/GDNy";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect-to");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();

  onAuthStateChanged(auth, (usr) => {
    if (usr) {
      if (redirectTo === "log") {
        navigate(`/${redirectTo}/${id}`);
        return;
      }
      if (redirectTo === "validate-log") {
        navigate(`/${redirectTo}/${id}`);
        return;
      }
      if (redirectTo === "dive-center/registration") {
        navigate(`/${redirectTo}`);
        return;
      }
      if (redirectTo === "checkout/mp") {
        navigate(`/${redirectTo}`, { state });
        return;
      }
      navigate("/");
    }
  });

  const logInWithEmailAndPassword = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
      alert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      return;
    }

    logInWithEmailAndPassword(email, password);
  }

  return (
    <div className="login">
      <Helmet>
        <title>Global Divers Network</title>
        <meta
          name="description"
          content={t("enter") + " - Global Divers Network" || ""}
        />
      </Helmet>
      <div className="text-center">
        <main className="form-signin w-100 m-auto">
          <div>
            <GDNy className="mb-3" />
          </div>
          <hr className="mb-4"></hr>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <FloatingLabel label={t("email")}>
              <Form.Control
                type="email"
                className="form-control mb-2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label={t("password")}>
              <Form.Control
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FloatingLabel>

            <Button
              type="submit"
              id="signin-btn"
              variant="primary"
              className="w-100 btn-lg mb-1 mt-2"
              disabled={isLoading}
            >
              {isLoading ? <RiseLoader size={5} color="#fff" /> : t("enter")}
            </Button>
          </Form>
          <hr></hr>

          <Button variant="danger" className="w-100" size="lg">
            <Link
              to={{
                pathname: "/register",
                search: `?redirect-to=${redirectTo}&id=${id}`,
              }}
              className="no-style-link"
            >
              {" "}
              {t("signup")}{" "}
            </Link>
          </Button>
          <hr></hr>
          <div className="text-start">
            <Link to="/reset-password">{t("click_here")} </Link>
            {t("if_forgot_pass")}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Login;
