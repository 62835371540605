import React, { useState } from "react";
import { deleteUser, getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { logout } from "../../authentication/Logout";
import DeleteCheckModal from "../Modal/DeleteCheckModal";
import LoadingModal from "../Modal/LoadingModal";
import { changeLogsVisibility } from "../../model/LogModel";
import { setUserStatus } from "../../model/UserModel";
import { useNavigate } from "react-router-dom";

function DeleteAccount() {
  const [showDeleteCheck, setShowDeleteCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function handleDeleteUser() {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) navigate("/login");

    setLoading(true);

    if (!currentUser) return;

    deleteUser(currentUser)
      .then(() => {
        setLoading(false);
        alert(t("account_deleted"));
      })
      .catch((err) => {
        alert(t("please_login_again"));
        logout();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function setDeleteStatus(userId: string) {
    setLoading(true);

    setUserStatus(userId, "deleted")
      .then(() => {
        handleDeleteUser();
      })
      .catch((err) => alert(t("request_error")))
      .finally(() => {
        setLoading(false);
      });
  }

  async function hideLogs() {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) navigate("/login");
    const currentUserId = currentUser?.uid;
    setLoading(true);

    if (!currentUserId) return;

    changeLogsVisibility(currentUserId, "deleted")
      .then(() => {
        setDeleteStatus(currentUserId);
      })
      .catch((err) => {
        alert(t("request_error"));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Button variant="link" onClick={() => setShowDeleteCheck(true)} size="sm">
        {t("delete_account")}
      </Button>
      <DeleteCheckModal
        shouldShow={showDeleteCheck}
        message={
          t("all_data_will_be_removed") + "! \n" + t("sure_and_continue")
        }
        setShowDeleteCheck={setShowDeleteCheck}
        proceed={hideLogs}
      ></DeleteCheckModal>
      <LoadingModal shouldShow={loading}></LoadingModal>
    </>
  );
}

export default DeleteAccount;
