import React from "react";
import MailtoBtn from "../Buttons/MailtoBtn";
import { Card } from "react-bootstrap";

function ContactCard() {
  return (
    <Card className="p-5 shadow">
      <h4>Ficou com alguma dúvida?</h4>
      <p>
        Por favor, entre em contato conosco através do email:{" "}
        <MailtoBtn email="contato@globaldiversnetwork.com" />
        <br />
        Ficaremos felizes em receber sua mensagem e retornaremos o contato o
        mais breve possível.
        <br />
        Obrigado.
      </p>
    </Card>
  );
}

export default ContactCard;
