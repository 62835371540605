import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdOutlineContentCopy } from "react-icons/md";
import { Size } from "../../interfaces/interfaces";

function CopyLinkBtn({
  link,
  className,
  size,
  iconSize,
}: {
  link: string;
  className?: string;
  size?: Size;
  iconSize?: string;
}) {
  const { t } = useTranslation();

  function copyLink() {
    navigator.clipboard.writeText(link);
    alert(t("link") + " " + t("copied").toLowerCase());
  }

  return (
    <Button
      variant="dark"
      size={size ? size : undefined}
      onClick={() => copyLink()}
      className={className}
    >
      <MdOutlineContentCopy
        size={iconSize ? iconSize : "30"}
        className="mx-2"
      />
      {t("copy") + " " + t("link").toLowerCase()}
    </Button>
  );
}

export default CopyLinkBtn;
