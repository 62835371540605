export const TRANSLATIONS_PT = {
  date_format_one: "{{-date, YYYY/MM/DD}}",
  date_format_two: "{{date, DD/MM/YYYY}}",
  signin: "Login",
  email: "Email",
  password: "Senha",
  login_with_google: "Entrar com Google",
  no_account: "Você ainda não tem uma conta?",
  register: "Criar",
  now: "agora",
  click_here: "Clique aqui",
  if_forgot_pass: "se você esqueceu sua senha.",
  title: "Título",
  dive_number: "Mergulho de número:",
  dive_type: "Tipo de mergulho",
  dive: "Mergulho",
  night_dive: "Noturno",
  baptism: "Batismo",
  checkout: "Checkout",
  drift: "Correnteza",
  deep_dive: "Profundo",
  wreck: "Naufrágio",
  cave_dive: "Caverna",
  technical_dive: "Mergulho Técnico",
  boat_dive: "Embarcado",
  ice_dive: "No Gelo",
  free_dive: "Mergulho Livre",
  altitude: "Altitude",
  snorkeling: "Snorkel",
  shore_diving: "Saída de Praia",
  choose: "Selecione",
  created_with_success: "Criado com sucesso!",
  request_error: "Erro no processamento. Por favor, tente novamente.",
  date: "Data",
  dive_site: "Ponto de mergulho",
  location: "Localidade",
  notes: "Observações sobre o mergulho:",
  max_depth: "Profundidade máxima",
  max_depth_abbr: "P. máxima",
  average_depth: "Profundidade média",
  average_depth_abbr: "P. média",
  dive_time: "Tempo de mergulho",
  deco_time: "Tempo de fundo",
  deco_time_abbr: "T. de fundo",
  visibility: "Visibilidade",
  water_condition: "Condição da água",
  water_temperature: "Temperatura da água",
  water_temperature_abbr: "T. Água",
  water_kind: "Tipo de água",
  calm: "Calma",
  rough: "Agitada",
  seawater: "Água do mar",
  fresh_water: "Água doce",
  tide: "Maré",
  gas: "Gás",
  air: "Ar",
  nitrox: "Nitrox",
  starting_gas: "Gás inicial",
  final_gas: "Gás final",
  temperature: "Temperatura ambiente",
  temperature_abbr: "T. ambiente",
  equipment_notes: "Notas sobre o equipamento utilizado:",
  suit: "Roupa",
  weighting: "Lastro",
  create: "Criar",
  image_upload: "Upload de fotos",
  upload: "Fazer upload",
  dives: "Mergulhos",
  save: "Salvar",
  phone: "Telefone",
  name: "Nome",
  birthday: "Data de nascimento",
  city: "Cidade",
  state: "Estado",
  country: "País",
  address: "Endereço",
  zipcode: "CEP",
  update: "Atualizar",
  pic_a_photo: "Atualizar foto de perfil",
  time: "Tempo",
  depth: "Profundidade",
  dives_number: "Número total de mergulhos",
  diver_self_description_form:
    "Conte um pouco sobre você, sua relação e experiência com o mergulho:",
  successfully_updated: "Atualizado com sucesso",
  dive_master: "Dive Master",
  open_water: "Open Water",
  freediver: "Freediver",
  diver_to_be: "Futuro Mergulhador",
  specialty_diver: "Mergulhador Especialista",
  advanced_open_water: "Advanced Open Water",
  rescue_diver: "Mergulhador de resgate",
  advanced_freediver: "Freediver Avançado",
  dive_guide: "Guia de Mergulho",
  assistant_instructor: "Instrutor Assistente",
  instructor: "Instrutor",
  specialty_instructor: "Instrutor de Especialidade",
  instructor_trainer: "Treinador de Instrutores",
  freediving_instructor: "Instrutor de Freediving",
  tech_diver: "Mergulhador Técnico",
  tech_instructor: "Instrutor de Mergulho Técnico",
  diving_agency_owner: "Dono/gerente de Operadora",
  travel_agency_owner: "Agente de viagens",
  hotel_owner: "Dono/gerente de hotel/pousada/resort",
  lake: "Lago",
  select_at_least_one_tag: "Por favor, selecione pelo menos uma tag.",
  edit: "Editar",
  fill_required_fields: "Por favor, preencha todos os campos obrigatórios.",
  select_all_dive_tags:
    "Selecione as tags que melhor representam este mergulho:",
  full_name: "Nome Completo",
  invitation_code: "Código do Convite",
  create_account: "Crie uma conta na GDN",
  already_registered: "Já possui uma conta",
  enter: "Entrar",
  invalid: "Inválido",
  verify_email: "Verifique o seu email",
  sent_verification_link: "Enviamos um link de confirmação para o seu email.",
  to_finish_registration: "Para concluir seu cadastro, basta clicar nele.",
  if_not_found: "Caso não encontre o email na caixa principal",
  check_spam: "não esqueça de conferir sua caixa de spam",
  password_reset_sent:
    "Enviamos um email com instruções para você criar uma nova senha.",
  reset_password: "Crie uma nova senha",
  password_reset_instructions: "Informe seu email cadastrado na GDN:",
  will_send_reset_instructions:
    "Enviaremos instruções para que você recupere sua senha   para este endereço.",
  registered_email: "Digite seu email cadastrado",
  forgot_password: "Esqueceu sua senha?",
  send_password_reset: "Enviar",
  loading: "Carregando",
  successfully_saved: "Salvo com sucesso",
  edit_profile: "Editar Perfil",
  successfully_processed: "Processado com succeso",
  error_processing: "Erro no processamento",
  please_try_again: "Por favor, tente novamente mais tarde",
  send: "Enviar",
  profile_picture: "Foto de Perfil",
  profile: "Perfil",
  diver_profile: "Perfil de Mergulhador",
  buoyancy: "Flutuabilidade",
  navigation: "Navegação",
  dry_suit: "Roupa Seca",
  photo_video: "Foto & Video",
  shark_dive: "Mergulho com Tubarões",
  sidemount: "Sidemount",
  nothing_else_to_load: "Não existem mais dados referentes a sua busca.",
  likes: "Curtidas",
  delete_photo_check:
    "Você tem certeza que deseja excluir esta foto permanentemente?",
  action_cant_be_undone: "Esta operação não pode ser desfeita!",
  all_data_will_be_removed:
    "Todos os seus dados, fotos, logs e outras informações serão excluídos",
  sure_and_continue: "Você tem certeza que deseja prosseguir?",
  delete_photo: "Excluir foto",
  delete: "Excluir",
  cancel: "Cancelar",
  photo_deleted: "Foto excluída!",
  select_profile_picture: "Selecione uma foto para o seu perfil:",
  click_save_to_confirm: "Clique em 'Salvar' para confirmar a operação.",
  delete_account: "Excluir conta",
  click_to_delete_account: "Click no botão abaixo para excluir sua conta",
  permanently: "permanentemente",
  attention: "Atenção",
  please_login_again:
    "Por favor, entre de novo na sua conta, para confirmar sua identidade, e recomece a operação.",
  account_deleted: "Conta excluída com sucesso",
  everyone: "Todos usuários",
  followers: "Seguidores",
  only_me: "Somente eu",
  who_can_see: "Quem pode ver este log?",
  spearfishing: "Pesca Sub",
  comment: "Comentário",
  comments: "Comentários",
  chars_left: "caracteres restantes",
  delete_log: "Excluir Log",
  delete_log_check:
    "Tem certeza que deseja excluir este Log? Esta operação não poderá ser desfeita e todos os dados serão perdidos ao prosseguir.",
  type_your_message: "Digite sua mensagem",
  follow: "Seguir",
  following: "Seguindo",
  generic_error:
    "Houve um erro na requisição. Por favor, tente de novo mais tarde.",
  community: "Comunidade",
  community_standards: "Regras",
  use_terms: "Termos de uso",
  legal_bases: "Bases legais",
  cookie_policy: "Política de cookies",
  use_cookies: "Este website utiliza cookies",
  saved: "Salvo",
  validate_qr_code: "Valide seu mergulho compartilhando o QRcode acima",
  not_informed: "Não informado",
  validate: "Validar",
  cant_validate_own_log: "Você não pode validar seu próprio mergulho.",
  successfully_validated: "Mergulho validado com sucesso.",
  dive_already_validated: "Você já validou este mergulho.",
  validate_dive_from: "Valide o mergulho de ",
  or: "ou",
  ask_for_validation: "peça validação",
  via: "via",
  validate_dive_on: "Olá, dupla! Você pode validar nosso mergulho do dia ",
  at_dive_site: "no ponto ",
  here_is_the_link: "Aí vai o link: ",
  required_info: "Informação obrigatória",
  share: "Compartilhar",
  check_out_dive: "Confira este mergulho incrível na Global Divers Network! ",
  share_this_dive:
    "Compartilhe este mergulho por meio do QRcode acima ou escolha uma das opções abaixo:",
  copy: "Copiar",
  link: "Link",
  copied: "Copiado",
  signup: "Criar uma conta",
  thanks: "Obrigado",
  diver: "Mergulhador(a)",
  validated: "Validado",
  dives_by: "Mergulhos de ",
  add_dive_to_logbook:
    "Adicionar registro ao meu logbook. É possível editar, adicionar ou excluir estas informações a qualquer momento.",
  recovery_password_description:
    "Recupere sua senha para acessar a rede social para mergulhadores Global Divers Network",
  where_do_you_live: "Onde você mora?",
  register_dive: "Registre seu mergulho",
  select_dive_options: "Selecione as opções que melhor caracterizam o mergulho",
  basic: "Iniciante",
  advanced: "Avançado",
  specialty: "Especialidade",
  professional: "Profissional",
  dive_courses: "Cursos de mergulho",
  policies: "Termos de uso",
  course: "Curso",
  diving_articles: "Artigos sobre mergulho",
  signoff: "Sair",
  dive_in: "Mergulhe em",
  where_we_are: "Onde estamos",
  courses: "Cursos",
  certified: "Certificados",
  in: "em",
  divers: "Mergulhadores",
  accompaniers: "Acompanhantes",
  accompanier: "Acompanhante",
  total: "Total",
  payment_info: "Pagamento e cancelamentos:",
  additional_information: "Informações importantes:",
};
