import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import WhatsAppBtn from "../ShareButtons/WhatsAppBtn";
import { Log } from "../../interfaces/interfaces";
import { BsCheck2All } from "react-icons/bs";

function ValidationModal({
  shouldShow,
  log,
  setShouldShow,
}: {
  shouldShow: boolean;
  log: Log;
  setShouldShow: Function;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Modal
      centered
      show={shouldShow}
      onHide={() => {
        setShouldShow(false);
        if (location.pathname === "/logger") navigate("/");
      }}
    >
      <Modal.Header closeButton>
        {" "}
        <BsCheck2All size="30" className="mx-2" />{" "}
        <strong>{t("validate") + " " + t("dive").toLowerCase()}</strong>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Row className="mt-4 mb-4">
          <Col>
            <QRCodeSVG
              value={`https://globaldiversnetwork.com/?redirect-to=validate-log&id=${log.logId}`}
              size={256}
              includeMargin={false}
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>
            {t("validate_qr_code")} <br />
            {t("or")} {t("ask_for_validation")} {t("via")}
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>
            <WhatsAppBtn variant="validation" resource={log}></WhatsAppBtn>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ValidationModal;
