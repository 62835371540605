import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import DownloadInfoBtn from "../../components/DownloadInfo/DownloadInfoBtn";
import Footer from "../../components/Footer/Footer";
import DeleteAccount from "../../components/ManageProfile/DeleteAccount";
import NavBar from "../../components/NavBar/NavBar";

function BasesJuridicas() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="text-justify mt-4">
        <Helmet>
          <title>Base jurídica - GDN</title>
          <meta
            name="description"
            content="Documento que descreve brevemente a base jurídica adotada pela rede social destinada a mergulhadores Global Divers Network."
          />
        </Helmet>
        <Row>
          <Col>
            <h1> Seus direitos e nossas bases jurídicas</h1>
            <small className="text-mutted">
              Data de vigência: 20 de Janeiro de 2023
            </small>
            <hr></hr>
            <p>
              A Global Divers Network têm o compromisso de fornecer a você
              informações significativas e opções sobre as informações que
              compartilha conosco. Explicamos como coletamos, usamos,
              compartilhamos e protegemos suas informações em nossa{" "}
              <Link to="/policies/pt/politica-de-privacidade">
                Política de privacidade{" "}
              </Link>
              . Fornecemos o mesmo conjunto de ferramentas e controles de
              privacidade a todos os nossos membros em todo o mundo que usam os
              sites da Global Divers Network e os aplicativos e serviços móveis
              relacionados (coletivamente, os “Serviços”). Com base nas leis da
              sua localização, direitos específicos podem estar disponíveis para
              você. Clique nos links abaixo para saber mais sobre seus direitos
              e nossas bases jurídicas para processar suas informações.
            </p>
            <hr></hr>
            <h3>Direitos dos membros do Espaço Econômico Europeu</h3>
            <h4>Seus direitos e como exercê-los</h4>
            <p>
              Se você estiver habitualmente localizado no Espaço Econômico
              Europeu (“EEE”) ou no Reino Unido (“RU”), terá o direito de
              acessar, retificar, fazer download ou apagar as suas informações,
              bem como o direito de restringir e opor-se a determinados
              processamentos das suas informações. Embora alguns desses direitos
              se apliquem de forma geral, certos direitos se aplicam somente em
              determinadas circunstâncias limitadas. Descrevemos esses direitos
              abaixo:
            </p>
            <h5>Objeção</h5>
            <p>
              Nos casos em que processarmos as suas informações com base em
              nossos interesses legítimos, você pode opor-se a esse
              processamento em certas circunstâncias. Nesses casos,
              interromperemos o processamento das suas informações, a menos que
              tenhamos bases legítimas para continuar o processamento ou se ele
              for necessário por motivos legais.
            </p>
            <h5>Acesso e portabilidade</h5>
            <p>
              Você pode acessar a maior parte das suas informações fazendo login
              na sua conta. Se precisar de acesso adicional ou se não for
              usuário da Global Divers Network, entre em contato conosco em
              <a href="mailto:#">contato@globaldiversnetwork.com</a>. Clique
              <DownloadInfoBtn label="aqui"></DownloadInfoBtn> para fazer o
              download de uma cópia dos seus dados.
            </p>
            <h5>Retificação, restrição, limitação e exclusão</h5>
            <p>
              Você também pode retificar, restringir, limitar ou excluir a maior
              parte das suas informações fazendo login na sua conta, como editar
              o seu perfil, excluir fotos que publicou, remover atividades
              individuais de exibição ou excluir a sua conta. Se não conseguir
              fazer isso, entre em contato conosco em{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>
            <h5>Revogação de consentimento</h5>
            <p>
              Se você tiver fornecido anteriormente o seu consentimento, para
              processamento e armazenamento de seus dados você terá o direito de
              retirar o seu consentimento ao processamento das suas informações
              a qualquer momento. Por exemplo, você pode retirar o seu
              consentimento atualizando as suas informações na{" "}
              <Link to="/manage-profile/edit-profile">
                página de edição de perfil
              </Link>
              . Em alguns casos, podemos continuar processando suas informações
              após você ter retirado o consentimento, se tivermos bases legais
              para isso ou se a sua remoção de consentimento tiver sido limitada
              a determinadas atividades de processamento.
            </p>
            <h5>Reclamação</h5>
            <p>
              Caso deseje levantar uma questão sobre o nosso uso das suas
              informações (e sem prejuízo a quaisquer outros direitos que você
              possa ter), você terá o direito de fazê-lo com sua autoridade de
              supervisão local.
            </p>
            <h3>
              Membros do Espaço Econômico Europeu: nossas bases jurídicas para o
              processamento de suas informações
            </h3>
            <p>
              A lei de proteção de dados (por exemplo, no EEE ou no Brasil)
              exige que as organizações tenham bases legais para coletar, usar,
              compartilhar e de outra forma processar informações sobre você.
              Embora alguns de seus direitos se apliquem de modo geral,
              determinados direitos somente se aplicam dependendo das bases
              jurídicas com que contamos para nosso processamento de dados.
              Explicaremos essas bases jurídicas e os seus direitos a seguir.
            </p>
            <h4>Para prestar os Serviços da Global Divers Network</h4>
            <p>
              Conforme descrito nos{" "}
              <Link to="/policies/pt/termos-de-uso">
                {" "}
                Termos de uso e de serviço
              </Link>{" "}
              , os principais Serviços da Global Divers Network não podem ser
              prestados, e os{" "}
              <Link to="/policies/pt/termos-de-uso">
                {" "}
                Termos de uso e de serviço
              </Link>{" "}
              não podem ser cumpridos, sem a Global Divers Network processar
              dados, inclusive para as seguintes finalidades:
            </p>
            <ul>
              <li>Criar sua conta e fornecer a você acesso aos Serviços.</li>
              <li>
                Registrar suas atividades e seus logs, incluindo seus dados de
                localização.
              </li>
              <li>
                Ajudar você a analisar seu desempenho. Por exemplo, para
                comparar seus consumo de gás o profundidade máxima em mergulhos
                anteriores.
              </li>
              <li>
                Ajudar você a gerenciar sua evolução no mergulho. Por exemplo,
                para definir metas e identificar pontos de melhoria.
              </li>
              <li>Processar possível assinatura de Serviços.</li>
              <li>Responder às suas solicitações de suporte e comentários.</li>
            </ul>
            <p>
              Como processamos os dados que você nos fornece e que são
              necessários para a execução de nosso contrato com você, você tem o
              direito de portar ou transferir esses dados se for residente
              habitual do EEE ou do Reino Unido.
            </p>
            <h4>Com o seu consentimento</h4>
            <p>
              Solicitamos a sua permissão para processar suas informações para
              determinados fins e você tem o direito de retirar o seu
              consentimento a qualquer momento. Solicitamos seu consentimento
              para:
            </p>
            <ul>
              <li>Enviar comunicações de marketing.</li>
              <li>
                Coletar e processar informações de produtos e serviços de
                terceiros, como Facebook ou Google, ou dispositivos e
                aplicativos, como seu relógio Mergulho, que você conecta ou
                retira dados para abastecer a Global Divers Network.
              </li>
              <li>
                Acessar fotos, localização e informações de contatos através de
                suas configurações baseadas no dispositivo, para que possamos
                prestar os serviços descritos quando você habilita as
                configurações.
              </li>
            </ul>

            <p>
              Quando processamos os dados que você nos fornece baseado em seu
              consentimento, você tem o direito de retirar o consentimento a
              qualquer momento. Você também tem o direito de{" "}
              <DownloadInfoBtn label="portar ou transferir os dados"></DownloadInfoBtn>
              .
            </p>
            <h4>
              Obrigação legal ou para o estabelecimento, exercício ou defesa de
              reivindicações legais
            </h4>
            <p>
              Nós processamos dados nos casos em que temos uma obrigação legal
              de fazê-lo, por exemplo, ao respondermos a um processo jurídico
              válido e vinculativo de órgãos de aplicação da lei para
              determinados dados. Consulte a seção “Requisitos legais” em nossa
              <Link to="/policies/pt/politica-de-privacidade">
                Política de privacidade{" "}
              </Link>{" "}
              para obter mais informações. Além disso, o processamento pode ser
              necessário para que possamos estabelecer, exercer ou defender
              reivindicações civis ou criminais relacionadas a um litígio real
              ou em potencial, inclusive para proteger os Serviços da Global
              Divers Network, nossos direitos de propriedade ou outros direitos
              legais, inclusive os de nossos membros ou parceiros.
            </p>
            <h4>Para proteger interesses vitais</h4>
            <p>
              Processamos dados nos casos em que for necessário para proteger um
              interesse que seja essencial para a vida de qualquer pessoa ou
              para proteger qualquer pessoa contra lesões corporais graves. Isso
              inclui o processamento de informações para combater conduta
              prejudicial dentro e fora de nossos Serviços.
            </p>
            <h4>Realização de uma tarefa de interesse público</h4>
            <p>
              Quando exigido pela lei da UE ou de um estado-membro da UE,
              podemos processar seus dados para realizar processamento de
              interesse público. Isso pode incluir proteger contra danos e
              realizar pesquisa para o bem social. Você tem o direito de se opor
              e de procurar restringir o nosso processamento dos seus dados
              pessoais quando processarmos dados usando esta base jurídica.
            </p>
            <h4>Cumprimento de interesses pessoais</h4>
            <p>
              Processamos suas informações para nossos interesses legítimos e
              para os interesses de terceiros, aplicando, também, medidas de
              segurança apropriadas que protejam a sua privacidade, direitos e
              interesses. Fazemos isso para:
            </p>
            <ul>
              <li>
                Comercializar os Serviços, atividades na Global Divers Network e
                outros produtos ou serviços comerciais. Por exemplo, nossos
                parceiros podem nos pagar para promover seus produtos e serviços
                na Global Divers Network. Essa é uma das formas nas quais
                podemos prestar os Serviços de modo sustentável.
              </li>
              <li>
                Manter nossos negócios conduzindo pesquisa e aprimorando os
                Serviços continuamente para fazer ofertas inovadoras e
                personalizadas a nossos usuários s e parceiros.
              </li>
              <li>
                Converter suas informações em forma agregada para nosso próprio
                uso e o de nossos parceiros.
              </li>
              <li>
                Manter os Serviços protegidos e em segurança usando informações
                para prevenir ou detectar violações de nossos{" "}
                <Link to="/policies/pt/termos-de-uso">
                  {" "}
                  Termos de uso e de serviço
                </Link>{" "}
                ou{" "}
                <Link to="/policies/pt/padroes-da-comunidade">
                  {" "}
                  Padrões da Comunidade{" "}
                </Link>
                , fraude ou abuso e outras condutas prejudiciais ou ilegais.
                Também podemos compartilhar informações com terceiros, inclusive
                órgãos de aplicação da lei, com essa finalidade.
              </li>
              <li>
                Promover os Serviços, inclusive campanhas de marketing por
                e-mail ou dentro do produto, para informar você sobre nossos
                Serviços ou serviços de terceiros.
              </li>
              <li>
                Permitir que você encontre novas maneiras de interagir usando os
                Serviços. Por exemplo, para participar de clubes ou eventos,
                seguir outros usuários e usar recursos que ajudam usuários ou
                mergulhadores a interagirem uns com os outros, como atividades
                em grupo recreativas ou profissionais.
              </li>
              <li>
                Permitir que você visualize suas atividades de novas maneiras.
                Por exemplo, criando gráficos ou métricas para visualização de
                seu desempenho.
              </li>
              <li>
                Personalizar os serviços para você. Podemos sugerir mergulhos,
                viagens, hospedagens ou agências de mergulho que possam
                interessar a você, mergulhadores ou atletas que você queira
                seguir ou novos recursos que queira experimentar. Consideramos
                nosso interesse legítimo de manter usuários ao garantir que
                oferecemos novas oportunidades, como mostrar locais e pontos de
                mergulho de interesse à nossa comunidade, e podemos usar
                informações de localização ao sugerir tais oportunidades.
              </li>
            </ul>
            <p>
              Você tem o direito de se opor e de procurar restringir o nosso
              processamento dos seus dados pessoais baseado em interesses
              legítimos. Acessar seus controles de privacidade para se opor ao
              processamento de seus dados pessoais. Se você tiver dúvidas sobre
              objeções, entre em contato conosco em{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>

            <h2>Direitos de usuários do Brasil</h2>
            <hr></hr>
            <p>
              Uma Autoridade Brasileira de Proteção de Dados ("DPA") será
              estabelecida e fornecerá diretrizes sobre como interpretar e
              implementar os requisitos da LGPD. Como essas diretrizes ainda não
              foram estabelecidas, nossa abordagem está sujeita a alterações.
            </p>
            <p>
              Se você estiver habitualmente localizado no Brasil, terá direitos
              sob a Lei Geral de Proteção de Dados ("LGPD"), incluindo o direito
              de acessar, retificar, fazer download ou apagar as suas
              informações, bem como o direito de restringir e opor-se a
              determinados processamentos das suas informações. Você pode
              encontrar mais informações sobre como exercer esses direitos na
              seção acima intitulada Direitos de Usuários do Espaço Econômico
              Europeu.
            </p>

            <p>
              Se você tiver dúvidas sobre seus direitos, pode entrar em contato
              conosco em <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>
            <p>
              A LGPD exige que tenhamos bases jurídicas para coletar, usar,
              compartilhar e processar informações sobre você. Você pode
              encontrar uma descrição de nossas bases jurídicas analisando a
              seção acima intitulada Usuários do Espaço Econômico Europeu:
              Nossas bases jurídicas para o processamento de suas informações.
            </p>
            <h2>Direitos de usuários da Alemanha</h2>
            <p>
              Se você mora na Alemanha, tem o direito de denunciar conteúdo que
              acredita ser ilegal de acordo com a Lei de Regulamentação da Rede
              ("NetzDG"). Para fazer uma denúncia, envie um e-mail para
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>
            <p>
              Observe que a Global Divers Network mantém adicionalmente{" "}
              <Link to="/policies/pt/padroes-da-comunidade">
                {" "}
                Padrões da Comunidade{" "}
              </Link>{" "}
              que proíbem certos tipos de conteúdo, incluindo discurso de ódio.
              Você pode denunciar conteúdo que acredite violar nossos{" "}
              <Link to="/policies/pt/padroes-da-comunidade">
                {" "}
                Padrões da Comunidade{" "}
              </Link>
              , através do email:{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>

            <h2>Direitos dos membros da Califórnia</h2>
            <p>
              Se você for residente da Califórnia, conforme definido no Código
              de Regulamentos da Califórnia, possui direitos ao abrigo da Lei de
              Privacidade do Consumidor da Califórnia de 2018 (a “CCPA”).
              Abaixo, fornecemos uma descrição de seus direitos e divulgações
              sobre suas informações pessoais.
            </p>
            <h3>Seus direitos e como exercê-los</h3>
            <h4>
              Direito de saber sobre as informações pessoais que coletamos e
              compartilhamos
            </h4>
            <p>
              A CCPA concede a você o direito de solicitar a divulgação de
              informações específicas que coletamos sobre você, o que fazemos
              depois que recebemos e validamos sua solicitação.
            </p>
            <p>
              <strong>
                A Global Divers Network não vende suas informações pessoais.
              </strong>
              No entanto, podemos divulgar determinadas informações pessoais
              para fins comerciais. Quando você solicitar o download de suas
              informações pessoais, incluiremos uma lista das categorias de
              informações pessoais que podemos ter divulgado sobre você, bem
              como as categorias de terceiros aos quais suas informações
              pessoais podem ter sido divulgadas.
            </p>
            <h4>Como fazer uma solicitação de divulgação</h4>
            <p>
              Você pode solicitar essas divulgações descritas acima clicando
              <DownloadInfoBtn label="aqui"></DownloadInfoBtn> para fazer o
              download de uma cópia dos seus dados.
            </p>
            <p>
              Você tem o direito de fazer uma solicitação gratuita duas vezes em
              qualquer período de 12 meses. Faremos a divulgação dentro de 45
              dias após o recebimento de sua solicitação, a menos que
              solicitemos uma extensão. Se razoavelmente precisarmos de uma
              extensão de 45 dias, notificaremos você da extensão dentro do
              período inicial de 45 dias.
            </p>
            <h4>Direito de exclusão</h4>
            <p>
              Você tem o direito de solicitar que excluamos suas informações
              pessoais, sujeito a determinadas exceções. Depois de recebermos e
              validarmos sua solicitação, excluiremos suas informações pessoais
              e também instruiremos nossos prestadores de serviços a excluir
              suas informações pessoais, a menos que uma exceção se aplique. Se
              você desejar excluir sua conta, clique em
              <DeleteAccount></DeleteAccount>.
            </p>
            <h4>Direito à não discriminação</h4>
            <p>
              Você tem o direito de não receber tratamento discriminatório pelo
              exercício de seus direitos de privacidade de acordo com o CCPA.
            </p>
            <h3>Divulgações sobre suas informações pessoais</h3>
            <h4>
              Categorias de informações que coletamos e divulgamos para fins
              comerciais
            </h4>
            <p>
              Coletamos as seguintes categorias de informações pessoais em
              conexão com os Serviços, conforme definido na CCPA. Além disso,
              nos últimos doze meses, divulgamos essas categorias de informações
              pessoais para fins comerciais:
            </p>
            <ul>
              <li>
                Identificadores, como seu nome real, ID de Usuário, endereço do
                Protocolo da Internet, endereço de e-mail e outros
                identificadores similares.
              </li>
              <li>
                Categorias de informações pessoais listadas nas disposições do
                California Customer Records, incluindo características físicas,
                como peso, e informações de pagamento, como o número do seu
                cartão de crédito.
              </li>
              <li>
                Características das classificações protegidas de acordo com a
                lei federal ou da Califórnia, como sexo e idade.
              </li>
              <li>
                Informações comerciais, como o registro de compra de produtos ou
                de assinatura.{" "}
              </li>
              <li>Informações biométricas, como seus dados de mergulho.</li>
              <li>
                Informações de atividades na Internet ou em outra rede
                eletrônica, como logs de sessão.
              </li>
              <li>
                Dados de geolocalização, como o local físico de logs
                registrados.
              </li>
              <li>
                Informações eletrônicas, visuais ou semelhantes, como fotos.
              </li>
              <li>
                Inferências extraídas de qualquer uma das informações acima para
                criar um perfil que reflete suas preferências, características,
                comportamento, habilidades e aptidões.
              </li>
            </ul>
            <p>
              De acordo com a lei da Califórnia, as informações pessoais não
              incluem:
            </p>
            <ul>
              <li>
                Informações publicamente disponíveis nos registros do governo.
              </li>
              <li>Informações de consumidor não identificadas ou agregadas.</li>
            </ul>
            <h4>Outras divulgações sobre suas informações pessoais</h4>
            <p>
              Nossa Política de privacidade abrange divulgações adicionais sobre
              suas informações pessoais que a CCPA exige que forneçamos a você.
              Para saber mais sobre as fontes de onde coletamos suas informações
              pessoais, os objetivos de negócios ou comerciais para os quais
              coletamos suas informações pessoais e as categorias de terceiros
              com quem compartilhamos suas informações pessoais, entre em
              contato conosco, conforme a sessão abaixo.
            </p>
            <h4>Como entrar em contato conosco</h4>
            <p>
              Se você tiver dúvidas sobre seus direitos ou nossas divulgações de
              acordo com a CCPA, entre em contato conosco através do email:{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
            </p>
            <h3>Direitos dos membros de Nevada</h3>
            <p>
              Não vendemos suas informações cobertas, conforme definido na Seção
              1.6 do Capítulo 603A dos Estatutos Revisados de Nevada. Se você
              reside em Nevada, tem o direito de enviar uma solicitação ao nosso
              endereço de email de solicitação designado{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a> sobre a
              venda de informações cobertas.
            </p>
            <h2>Alterações nestas informações</h2>
            <p>
              Reservamo-nos o direito de modificar estas informações a qualquer
              momento. Revise-as periodicamente. Se a Global Divers Network
              fizer alterações nestas informações, a página atualizada será
              publicada nos Serviços em tempo hábil.
            </p>
            <p>
              Perguntas e comentários sobre estas informações podem ser enviados
              por correio para o endereço abaixo ou para o email:
              <a href="mailto:#">contato@globaldiversnetwork.com</a>.
              <address>
                <strong>Global Divers Network</strong>
                <br />
                Rua Álamo, 148 - Balneário Cassino
                <br />
                Rio Grande - RS, Brasil
                <br />
                <strong>Email</strong>
                <br />
                <a href="mailto:#">contato@globaldiversnetwork.com</a>
              </address>
            </p>
            <p>© 2023 Global Divers Network</p>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default BasesJuridicas;
