import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";
import { LOCATIONS } from "../../data/locations";
import { Link } from "react-router-dom";

function WhereWeAre() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("where_we_are") + " - GDN"}</title>
        <meta
          name="description"
          content="Lista das cidades e locais onde prestamos serviços relacionados ao mergulho, batismo, cursos, viagens, equipamentos, manutenção e navegação para mergulhadores"
        />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/" className="no-style-link">
              Voltar ao início
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{t("where_we_are")}</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-2">
          {LOCATIONS.map((p, i) => (
            <Col>
              <Card className="border-0 same-height-imgs">
                <Link to={`/where-we-are/${p.id}`} className="no-style-link">
                  <Card.Img
                    className="rounded-3 border-0 shadow"
                    variant="top"
                    src={p.img}
                  />
                </Link>
                <Card.Body className="px-1 pt-2 h-100">
                  <Card.Title>
                    <Link
                      to={`/where-we-are/${p.id}`}
                      className="no-style-link"
                    >
                      {p.fullName}
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default WhereWeAre;
