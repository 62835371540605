import React from "react";
import { Link } from "react-router-dom";

const MailtoBtn = ({ email, label }: { email: string; label?: string }) => {
  if (!label) label = email;
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = `mailto:${email}`;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default MailtoBtn;
