import { BsCloudRainHeavy } from "react-icons/bs";
import { Certification } from "../interfaces/certifiers";
import { Dive, Entering, Level } from "../interfaces/dive";
import { DiveCenter } from "../interfaces/diveCenter";
import { LocationId } from "../interfaces/location";
import { concat, filter } from "lodash";

export const MAX_BOOKING_ADVANCE = 90; //days

export const MAX_AVAILABLE_PLACES = 99; //maximum people allowed on diving operations for diving centers that does not have a limitation on sales per operation

export const ilhabelaList: Array<Dive> = [
  {
    id: "ilhabela-sp-discovery",
    title: { pt: "Batismo: Discovery Scuba", en: "TO-DO" },
    description: {
      pt: "Descubra o mundo do mergulho com cilindro e faça o seu batismo",
      en: "TO-DO",
    },
    price: 320,
    level: "basic" as Level,
    entering: "dinghy" as Entering,
    cylinders: 1,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/batismo-ilhabela.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 160,
    accompanySnorkelPrice: 225,
    productDescription: {
      pt: "Discovery Scuba: Mergulho de batismo e saída em bote com 1 cilindro em Ilhabela - SP",
      en: "TO-DO",
    },
    requiredCertification: "none" as Certification,
    equipment: ["all"],
  },
  {
    id: "ilhabela-sp-shore-1-cyclinder",
    title: {
      pt: "Saída de praia com um cilindro",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho com saída de praia para mergulhadores com certificação",
      en: "TO-DO",
    },
    price: 350,
    level: "certified" as Level,
    entering: "shore" as Entering,
    cylinders: 1,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/ilhabela-estatua.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: false,
    productDescription: {
      pt: "Mergulho com saída de praia e 1 cilindro em Ilhabela - SP",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "ilhabela-sp-dinghy-1-cyclinder",
    title: {
      pt: "Mergulho embarcado com um cilindro",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho para mergulhadores credenciados com 1 cilindro embarcado",
      en: "TO-DO",
    },
    price: 370,
    level: "certified" as Level,
    entering: "dinghy" as Entering,
    cylinders: 1,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/mergulho-ilhabela-batismo.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 160,
    accompanySnorkelPrice: 225,
    productDescription: {
      pt: "Mergulho com saída em bote e 1 cilindro em Ilhabela - SP",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "ilhabela-sp-shore-2-cyclinders",
    title: {
      pt: "Saída de praia, dois cilindros",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho com saída de praia para mergulhadores credenciados",
      en: "TO-DO",
    },
    price: 510,
    level: "certified" as Level,
    entering: "shore" as Entering,
    cylinders: 2,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/shore.png",
    minBookingAdvance: 2,
    accompanyAllowed: false,
    productDescription: {
      pt: "Mergulho com saída de praia e 2 cilindros em Ilhabela - SP",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "ilhabela-sp-dinghy-2-cyclinders",
    title: {
      pt: "Mergulho embarcado com 2 cilindros",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho embarcado para mergulhadores credenciados",
      en: "TO-DO",
    },
    price: 535,
    level: "certified" as Level,
    entering: "dinghy" as Entering,
    cylinders: 2,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/mergulho-ilhabela.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 160,
    accompanySnorkelPrice: 225,
    productDescription: {
      pt: "Mergulho com saída em bote e 2 cilindros em Ilhabela - SP",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "ilhabela-sp-night-dive",
    title: {
      pt: "Mergulho noturno",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho noturno embarcado para mergulhadores com certificação avançada",
      en: "TO-DO",
    },
    price: 385,
    level: "advanced" as Level,
    entering: "dinghy" as Entering,
    cylinders: 1,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/night-dive.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 160,
    accompanySnorkelPrice: 225,
    productDescription: {
      pt: "Mergulho noturno embarcado com 1 cilindro em Ilhabela - SP",
      en: "TO-DO",
    },
    requiredCertification: "Advanced Open Water" as Certification,
    equipment: ["all"],
  },
  // {
  //   id: "ilhabela-sp-boat-snorkel",
  //   title: {
  //     pt: "Snorkel embarcado",
  //     en: "TO-DO",
  //   },
  //   description: {
  //     pt: "snorkel",
  //     en: "TO-DO",
  //   },
  //   price: 225,
  //   level: "basic" as Level,
  //   entering: "dinghy" as Entering,
  //   cylinders: 0,
  //   location: "Ilhabela - SP",
  //   locationId: "ilhabela-SP" as LocationId,
  //   diveCenter: "Prado's Dive",
  //   img: "/images/dives/batismo-de-mergulho-ilhabela-sp.jpeg",
  //   minBookingAdvance: 2,
  //   accompanyAllowed: true,
  //   accompanyPrice: 160,
  //   accompanySnorkelPrice: 225,
  //   productDescription: {
  //     pt: "Snorkel com passeio de bote em Ilhabela - SP",
  //     en: "TO-DO",
  //   },
  //   requiredCertification: "Open Water" as Certification,
  // },
  {
    id: "ilhabela-sp-wreck",
    title: {
      pt: "Mergulho em naufrágio",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho em naufrágio",
      en: "TO-DO",
    },
    price: 590,
    level: "advanced" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/mergulho-ilhabela-naufragio-velasquez.jpg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 470,
    accompanySnorkelPrice: 535,
    productDescription: {
      pt: "Mergulho em naufrágio em Ilhabela - SP",
      en: "TO-DO",
    },
    diveSites: ["Velazques", "Terezina", "Dart", "Aymoré"],
    quorumMin: 4,
    requiredCertification: "Advanced Open Water" as Certification,
    equipment: ["none"],
  },
  {
    id: "ilhabela-sp-ilha-dos-buzios-e-sumitica",
    title: {
      pt: "Ilha dos Búzios e Sumitica",
      en: "TO-DO",
    },
    description: {
      pt: "Ilha dos Búzios e Sumitica",
      en: "TO-DO",
    },
    price: 680,
    level: "basic" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/deep-blue.jpg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 570,
    accompanySnorkelPrice: 635,
    productDescription: {
      pt: "Megulho nas Ilhas dos Búzios e Sumitica em Ilhabela - SP",
      en: "TO-DO",
    },
    diveSites: ["Ilha dos Búzios", "Sumitica"],
    quorumMin: 6,
    extraFeatures: {
      pt: ["Churrasco à bordo", "Bebidas não alcoólicas"],
      en: [],
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["none"],
  },
  {
    id: "ilhabela-sp-parcel-da-fome-e-jabaquara",
    title: {
      pt: "Parcel da Fome e Jabaquara",
      en: "TO-DO",
    },
    description: {
      pt: "Parcel da Fome e Jabaquara",
      en: "TO-DO",
    },
    price: 590,
    level: "basic" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/turtle.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 470,
    accompanySnorkelPrice: 535,
    productDescription: {
      pt: "Megulho em Parcel da Fome e Jabaquara em Ilhabela - SP",
      en: "TO-DO",
    },
    diveSites: ["Parcel da Fome", "Jabaquara"],
    quorumMin: 4,
    requiredCertification: "Open Water" as Certification,
    equipment: ["none"],
  },
  {
    id: "ilhabela-sp-cabarau-1-cyclinder",
    title: {
      pt: "Mergulho em Cabarau com 1 cilindro",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho em Cabarau com 1 cilindro",
      en: "TO-DO",
    },
    price: 470,
    level: "basic" as Level,
    entering: "pier" as Entering, ///???
    cylinders: 1,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive",
    img: "/images/dives/prados/open-water-ilhabela.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: false,
    productDescription: {
      pt: "Megulho em Cabarau com 1 cilindro",
      en: "TO-DO",
    },
    diveSites: ["Cabarau"],
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "ilhabela-sp-cabarau-2-cylinders",
    title: {
      pt: "Mergulho em Cabarau com 2 cilindros",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho em Cabarau com 2 cilindros",
      en: "TO-DO",
    },
    price: 630,
    level: "basic" as Level,
    entering: "pier" as Entering, ///???
    cylinders: 2,
    location: "Ilhabela - SP",
    locationId: "ilhabela-SP" as LocationId,
    diveCenter: "Prado's Dive" as DiveCenter,
    img: "/images/dives/prados/trim.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: false,
    productDescription: {
      pt: "Megulho em Cabarau com 2 cilindro",
      en: "TO-DO",
    },
    diveSites: ["Cabarau"],
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
];

export const mergulhoMagicoList: Array<Dive> = [
  {
    id: "mergulhoMagico-batismo",
    title: {
      pt: "Mergulho de Batismo",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho de Batismo",
      en: "TO-DO",
    },
    price: 250,
    level: "basic" as Level,
    entering: "boat" as Entering, ///???
    cylinders: 1,
    location: "São José da Coroa Grande - PE",
    locationId: "saoJoseDaCoroaGrande-PE" as LocationId,
    diveCenter: "Mergulho Mágico",
    img: "/images/dives/mergulhoMagico/mergulho-magico.jpg",
    minBookingAdvance: 2, ///???
    accompanyAllowed: true,
    accompanyPrice: 150,
    accompanySnorkelPrice: 150, //????
    productDescription: {
      pt: "Batismo de Mergulho em São José da Coroa Grande - PE",
      en: "TO-DO",
    },
    //diveSites: ["Cabarau"],
    requiredCertification: "none" as Certification,
    equipment: ["all"],
  },
  {
    id: "mergulhoMagico-1-cyclinder",
    title: {
      pt: "Mergulho credenciado no mar de dentro",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho credenciado com 1 cilindro",
      en: "TO-DO",
    },
    price: 350,
    level: "certified" as Level,
    entering: "boat" as Entering,
    cylinders: 1,
    location: "São José da Coroa Grande - PE",
    locationId: "saoJoseDaCoroaGrande-PE" as LocationId,
    diveCenter: "Mergulho Mágico",
    img: "/images/dives/mergulhoMagico/mergulho-magico-mero.jpg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 150,
    accompanySnorkelPrice: 150,
    productDescription: {
      pt: "Mergulho embarcado no mar de dentro de São José da Coroa Grande para mergulhadores credenciados com 1 cilindro.",
      en: "TO-DO",
    },
    diveSites: ["Mar de dentro"],
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "mergulhoMagico-2-cyclinders",
    title: {
      pt: "Mergulho credenciado no mar de fora com 2 cilindros",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho credenciado com 2 cilindros",
      en: "TO-DO",
    },
    price: 780,
    level: "certified" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "São José da Coroa Grande - PE",
    locationId: "saoJoseDaCoroaGrande-PE" as LocationId,
    diveCenter: "Mergulho Mágico",
    img: "/images/dives/mergulhoMagico/mergulho-magico-naufragio.jpg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 150,
    accompanySnorkelPrice: 150,
    productDescription: {
      pt: "Mergulho embarcado em São José da Coroa Grande para mergulhadores credenciados com 2 cilindros.",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "mergulhoMagico-2-night-dive",
    title: {
      pt: "Mergulho Noturno",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho Noturno",
      en: "TO-DO",
    },
    price: 400,
    level: "certified" as Level,
    entering: "boat" as Entering,
    cylinders: 1,
    location: "São José da Coroa Grande - PE",
    locationId: "saoJoseDaCoroaGrande-PE" as LocationId,
    diveCenter: "Mergulho Mágico",
    img: "/images/dives/prados/mergulho-ilhabela.jpeg",
    minBookingAdvance: 2,
    accompanyAllowed: true,
    accompanyPrice: 150,
    accompanySnorkelPrice: 150,
    productDescription: {
      pt: "Mergulho noturno embarcado em São José da Coroa Grande para mergulhadores com certificação avançada.",
      en: "TO-DO",
    },
    requiredCertification: "Advanced Open Water" as Certification,
    equipment: ["all"],
  },
];

export const buziosList: Array<Dive> = [
  {
    id: "buzios-baptism",
    title: {
      pt: "Mergulho de Batismo",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho de Batismo",
      en: "TO-DO",
    },
    price: 300,
    level: "basic" as Level,
    entering: "boat" as Entering,
    cylinders: 1,
    location: "Armação dos Búzios - RJ",
    locationId: "buzios-RJ" as LocationId,
    diveCenter: "Búzios Divers" as DiveCenter,
    img: "/images/dives/buzios/buzios-batismo.jpeg",
    accompanyAllowed: true,
    accompanyPrice: 150,
    accompanySnorkelPrice: 200,
    productDescription: {
      pt: "Batismo de Mergulho em Armação dos Búzios - RJ.",
      en: "TO-DO",
    },
    requiredCertification: "none" as Certification,
    equipment: ["all"],
  },
  {
    id: "buzios-certified",
    title: {
      pt: "Mergulho embarcado para credenciados",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho embarcado com dois cilindros para mergulhadores com certificação.",
      en: "TO-DO",
    },
    price: 450,
    level: "certified" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "Armação dos Búzios - RJ",
    locationId: "buzios-RJ" as LocationId,
    diveCenter: "Búzios Divers" as DiveCenter,
    img: "/images/dives/buzios/buzios-credenciado.jpeg",
    accompanyAllowed: true,
    accompanyPrice: 150,
    accompanySnorkelPrice: 200,
    productDescription: {
      pt: "Mergulho embarcado com dois cilindros para mergulhadores com certificação, em Armação dos Búzios - RJ.",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
  },
  {
    id: "buzios-night-dive",
    title: {
      pt: "Mergulho Noturno",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho noturno para mergulhadores com certificação avançada",
      en: "TO-DO",
    },
    price: 400,
    level: "advanced" as Level,
    entering: "shore" as Entering,
    cylinders: 1,
    location: "Armação dos Búzios - RJ",
    locationId: "buzios-RJ" as LocationId,
    diveCenter: "Búzios Divers" as DiveCenter,
    img: "/images/dives/buzios/buzios-noturno.jpeg",
    accompanyAllowed: false,
    productDescription: {
      pt: "Mergulho noturno com saída de praia para mergulhadores com certificação avançada, em Armação dos Búzios - RJ.",
      en: "TO-DO",
    },
    requiredCertification: "Advanced Open Water" as Certification,
    equipment: ["all"],
  },
];

export const beachBahiaList: Array<Dive> = [
  {
    id: "beachBahia-discovery-pool",
    title: {
      pt: "Discovery Scuba em Piscina",
      en: "TO-DO",
    },
    description: {
      pt: "Discovery Scuba em Piscina",
      en: "TO-DO",
    },
    price: 190,
    level: "basic" as Level,
    entering: "pool" as Entering,
    cylinders: 1,
    location: "Porto Seguro - BA",
    locationId: "portoSeguro-BA" as LocationId,
    diveCenter: "Beach Bahia" as DiveCenter,
    img: "/images/dives/beachBahia/beachBahia-poolBaptism.jpg",
    accompanyAllowed: false,
    productDescription: {
      pt: "Discovery Scuba em Piscina, Porto Seguro - BA.",
      en: "TO-DO",
    },
    requiredCertification: "none" as Certification,
    equipment: ["all"],
  },
  {
    id: "beachBahia-baptism",
    title: {
      pt: "Mergulho de Batismo",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho de Batismo",
      en: "TO-DO",
    },
    price: 290,
    level: "basic" as Level,
    entering: "boat" as Entering,
    cylinders: 1,
    location: "Porto Seguro - BA",
    locationId: "portoSeguro-BA" as LocationId,
    diveCenter: "Beach Bahia" as DiveCenter,
    img: "/images/dives/beachBahia/beachBahia-baptism.jpeg",
    accompanyAllowed: true,
    accompanyPrice: 180,
    accompanySnorkelPrice: 210,
    productDescription: {
      pt: "Batismo de Mergulho em Porto Seguro - BA.",
      en: "TO-DO",
    },
    requiredCertification: "none" as Certification,
    equipment: ["all"],
    additionalPaymentInfo: [
      {
        icon: <BsCloudRainHeavy size="30" className="ms-2 mt-1" />,
        title: "Em caso de mudança climática inesperada",
        description:
          "Caso haja mudança climática inesperada de tempo, sem previsão/aviso da Marinha do Brasil, e a navegação esteja em andamento, será cobrado o valor de passeio(equivalente ao valor de acompanhante, informado no momento da compra).",
      },
    ],
  },
  {
    id: "beachBahia-discovery",
    title: {
      pt: "Discovery Scuba (Piscina e Oceano)",
      en: "TO-DO",
    },
    description: {
      pt: "Discovery Scuba em dois dias com piscina e oceano",
      en: "TO-DO",
    },
    price: 400,
    level: "basic" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "Porto Seguro - BA",
    locationId: "portoSeguro-BA" as LocationId,
    diveCenter: "Beach Bahia" as DiveCenter,
    img: "/images/dives/beachBahia/beachBahia-discovery.jpg",
    accompanyAllowed: true,
    accompanyPrice: 180,
    accompanySnorkelPrice: 210,
    productDescription: {
      pt: "Discovery Scuba em dois dias, o primeiro na piscina e o segundo com saída embarcada para mergulho no oceano em Porto Seguro - BA.",
      en: "TO-DO",
    },
    requiredCertification: "none" as Certification,
    equipment: ["all"],
    additionalPaymentInfo: [
      {
        icon: <BsCloudRainHeavy size="30" className="ms-2 mt-1" />,
        title: "Em caso de mudança climática inesperada",
        description:
          "Caso haja mudança climática inesperada de tempo, sem previsão/aviso da Marinha do Brasil, e a navegação esteja em andamento, será cobrado o valor de passeio(equivalente ao valor de acompanhante, informado no momento da compra).",
      },
    ],
  },
  {
    id: "beachBahia-certified",
    title: {
      pt: "Mergulho para credenciados",
      en: "TO-DO",
    },
    description: {
      pt: "Mergulho para mergulhadores credenciados",
      en: "TO-DO",
    },
    price: 570,
    level: "basic" as Level,
    entering: "boat" as Entering,
    cylinders: 2,
    location: "Porto Seguro - BA",
    locationId: "portoSeguro-BA" as LocationId,
    diveCenter: "Beach Bahia" as DiveCenter,
    img: "/images/dives/beachBahia/beachBahia-certified.jpeg",
    accompanyAllowed: true,
    accompanyPrice: 180,
    accompanySnorkelPrice: 210,
    productDescription: {
      pt: "Mergulho embarcado para mergulhadores credenciados em Porto Seguro - BA.",
      en: "TO-DO",
    },
    requiredCertification: "Open Water" as Certification,
    equipment: ["all"],
    additionalPaymentInfo: [
      {
        icon: <BsCloudRainHeavy size="30" className="ms-2 mt-1" />,
        title: "Em caso de mudança climática inesperada",
        description:
          "Caso haja mudança climática inesperada de tempo, sem previsão/aviso da Marinha do Brasil, e a navegação esteja em andamento, será cobrado o valor de passeio(equivalente ao valor de acompanhante, informado no momento da compra).",
      },
    ],
  },
];

export const DIVELIST: Array<Dive> = concat(
  mergulhoMagicoList,
  ilhabelaList,
  buziosList,
  beachBahiaList
);

export const baptismDives = filter(
  DIVELIST,
  (d) => d.requiredCertification === "none"
);
