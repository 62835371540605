import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./authentication/Login";
import Register from "./authentication/Register";
import Dashboard from "./pages/Dashboard";
import Logger from "./pages/Logger";
import EmailVerification from "./authentication/EmailVerification";
import ResetPassword from "./authentication/ResetPassword";
import LogDetails from "./pages/LogDetails";
import ManageProfile from "./pages/ManageProfile";
import Profile from "./components/ManageProfile/Profile";
import EditProfile from "./components/ManageProfile/EditProfile";
import DiverProfile from "./components/ManageProfile/DiverProfile";
import ProfilePhoto from "./components/ManageProfile/ProfilePhoto";
import NotFound from "./pages/NotFound";
import TermosDeUso from "./policies/pt/TermosDeUso";
import PoliticaDePrivacidade from "./policies/pt/PoliticaDePrivacidade";
import BasesJuridicas from "./policies/pt/BasesJuridicas";
import DiverPage from "./pages/DiverPage";
import PoliticaDeCookies from "./policies/pt/PolíticaDeCookies";
import Padroes from "./policies/pt/PadroesDaComunidade";
import Batismo from "./articles/pt/Batismo";
import ValidateLog from "./pages/ValidateLog";
import MergulhoTubaroesBR from "./articles/pt/MergulhoTubaroesBR";
import VaporBahia from "./articles/pt/VaporBahia";
import NaufragiosPernambuco from "./articles/pt/NaufragiosPernambuco";
import Virgo from "./articles/pt/Virgo";
import ArticlesPT from "./articles/pt/ArticlesPT";
import Registration from "./pages/DiveCenters/Registration";
import DiveCenterSummary from "./pages/DiveCenters/DiveCenterSummary";
import Courses from "./pages/Courses/Courses";
import OpenWater from "./pages/Courses/OpenWater";
import CourseBooking from "./pages/Courses/CourseBooking";
import Location from "./pages/WhereWeAre/Location";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import WhereWeAre from "./pages/WhereWeAre/WhereWeAre";
import DivingIn from "./pages/Diving/DivingIn";
import DiveDetails from "./pages/Diving/DiveDetails";
import MpCheckout from "./pages/Checkout/MpCheckout";
import LandingPage from "./pages/LandingPage/LandingPage";
import CourseSearch from "./pages/Courses/CourseSearch";
import CoursesIn from "./pages/Courses/CoursesIn";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq/Faq";
import SalesContract from "./policies/pt/SalesContract";
import Baptism from "./pages/Baptism";
import Experience from "./pages/Experiences/Experience";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* #######  articles ######*/}
          {/* <Route path="/articles/pt" element={<ArticlesPT />} />
          <Route
            path="/articles/pt/mergulho-com-tubaroes-no-brasil"
            element={<MergulhoTubaroesBR />}
          />
          <Route
            path="/articles/pt/mergulho-de-batismo"
            element={<Batismo />}
          />
          <Route
            path="/articles/pt/mergulho-naufragio-vapor-bahia"
            element={<VaporBahia />}
          />
          <Route
            path="/articles/pt/mergulho-naufragios-pernambuco"
            element={<NaufragiosPernambuco />}
          />
          <Route path="/articles/pt/naufragio-virgo" element={<Virgo />} /> */}
          {/* END #######  articles ######*/}
          <Route path="/policies/pt/termos-de-uso" element={<TermosDeUso />} />
          <Route
            path="/policies/pt/politica-de-privacidade"
            element={<PoliticaDePrivacidade />}
          />
          <Route
            path="/policies/pt/bases-juridicas"
            element={<BasesJuridicas />}
          />
          <Route
            path="/policies/pt/politica-de-cookies"
            element={<PoliticaDeCookies />}
          />
          <Route
            path="/policies/pt/padroes-da-comunidade"
            element={<Padroes></Padroes>}
          />
          <Route
            path="/policies/pt/sales-contract"
            element={<SalesContract />}
          />

          <Route
            path="/email-verification"
            element={<EmailVerification></EmailVerification>}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword></ResetPassword>}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/diver/:id" element={<DiverPage />} />
          <Route path="/logger" element={<Logger />} />
          <Route path="/log/:id" element={<LogDetails />} />
          <Route path="/validate-log/:id" element={<ValidateLog />} />
          <Route path="/manage-profile" element={<ManageProfile />}>
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="diver-profile" element={<DiverProfile />} />
            <Route path="profile-photo" element={<ProfilePhoto />} />
          </Route>
          <Route path="dive-center/registration" element={<Registration />} />
          <Route path="dive-center/summary" element={<DiveCenterSummary />} />
          {/* <Route path="dive-the-plan/select-plan" element={<PlanSelection />} />
          <Route
            path="dive-the-plan/successfully-enrolled"
            element={<Enrolled />}
          /> */}
          <Route path="/experience/:id" element={<Experience />} />
          <Route path="courses" element={<Courses />} />
          <Route path="courses/search" element={<CourseSearch />} />
          <Route path="courses/open-water" element={<OpenWater />} />
          <Route path="course/:id" element={<CourseBooking />} />
          <Route path="/where-we-are" element={<WhereWeAre />} />
          <Route path="/where-we-are/:id" element={<Location />} />
          <Route path="/where-we-are/:id/courses" element={<CoursesIn />} />
          <Route path="/where-we-are/:id/dive" element={<DivingIn />} />
          <Route path="/dive/:id" element={<DiveDetails />} />
          <Route path="/dive/baptism" element={<Baptism />} />
          <Route path="/checkout/mp" element={<MpCheckout />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
