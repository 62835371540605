import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlineError } from "react-icons/md";
import { useTranslation } from "react-i18next";

function ErrorModal({
  shouldShow,
  redirectTo,
}: {
  shouldShow: boolean;
  redirectTo: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal
      centered
      show={shouldShow}
      onHide={() => {
        navigate(redirectTo);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <Row className="mt-3">
          <Col>
            <MdOutlineError size={100} className="text-danger"></MdOutlineError>
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>
            {t("error_processing")}. <br /> {t("please_try_again")}.
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ErrorModal;
