import React from "react";
import { Button, Card } from "react-bootstrap";
import { RxCalendar } from "react-icons/rx";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { Experience } from "../../interfaces/experience";
import { orderBy } from "lodash";

function ExperienceCard({
  experience,
  showLocation,
}: {
  experience: Experience;
  showLocation?: boolean;
}) {
  const lang = i18next.language === "pt" ? "pt" : "en";
  const navigate = useNavigate();

  return (
    <Card className="bg-light same-height-imgs h-100 shadow">
      <Link to={`/experience/${experience.id}`} className="no-style-link">
        <Card.Img
          className="border-0 card-img-top"
          variant="top"
          src={experience.img}
        />
      </Link>
      <Card.Title className="mb-auto px-2 mt-1">
        <Link to={`/experience/${experience.id}`} className="no-style-link">
          {showLocation ? (
            <div className="text-muted mb-1">{experience.location}</div>
          ) : (
            ""
          )}
          <strong className="fs-5">{experience.title[lang]}</strong>
        </Link>
      </Card.Title>

      <Card.Body className="d-flex flex-column">
        <div className="text-center mt-auto">
          <small className="text-muted">a partir de </small>
          <small className="text-muted">R$</small>
          <small className="h2 display-6">
            {orderBy(experience.tickets, ["price.value"])[0].price.value}
          </small>
          <small className="fs-6 text-muted ">,00 </small>
          <small className="text-muted ">em até 12x</small>
          <br />
          <small className="text-muted ">
            utilize mais de um cartão ou pague via PIX ou boleto
          </small>
        </div>
      </Card.Body>
      <Button
        variant="danger"
        className="rounded-0 mt-2 w-100"
        onClick={() => navigate(`/experience/${experience.id}`)}
      >
        <RxCalendar size="35" /> Verificar disponibilidade
      </Button>
    </Card>
  );
}

export default ExperienceCard;
