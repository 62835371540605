export const TRANSLATIONS_EN = {
  date_format_one: "{{-date, YYYY/MM/DD}}",
  date_format_two: "{{date, MM/DD/YYYY}}",
  signin: "Please sign in",
  email: "Email address",
  password: "Password",
  login_with_google: "Login with Google",
  no_account: "Don't have an account?",
  register: "Register",
  now: "now",
  click_here: "Click here",
  if_forgot_pass: "if you forgot your password.",
  title: "Title",
  dive_number: "Dive number",
  dive_type: "Dive type",
  dive: "Dive",
  night_dive: "Night Dive",
  baptism: "Baptism",
  checkout: "Checkout",
  drift: "Drift",
  deep_dive: "Deep Dive",
  wreck: "Wreck",
  cave_dive: "Cave Dive",
  technical_dive: "Technical Dive",
  boat_dive: "Boat Dive",
  ice_dive: "Ice Dive",
  free_dive: "Free Dive",
  altitude: "Altitude",
  snorkeling: "Snorkeling",
  shore_diving: "Shore Diving",
  choose: "Choose",
  created_with_success: "Created with success!",
  request_error: "Request error. Please, try again later.",
  date: "Date",
  dive_site: "Dive site",
  location: "Location",
  notes: "Notes",
  max_depth: "Maximum depth",
  max_depth_abbr: "Maximum depth",
  average_depth: "Average depth",
  average_depth_abbr: "Average depth",
  dive_time: "Dive time",
  deco_time: "Deco time",
  deco_time_abbr: "Deco time",
  water_condition: "Water condition",
  water_temperature: "Water temperature",
  water_temperature_abbr: "Water temperature",
  water_kind: "Water kind",
  calm: "Calm",
  rough: "Rough",
  seawater: "Seawater",
  fresh_water: "Fresh water",
  tide: "Tide",
  gas: "Gas",
  air: "Air",
  nitrox: "Nitrox",
  starting_gas: "Starting gas",
  final_gas: "Final gas",
  temperature: "Temperature",
  equipment_notes: "Equipment notes",
  suit: "Suit",
  weighting: "Weighting",
  create: "Create",
  image_upload: "Image upload",
  upload: "Upload",
  save: "Save",
  phone: "Phone",
  name: "Name",
  birthday: "Birthday",
  city: "City",
  state: "State",
  country: "Country",
  address: "Address",
  zipcode: "Zipcode",
  update: "Update",
  pic_a_photo: "Update profile picture",
  dives: "Dives",
  time: "Time",
  depth: "Depth",
  dives_number: "Number of Dives",
  diver_self_description_form:
    "Tell us a bit about your self and what diving means to you.",
  successfully_updated: "Successfully updated",
  dive_master: "Dive Master",
  open_water: "Open Water Scuba Diver",
  freediver: "Freediver",
  diver_to_be: "Diver To Be",
  specialty_diver: "Specialty Diver",
  advanced_open_water: "Advanced Open Water Scuba Diver",
  rescue_diver: "Rescue Diver",
  advanced_freediver: "Advanced Freediver",
  dive_guide: "Dive Guide",
  assistant_instructor: "Assistant Instructor",
  instructor: "Instructor",
  specialty_instructor: "Specialty Instructor",
  instructor_trainer: "Instructor Trainer",
  freediving_instructor: "Freediving Instructor",
  tech_diver: "Tech Diver",
  tech_instructor: "Tech Instructor",
  diving_agency_owner: "Diving Agency Manager",
  travel_agency_owner: "Travel Agency Manager",
  hotel_owner: "Resort Manager",
  lake: "Lake",
  select_at_least_one_tag: "Please select at least one tag.",
  edit: "Edit",
  fill_required_fields: "Please, fill all required fields.",
  select_all_dive_tags: "Select all tags that represent this dive:",
  full_name: "Full Name",
  invitation_code: "Invitation Code",
  create_account: "Create Account",
  already_registered: "Already registered",
  enter: "Login",
  invalid: "Invalid",
  verify_email: "Verify you email",
  sent_verification_link: "We sent a verification link to your email address.",
  to_finish_registration: "Click in the link to activate your account.",
  if_not_found: "If you don't find it on your primary inbox",
  check_spam: "don't forget to check the spam box",
  password_reset_sent: "Password reset link sent",
  reset_password: "Reset password",
  send_password_reset: "Send password reset email",
  password_reset_instructions: "Inform the email address registered on GDN:",
  will_send_reset_instructions:
    "We will send instructions to this address so you can create a new password.",
  registered_email: "Registered email address",
  forgot_password: "Forgot your password?",
  loading: "Loading",
  successfully_saved: "Saved",
  edit_profile: "Edit Profile",
  successfully_processed: "Success",
  error_processing: "Error",
  please_try_again: "Please, try again later",
  send: "Send",
  profile_picture: "User Picture",
  profile: "Profile",
  diver_profile: "Diver Profile",
  buoyancy: "Buoyancy",
  navigation: "Navigation",
  dry_suit: "Dry Suit",
  photo_video: "Photo & Video",
  shark_dive: "Shark Dive",
  sidemount: "Sidemount",
  nothing_else_to_load: "Looks like you've reached the end",
  likes: "Likes",
  delete_photo_check:
    "Are you sure that you want to delete this photo forever?",
  action_cant_be_undone: "This action can't be undone!",
  sure_and_continue: "Are you sure you want to continue?",
  delete_photo: "Delete photo",
  delete: "Delete",
  cancel: "Cancel",
  photo_deleted: "Photo deleted!",
  select_profile_picture: "Select your profile picture:",
  click_save_to_confirm: "Click 'Save' to confirm the operation",
  delete_account: "Delete account",
  click_to_delete_account: "Click the button bellow to delete your account",
  permanently: "permanently",
  attention: "Attention",
  all_data_will_be_removed: "All your data will be deleted",
  please_login_again:
    "Please, login again to confirm your identity and repeat the operation .",
  account_deleted: "Account deleted",
  everyone: "Everyone",
  followers: "Followers",
  only_me: "Only me",
  who_can_see: "Who can see this log?",
  spearfishing: "Spearfishing",
  comment: "Comment",
  comments: "Comments",
  chars_left: "chars left",
  delete_log: "Delete Log",
  delete_log_check:
    "Are you sure you want to delete this Log? This operation can't be undone. All it's information will be lost forever.",
  type_your_message: "Type your message",
  follow: "Follow",
  following: "Following",
  generic_error: "Request error! Please, try again.",
  community: "Community",
  community_standards: "Community standards",
  use_terms: "Terms of service",
  legal_bases: "Legal bases",
  cookie_policy: "Cookie policy",
  use_cookies: "This website uses cookies",
  saved: "Saved",
  validate_qr_code: "Use the QRcode above to ask people to validate the dive",
  not_informed: "Not informed",
  validate: "Validate",
  cant_validate_own_log: "You cannot validate your own dive.",
  successfully_validated: "Dive validated.",
  dive_already_validated: "You've already validated this dive.",
  validate_dive_from: "Validate dive from",
  or: "or",
  ask_for_validation: "ask for validation",
  via: "via",
  validate_dive_on: "Hey, buddy! Can you please validate our dive on ",
  at_dive_site: "at dive site ",
  here_is_the_link: "Here's the link: ",
  required_info: "Required information",
  share: "Share",
  check_out_dive:
    "Check out this amazing dive at Global Divers Network! Just follow the link: ",
  share_this_dive:
    "Share this dive using the QRcode above or choosing any of the options bellow:",
  copy: "Copy",
  link: "Link",
  copied: "Copied",
  signup: "Sign Up",
  thanks: "Thanks",
  diver: "Diver",
  validated: "Validated",
  dives_by: "Dives by ",
  add_dive_to_logbook: "Add to my logbook",
  recovery_password_description:
    "Recovery your password to access Global Divers Network",
  where_do_you_live: "Where do you live?",
  register_dive: "Create new log",
  select_dive_options: "Select the options that best describe the activity",
  basic: "Basic ",
  advanced: "Advanced",
  specialty: "Specialty",
  professional: "Profissional",
  dive_courses: "Dive courses",
  policies: "Policies",
  course: "Course",
  diving_articles: "Diving articles",
  signoff: "signoff",
  dive_in: "Dive in",
  where_we_are: "Where we are",
  courses: "Courses",
  certified: "Certified",
  in: "in",
  divers: "Divers",
  accompaniers: "Accompaniers",
  accompanier: "Accompanier",
  total: "Total",
};
