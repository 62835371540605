import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {
  setDoc,
  arrayUnion,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { db } from "../../config/firebase";
import { LeanUser, Log } from "../../interfaces/interfaces";
import { useNavigate } from "react-router-dom";
import { omitBy, isNil } from "lodash";

function ValidateLogBtn({ log }: { log: Log }) {
  const [validator, setValidator] = useState<LeanUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [switchState, setSwitchState] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    setValidator({
      id: currentUser.uid,
      name: currentUser?.displayName || "",
      photo: currentUser?.photoURL || "",
    });
  }, []);

  async function validate() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      navigate("/login");
      return;
    }
    const docRef = doc(db, "logbook", log.logId);
    const logRef = doc(collection(db, "logbook"));
    const userRef = doc(db, "users", user.uid);

    if (log.userId === validator?.id) {
      alert(t("cant_validate_own_log"));
      return;
    }

    try {
      setLoading(true);
      await updateDoc(docRef, {
        validatedBy: arrayUnion({
          id: validator?.id,
          name: validator?.name,
          photo: validator?.photo,
        }),
      });

      if (switchState === true) {
        const logProps = {
          userId: user.uid,
          userPicture: user.photoURL,
          userName: user.displayName,
          logId: logRef.id,
          title: log.title,
          date: log.date,
          diveSite: log.diveSite,
          location: log.location,
          temperature: log.temperature,
          waterTemperature: log.waterTemperature,
          noDecoTime: log.noDecoTime,
          diveTime: log.diveTime,
          averageDepth: log.averageDepth,
          maximumDepth: log.maximumDepth,
          visibility: log.visibility,
          logVisibility: "everyone",
        };

        const validProps = omitBy(logProps, isNil);
        await setDoc(logRef, validProps);
        await updateDoc(userRef, {
          dives: arrayUnion(logRef.id),
        });
      }

      alert(t("successfully_validated"));
      navigate(`/log/${log.logId}`);
    } catch (err) {
      alert(t("request_error"));
    } finally {
      setLoading(false);
    }
  }

  return log.userId === validator?.id ? (
    <></>
  ) : (
    <>
      <Form className="mb-2">
        <Form.Check
          inline
          label={<strong>{t("add_dive_to_logbook")}</strong>}
          name="check"
          type="switch"
          checked={switchState}
          onClick={() => setSwitchState(!switchState)}
        />
      </Form>
      <Button variant="success" disabled={loading} onClick={validate}>
        {t("validate")}
      </Button>
    </>
  );
}

export default ValidateLogBtn;
