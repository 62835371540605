import React, { useState } from "react";
import { Like } from "../../interfaces/interfaces";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row } from "react-bootstrap";
import LikesUsersList from "./LikesUsersList";

function LikeCounter({ likes }: { likes: Like[] }) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const likePictures: any = likes?.map(({ userPicture }, i) => {
    const margin: string = (i * 20).toString() + "px";
    if (i === 0)
      return (
        <img
          src={userPicture}
          alt=""
          className="circle-cropper position-relative end-0 ms-4"
          width="43px"
          height="43px"
          onClick={() => setShowModal(true)}
          key={i}
        />
      );
    if (i > 0 && i < 3)
      return (
        <img
          src={userPicture}
          alt=""
          className="circle-cropper position-absolute"
          style={{ right: margin }}
          width="43px"
          height="43px"
          onClick={() => setShowModal(true)}
          key={i}
        />
      );
    return "";
  });

  return (
    <div>
      <div className="text-end m-2 mt-3">
        <div className="position-relative end-0 pointer">
          {likePictures}
          <span onClick={() => setShowModal(true)} className="me-1 pointer">
            {likes?.length > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success mt-1">
                {likes?.length}
              </span>
            )}
          </span>
        </div>
      </div>

      <Modal
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Row>
            <Col xs={10} className="text-start">
              <strong>{t("likes")} </strong>
            </Col>{" "}
          </Row>
        </Modal.Header>
        <Modal.Body>
          <LikesUsersList likes={likes}></LikesUsersList>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LikeCounter;
