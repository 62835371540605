import React from "react";
import { Col, Row } from "react-bootstrap";
import { Dive } from "../../interfaces/dive";
import {
  GiWoodenPier,
  GiScubaTanks,
  GiThreeFriends,
  GiSwimfins,
} from "react-icons/gi";
import {
  TbCertificate,
  TbCertificateOff,
  TbSailboat,
  TbScubaMask,
  TbSpeedboat,
} from "react-icons/tb";
import { MdOutlineBeachAccess } from "react-icons/md";
import { includes } from "lodash";

function DiveIconProps({ dive }: { dive: Dive }) {
  return (
    <Row>
      <Col>
        {dive.entering === "boat" ? (
          <TbSailboat size="20" className="me-1" />
        ) : (
          ""
        )}
        {dive.entering === "shore" ? (
          <MdOutlineBeachAccess size="20" className="me-1" />
        ) : (
          ""
        )}
        {dive.entering === "pier" ? (
          <GiWoodenPier size="20" className="me-1" />
        ) : (
          ""
        )}
        {dive.entering === "dinghy" ? (
          <TbSpeedboat size="20" className="me-1" />
        ) : (
          ""
        )}

        {dive.accompanyAllowed ? (
          <TbScubaMask size="20" className="me-1" />
        ) : (
          ""
        )}
        {dive.accompanyAllowed ? (
          <GiThreeFriends size="20" className="me-1" />
        ) : (
          ""
        )}

        {includes(dive.equipment, "all") && <GiSwimfins className="me-1" />}

        {dive.requiredCertification === "none" ? (
          <TbCertificateOff size="20" className="me-1" />
        ) : (
          ""
        )}
        {dive.requiredCertification !== "none" ? (
          <TbCertificate size="20" className="me-1" />
        ) : (
          ""
        )}

        {dive.cylinders >= 1 ? <GiScubaTanks size="20" className="me-1" /> : ""}
      </Col>
    </Row>
  );
}

export default DiveIconProps;
