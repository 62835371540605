import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FiShare } from "react-icons/fi";
import { Log } from "../../interfaces/interfaces";
import ShareModal from "../Modal/ShareModal";

function ShareLogBtn({ resource }: { resource: Log }) {
  const [showShareModal, setShowShareModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="outline-dark"
        className="w-100 mb-2"
        onClick={() => setShowShareModal(true)}
      >
        <FiShare size="30" className="me-2" />
        {t("share") + " " + t("dive").toLowerCase()}
      </Button>

      <ShareModal
        shouldShow={showShareModal}
        resource={resource}
        setShouldShow={setShowShareModal}
      ></ShareModal>
    </>
  );
}

export default ShareLogBtn;
