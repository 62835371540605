import React, { useEffect } from "react";
import NavBar from "../components/NavBar/NavBar";
import { Card, Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import GDNy from "../components/GDNbrand/GDNy";
import MailtoBtn from "../components/Buttons/MailtoBtn";

function ContactUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col>
            <GDNy size="200" className="size-x"></GDNy>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3 mb-2 text-muted">
            <h2>
              Entre em contato conosco sempre que desejar através de nossos
              canais
            </h2>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3">
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Imprensa e Marketing</Card.Title>
                <Card.Text>
                  Para contato de imprensa e assuntos relacionados a marketing e
                  propaganda, utilize:
                  <div className="text-primary mt-5">
                    <MailtoBtn email="marketing@globaldiversnetwork.com" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Comercial e vendas</Card.Title>
                <Card.Text>
                  Entre em contato com nossa equipe de vendas para assuntos
                  relacionados a contratos, propostas comercias e negócios:
                  <div className="text-primary mt-4">
                    <MailtoBtn email="comercial@globaldiversnetwork.com" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>PIX</Card.Title>
                <Card.Text>
                  Faça depósitos e envie comprovantes de transações PIX.
                  <div className="text-primary mt-5">
                    <MailtoBtn email="pix@globaldiversnetwork.com" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Financeiro</Card.Title>
                <Card.Text>
                  Para tratar de pagamentos, reembolsos, alteração de
                  recebimento, extorno e saldo, entre em contato com nosso
                  financeiro:
                  <div className="text-primary mt-4">
                    <MailtoBtn email="financeiro@globaldiversnetwork.com" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Suporte técnico</Card.Title>
                <Card.Text>
                  Receba o suporte necessário para problemas técnicos, informe
                  bugs e problemas de usabilidade e requisite mudanças pelo
                  email:
                  <div className="text-primary mt-4">
                    <MailtoBtn email="suporte@globaldiversnetwork.com" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Contato</Card.Title>
                <Card.Text>
                  Entre em contato com a GDN através de nosso email de
                  comunicação geral:
                  <div className="text-primary mt-5">
                    <MailtoBtn email="contato@globaldiversnetwork.com" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default ContactUs;
