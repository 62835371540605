import React, { useEffect } from "react";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import "./landingPage.css";
import { Link, useNavigate } from "react-router-dom";
import CookieConsent from "../../components/CookieConsent/CookieConsent";
import GDNy from "../../components/GDNbrand/GDNy";
import { LOCATIONS } from "../../data/locations";
import DiveCard from "../../components/Cards/DiveCard";
import { baptismDives } from "../../data/dives";

function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavBar />
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block landing-carousel"
            src="images/landingPage/diver-smile.jpeg"
            alt="Diver smiling"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block landing-carousel"
            src="images/landingPage/diver-smile-2.png"
            alt="Diver simling in Buzios"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block landing-carousel"
            src="images/landingPage/noturno.jpeg"
            alt="Night smiling diver"
          />
        </Carousel.Item>
      </Carousel>

      <CookieConsent></CookieConsent>

      <Container className="mt-3">
        <div className="px-3 pt-4 text-justify text-center">
          <GDNy size="100" className="brand-header" />
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Somos uma grande rede de pessoas apaixonadas pelo mergulho.
            </p>
            <p className="lead mb-4">
              Trabalhamos somente com operadoras e profissionais qualificados,
              que conhecemos, respeitamos e confiamos para oferecer os melhores
              serviços, em pontos de mergulho que julgamos serem capazes de
              mudar a maneira de ver o oceano e a vida marinha, por meio de uma
              experiência única e inesquecível. Venha mergulhar conosco!
            </p>
          </div>
        </div>

        <hr className="my-5" />

        <Row>
          <Col>
            <h3>Pontos de mergulho em destaque</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={2} className="gx-4 gy-2">
          {LOCATIONS.map((p, i) => (
            <Col>
              <Card className="border-0 same-height-imgs">
                <Link to={`/where-we-are/${p.id}`} className="no-style-link">
                  <Card.Img
                    className="rounded-3 border-0 shadow"
                    variant="top"
                    src={p.img}
                  />
                </Link>
                <Card.Body className="px-1 pt-2 h-100">
                  <Card.Title>
                    <Link
                      to={`/where-we-are/${p.id}`}
                      className="no-style-link"
                    >
                      {p.fullName}
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <hr className="my-5" />

        <Row>
          <Col lg={6} className="text-center">
            <img
              src="/images/articles/baptism/batismo-de-mergulho-ok-600.jpeg"
              alt="Mergulho de batismo"
              className="shadow rounded-2 img-fluid"
            />
          </Col>
          <Col lg={6}>
            <div className="pt-5 text-center">
              <h3 className="display-6">Descubra um novo mundo</h3>
              <p className="lead my-3">Você nunca mergulhou com cilindro?</p>
              <p className="lead my-3">
                Vem com a gente descobrir um novo mundo de aventuras e
                experimente a mágica sensação de explorar as profundezas do
                oceano com nossos Mergulhos de Batismo e atividades de Discovery
                Scuba Diving!
              </p>
              <Button size="lg" onClick={() => navigate("/dive/baptism")}>
                Saiba mais: Batismo de mergulho
              </Button>
            </div>
          </Col>
        </Row>

        <hr className="my-5" />

        <Row>
          <Col>
            <h3>Mergulhos de batismo e discovery em destaque</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3 mb-5">
          {baptismDives?.map((d) => (
            <Col>
              <DiveCard dive={d} showLocation={true} />
            </Col>
          ))}
        </Row>

        <hr className="my-5" />

        <Row>
          <Col lg={6} className="text-center">
            <img
              src="/images/courses/open-water-scuba-diver-class.jpeg"
              alt="Open water scuba diver course pool class"
              className="shadow rounded-2 img-fluid"
            />
          </Col>
          <Col lg={6} className="text-center mb-3">
            <div className="pt-5">
              <h3 className="display-6">
                Torne-se um mergulhador certificado!
              </h3>
              <p className="lead my-3">
                Explore nossas opções de cursos básicos de mergulho em águas
                abertas e obtenha sua primeira certificação internacional.
              </p>
              <Button size="lg" onClick={() => navigate("/courses/open-water")}>
                Cursos: Open Water Scuba Diver
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default LandingPage;
