import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { LogVisibility } from "../interfaces/interfaces";

export async function getLog(id: string, setLoading: Function) {
  setLoading(true);
  try {
    const docRef = doc(db, "logbook", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (!data) return undefined;
    const log = {
      userId: data.userId,
      userPicture: data.userPicture,
      userName: data.userName,
      logId: data.logId,
      title: data.title,
      date: data.date,
      diveSite: data.diveSite,
      location: data.location,
      photo: data.photo,
      video: data.video,
      tags: data.tags,
      likes: data.likes,
      validatedBy: data.validatedBy,
      comments: data.comments,
      logVisibility: data.logVisibility,
      temperature: data.temperature,
      waterTemperature: data.waterTemperature,
      noDecoTime: data.noDecoTime,
      diveTime: data.diveTime,
      averageDepth: data.averageDepth,
      maximumDepth: data.maximumDepth,
      visibility: data.visibility,
      weighting: data.weighting,
      finalGas: data.finalGas,
      startingGas: data.startingGas,
      notes: data.notes,
      latLng: data.latLng,
    };
    return log;
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function setLogVisibility(
  logId: string | undefined,
  visibility: LogVisibility
): Promise<any> {
  if (!logId) return;

  try {
    const logDoc = doc(db, "logbook", logId);
    await updateDoc(logDoc, {
      logVisibility: visibility,
    });
    alert("Operação concluída com sucesso.");
  } catch (err) {
    alert("Error");
  } finally {
  }
}

export async function changeLogsVisibility(
  userId: string,
  visibility: LogVisibility
): Promise<any> {
  const q = query(collection(db, "logbook"), where("userId", "==", userId));
  const userLogs = await getDocs(q);
  userLogs.forEach((log) => {
    setLogVisibility(log.id, visibility);
  });
}

export async function getUserLogs(userId: string, visibility: LogVisibility) {
  try {
    const q = query(
      collection(db, "logbook"),
      where("logVisibility", "==", visibility),
      where("userId", "==", userId)
    );
    const logDocs = await getDocs(q);
    return logDocs.docs.map((log: any) => log.data());
  } catch (err) {
    alert("Error");
  } finally {
  }
}
