import React, { useEffect } from "react";
import { Row, Col, Container, Card, Badge } from "react-bootstrap";
import NavBar from "../../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

function Courses() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("dive_courses")} - GDN</title>
        <meta
          name="description"
          content="Cursos de mergulho válidos no mundo todo, oferecidos pela PADI, SDI, TDI, SSI e outras certificadoras internacionais no Brasil."
        />
      </Helmet>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            {t("course").toLowerCase()}s
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{t("dive_courses").toLowerCase()}</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card className="border-0 h-100">
              <Link to="/courses/open-water" className="no-style-link">
                <Card.Img
                  className="rounded-3 border-0"
                  height="280px"
                  variant="top"
                  src="/images/courses/open-water-course-arraial-do-cabo.jpeg"
                />
                <div className="card-img-overlay">
                  <span className="card-title">
                    <Badge>{t("basic")}</Badge>
                  </span>
                </div>
              </Link>
              <Card.Body className="px-1 pt-2 h-100">
                <Card.Title>
                  <Link to="/courses/open-water" className="no-style-link">
                    open water scuba diver
                  </Link>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}></Col>
          <Col lg={4}></Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Courses;
