import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import DeleteAccount from "../../components/ManageProfile/DeleteAccount";
import NavBar from "../../components/NavBar/NavBar";

function TermosDeUso() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="text-justify mt-4">
        <Helmet>
          <title>Termos de uso - GDN</title>
          <meta
            name="description"
            content="Documento descrevendo os termos de uso e serviço da rede social de mergulho Global Divers Network."
          />
        </Helmet>
        <Row>
          <Col>
            <h1>Termos de Uso de Serviço da Global Divers Network</h1>
            <small className="text-mutted">
              Data de vigência: 20 de Janeiro de 2023
            </small>
            <hr></hr>
            <p>
              Olá! Seja bem vindo a Global Divers Network! Este documento
              apresenta nossos termos de serviço para que fique claro os seus
              direitos e os nossos direitos em relação ao fornecimento de
              serviços. Por favor, leia atentamente.
            </p>
            <h3>Termos de serviço</h3>
            <p>
              Websites e aplicativos móveis da Global Divers Network
              (coletivamente, os "Serviços") são disponibilizados por você pela
              Global Divers Network, localizada na Rua Álamo, 148, Balneário
              Cassino, Rio Grande, Rio Grande do Sul, CEP: 96208-110, Brasil,
              sujeitos a estes termos uso e de serviço (os "Termos") e de acordo
              com a{" "}
              <Link to="/policies/pt/politica-de-privacidade">
                Política de Privacidade{" "}
              </Link>
              da Global Divers Network (a "Política de Privacidade"). Mais
              informações sobre os Serviços podem ser encontradas em{" "}
              <Link to="/">https://globaldiversnetwork.com</Link>. Você aceita
              cumprir estes Termos e quaisquer termos complementares que a
              Global Divers Network disponibilizar nos Serviços, que farão parte
              dos Termos. AO ACESSAR, UTILIZAR OU FAZER UPLOAD OU DOWNLOAD DE
              QUAISQUER INFORMAÇÕES OU MATERIAIS DE OU PARA OS SERVIÇOS, OU AO
              INDICAR QUE ACEITA ESTES TERMOS AO CRIAR UMA CONTA, CLICAR NO LINK
              DE INSCRIÇÃO OU QUALQUER OUTRO MECANISMO SEMELHANTE, VOCÊ ESTÁ
              CONCORDANDO COM ESTES TERMOS. CASO NÃO CONCORDE COM ESTES TERMOS,
              NÃO ACESSE OU UTILIZE OS SERVIÇOS. Este acordo foi escrito em
              português. Na medida em que uma versão traduzida dos Termos seja
              conflitante com a versão em português, a versão em português
              prevalecerá.
            </p>
            <p>
              Se acessar ou usar os Serviços em nome de uma empresa ou outra
              entidade, você declara ter a autoridade para obrigar tal entidade
              e suas afiliadas a cumprir estes Termos e que ela está totalmente
              obrigada a cumpri-los. Nesse caso, o termo “você” estará se
              referindo a tal entidade e suas afiliadas. Se não tiver tal
              autoridade, você não poderá acessar ou usar os Serviços. Estes
              Termos contêm isenções de garantias e limitações de
              responsabilidade que podem se aplicar a você.
            </p>
            <p>
              Os Serviços da Global Divers Network não podem ser fornecidos e o
              acordo descrito nos Termos de serviço não pode ser cumprido sem a
              Global Divers Network processar dados sobre você, e outros
              usuários da Global Divers Network, incluindo seus dados de
              localização. O processamento dos dados que você compartilha com a
              Global Divers Network, inclusive dados de localização, é essencial
              para os Serviços que fornecemos e uma parte necessária do nosso
              cumprimento do acordo que temos com você.
            </p>
            <p>
              <strong>
                Aviso sobre resolução de disputas: estes Termos contêm
                disposições que determinam como as disputas entre você e a
                Global Divers Network serão resolvidas, inclusive um acordo de
                arbitragem, que exigirá, a menos que você resida na União
                Europeia, com exceção limitada, que você apresente as
                reivindicações que tiver contra nós à arbitragem final e
                vinculativa e limitará suas reivindicações contra a Global
                Divers Network individualmente, a menos que você renuncie de
                acordo com as instruções abaixo.
              </strong>
            </p>
            <h3> Registro e Contas</h3>
            <p>
              Os Serviços são destinados unicamente a pessoas maiores de 13
              anos, ou a maioridade no seu país para usar os Serviços. Se você
              não tiver atingido a idade legal para celebrar um contrato
              vinculativo em sua jurisdição, você declara que seus pais ou seu
              responsável legal leram e concordaram com estes Termos em seu
              nome.
            </p>
            <p>
              Para usar os Serviços, você deve se cadastrar. Você concorda em:
              (a) fornecer informações verdadeiras, precisas, atuais e completas
              sobre você (“Dados do Usuário”) e (b) manter e prontamente
              atualizar os Dados do Usuário. Você concorda que a Global Divers
              Network pode usar seus Dados do Usuário para fornecer os Serviços
              que você acessa ou usa e de outras formas definidas nestes Termos.
              Se você fornecer quaisquer Dados do Usuário que sejam imprecisos
              ou não sejam atuais, ou se a Global Divers Network tivere motivos
              razoáveis para suspeitar que tais Dados do Usuário sejam
              imprecisos ou não atuais, a Global Divers Network terá o direito
              de suspender ou encerrar a sua conta e recusar a utilização atual
              ou futura dos Serviços. Em relação à sua utilização dos Serviços,
              você declara não ser uma pessoa impedida de receber serviços
              dentro das leis de qualquer jurisdição aplicável.
            </p>
            <p>
              Você é o único responsável por manter a confidencialidade da senha
              associada à sua conta e restringir o acesso à sua senha, seu
              computador e dispositivo móvel enquanto estiver conectado(a) aos
              Serviços. Você aceita a responsabilidade por todas as atividades
              ocorridas em sua conta ou de seu computador ou dispositivo móvel.
              Nós nos empenhamos em empregar medidas razoáveis de segurança para
              prevenir o acesso não autorizado à sua conta. Não podemos, no
              entanto, garantir a segurança absoluta da sua conta, do seu
              Conteúdo (conforme definido abaixo) ou das informações pessoais
              que você fornece, e não podemos prometer que nossas medidas de
              segurança irão evitar que “hackers” de terceiros acessem
              ilegalmente os Serviços e seus conteúdos. Você concorda em
              notificar imediatamente a Global Divers Network de qualquer uso
              não autorizado da sua conta ou senha, ou qualquer outra infração
              de segurança, e confirma que entende todos os riscos de acesso não
              autorizado aos Dados do Usuário e quaisquer outras informações ou
              conteúdos que você fornecer à Global Divers Network.
            </p>
            <p>
              Você pode se cadastrar ou fazer login em sua conta por meio de uma
              rede de terceiros, como o Facebook ou Google. Nesse caso, você
              autoriza a Global Divers Network a preencher os campos de
              inscrição e outros campos relevantes da sua conta e/ou usar tais
              credenciais de terceiros para fazer login na sua conta. Se você
              conectar sua conta a uma rede de terceiros, concorda em seguir os
              termos e condições e as políticas aplicáveis aos terceiros.
            </p>
            <p>
              Em virtude da possível conexão de alguns dos Serviços ao API do
              Google Maps, atualmente ou em futuras funcionalidades, você
              concorda em cumprir os{" "}
              <a
                href="https://www.google.com/intl/pt-BR/help/terms_maps/"
                target="_blank"
                rel="noreferrer"
              >
                Termos de Serviço adicionais do Google Maps/Google Earth
              </a>{" "}
              (inclusive a{" "}
              <a
                href="https://policies.google.com/privacy?hl=pt-BR"
                target="_blank"
                rel="noreferrer"
              >
                Política de privacidade do Google{" "}
              </a>
              ) com relação ao seu uso de tais Serviços.
            </p>
            <h3>Cancelamento</h3>
            <h4>Cancelamento e exclusão da da sua conta de usuário</h4>
            <p>
              Exclusão da conta: Se você se inscreveu na
              globaldiversnetwork.com, pode excluir sua conta a qualquer momento
              clicando no link a seguir: <DeleteAccount></DeleteAccount>. Ao
              excluir sua conta, todas as informações armazenadas sobre você
              serão imediatamente excluídas, incluindo sem email, nome, cidade,
              país, bem como todas as informações referentes aos logs criados
              por você. Esta operação é permanente e não pode ser desfeitas.
              Caso você confirme a operação de exclusão de conta no sistema,
              todos os seus dados serão perdidos e não poderão ser recuperados.
            </p>

            <h3>Conteúdo e Conduta</h3>
            <h4>Conteúdo</h4>
            <p>
              Você é o(a) proprietário(a) das informações, dados, texto,
              software, sons, fotografias, gráficos, vídeo, mensagens,
              publicações, marcações ou outros materiais que você disponibilizar
              relacionados aos Serviços (“Conteúdo”), sejam eles postados
              publicamente, transmitidos de forma privada ou enviados por meio
              de uma API de terceiros (por exemplo, uma fotografia enviada pelo
              Instagram). Você nos concede uma licença não exclusiva,
              transferível, sublicenciável, livre de royalties e mundial para
              usar, reproduzir, modificar, adaptar, publicar, traduzir, criar
              trabalhos derivados, distribuir, apresentar publicamente e exibir
              qualquer Conteúdo e qualquer nome, nome de usuário ou imagem que
              você publicar nos Serviços ou em conexão com eles em todos os
              formatos de mídia e canais conhecidos atualmente ou desenvolvidos
              posteriormente sem compensação para você. A licença termina quando
              você excluir seu Conteúdo ou conta.
            </p>
            <p>
              ​​Você compreende que você, e não a Global Divers Network, é
              totalmente responsável por todo o Conteúdo carregado, publicado,
              enviado por e-mail, transmitido ou disponibilizado de outra forma
              por meio dos Serviços. A Global Divers Network não controla,
              examina ou monitora regularmente o Conteúdo publicado nos Serviços
              por outras pessoas e, por isso, não garante a precisão,
              integridade ou qualidade desse Conteúdo. A Global Divers Network
              pode, a seu exclusivo critério, examinar, monitorar, ocultar,
              recusar ou remover qualquer Conteúdo que viole os Termos ou que
              seja questionável. Você compreende que, ao utilizar os Serviços,
              poderá ser exposto(a) a Conteúdo ofensivo, indecente ou
              questionável. Em circunstância alguma, a Global Divers Network
              será responsável por qualquer Conteúdo, inclusive, sem limitação,
              quaisquer erros ou omissões em Conteúdo, ou perdas ou danos de
              qualquer espécie incorridos como resultado do uso do Conteúdo
              disponibilizado nos Serviços. Você concorda em assumir todos os
              riscos associados ao uso do Conteúdo disponível com relação aos
              Serviços, inclusive os de confiar na precisão, integridade ou
              utilidade do Conteúdo.
            </p>
            <p>
              Você concorda que a Global Divers Network não é responsável pelos
              Conteúdos publicados nos Serviços nem os endossa. Se o seu
              Conteúdo violar estes Termos, você poderá arcar com
              responsabilidade legal por esse Conteúdo. No que diz respeito a
              você, à Global Divers Network, qualquer Conteúdo será não
              confidencial e não proprietário e não seremos responsáveis por
              qualquer uso ou divulgação do Conteúdo. Você reconhece e concorda
              que o seu relacionamento com a Global Divers Network não é
              confidencial, fiduciário ou qualquer outro tipo de relacionamento
              especial, e que a sua decisão de enviar qualquer Conteúdo não
              coloca a Global Divers Network em uma posição diferente daquela
              ocupada por membros do público em geral, inclusive com relação ao
              seu Conteúdo. O seu Conteúdo não estará sujeito a qualquer
              obrigação de confidencialidade da parte da Global Divers Network,
              a não ser a determinada em nossa{" "}
              <Link to="/policies/pt/politica-de-privacidade">
                Política de privacidade{" "}
              </Link>
              , e a Global Divers Network não será responsável por qualquer uso
              ou divulgação do Conteúdo fornecido por você.
            </p>
            <p>
              Os Serviços podem oferecer a você a capacidade de marcar certos
              Conteúdos que você envia aos Serviços como privados, públicos ou
              disponíveis somente a determinados usuários dos Serviços. A Global
              Divers Network manterá a privacidade de tais Conteúdos de acordo
              com as suas seleções. Entretanto, se você não optar por marcar o
              Conteúdo como privado ou disponível para um grupo limitado de
              usuários, ou posteriormente alterar tal designação para permitir
              que tal Conteúdo esteja disponível para qualquer pessoa, você será
              responsável pela natureza pública do conteúdo. Leia nossa
              <Link to="/policies/pt/politica-de-privacidade">
                {" "}
                Política de privacidade
              </Link>{" "}
              para obter mais informações sobre como gerenciar seus controles de
              privacidade.
            </p>
            <p>
              Você nos dá permissão para usar seu nome de perfil, foto de
              perfil, informações sobre as sua atividades nos nossos serviços,
              postagens, conteúdo e as ações que realizou, inclusive seu uso de
              produtos, serviços ou dispositivos de terceiros, em anúncios,
              ofertas e outros contextos comerciais no serviço da Global Divers
              Network sem compensação para você. Por exemplo, podemos mostrar
              aos seguidores da Global Divers Network que você usa um
              determinado dispositivo, produto, equipamento ou serviço
              disponível de uma marca que nos pagou para veicular seus anúncios
              na Global Divers Network. Você pode a qualque momento excluir sua
              conta para impedir que o seu nome de perfil e foto de perfil
              apareçam em um anúncio. Para excluir sua conta, clique no link a
              seguir: <DeleteAccount></DeleteAccount>.
            </p>
            <h4>Conduta</h4>
            <p>
              Esperamos que você siga as{" "}
              <Link to="/policies/pt/padroes-da-comunidade">
                {" "}
                Normas da Comunidade{" "}
              </Link>{" "}
              Global Divers Network. Os Serviços destinam-se a seu uso pessoal e
              não comercial. Você não pode modificar, copiar, distribuir,
              transmitir, exibir, executar, reproduzir, publicar, licenciar,
              criar trabalhos derivados, transferir ou vender para nenhum fim
              comercial qualquer parte dos Serviços, uso dos Serviços ou acesso
              ao Conteúdo. Você não pode usar os Serviços ou auxiliar ou
              incentivar outra parte a envolver-se em qualquer uma das seguintes
              Atividades proibidas abaixo:
            </p>
            <ul>
              <li>
                Cópia, recorte ou espelhamento de qualquer parte dos Serviços;
              </li>
              <li>
                Acesso aos Serviços para monitorar sua disponibilidade,
                desempenho ou funcionalidade;
              </li>
              <li>Permissão do acesso de terceiros aos Serviços;</li>
              <li>
                Uso, cópia, modificação, criação de trabalho derivado,
                engenharia reversa, descompilação ou tentativa de extrair de
                outra forma o código fonte do software de base dos Serviços ou
                qualquer parte dele, a menos que expressamente permitido ou
                exigido por lei e, de qualquer forma, sem notificar a Global
                Divers Network previamente por escrito;
              </li>
              <li>
                Publicação, transmissão, distribuição ou armazenamento de
                conteúdo, material, informações ou dados que: (a) sejam ilegais,
                obscenos, difamatórios, ameaçadores, perturbadores, abusivos ou
                de incitação ao ódio, ou que preguem a violência; (b) sejam
                prejudiciais ou interfiram nos Serviços ou nas redes,
                equipamentos, aplicativos, serviços ou websites de terceiros
                (por exemplo: vírus, worms, cavalos de Tróia, etc.); (c)
                infrinjam, reduzam, apropriem-se indevidamente ou de outra forma
                violem quaisquer direitos de privacidade, propriedade
                intelectual, publicidade ou outros direitos pessoais, incluindo,
                sem limitação, direitos autorais, patentes, marcas comerciais,
                segredos comerciais ou outras informações proprietárias
                (inclusive o uso não autorizado de nomes de domínio); ou (d)
                sejam fraudulentos ou contenham declarações, alegações ou
                afirmações falsas, enganosas ou errôneas (tais como “phishing”);
              </li>
              <li>
                Tentativa de interromper, prejudicar, afetar ou violar a
                integridade ou a segurança dos Serviços ou computadores,
                serviços, Contas ou redes de qualquer outra parte (inclusive,
                sem limitação, “hacking”, ataques de “negação de serviço”,
                etc.), e qualquer atividade que geralmente antecede tentativas
                de violar a segurança, como varredura, sondagem ou outra
                atividade de avaliação e teste de vulnerabilidade, ou
                envolvimento ou permissão de atividades de rede ou hospedagem
                que resulte na inclusão em lista preta ou em outros bloqueios do
                espaço de protocolo de Internet da Global Divers Network;
              </li>
              <li>
                Não cumprimento do pagamento de despesas ou taxas devidas por
                você referentes aos Serviços;
              </li>

              <li>Prática de atos que possam prejudicar menores de idade;</li>
              <li>
                Distribuição ou divulgação de qualquer parte dos Serviços em
                qualquer meio, inclusive, sem limitação, por “scraping”
                automatizado ou não automatizado;
              </li>
              <li>
                Utilização de qualquer sistema automatizado, inclusive, sem
                limitação, “robôs”, “aranhas”, “leitores offline”, etc., para
                acessar os Serviços de uma forma que envie mais mensagens de
                solicitação à Global Divers Network do que um ser humano pode
                razoavelmente produzir no mesmo período de tempo usando um
                navegador da web convencional;
              </li>
              <li>
                Realização de ação que imponha ou possa impor, a nosso exclusivo
                critério, uma carga despropositada ou desproporcionalmente
                grande em nossa infraestrutura;
              </li>
              <li>
                Coletar ou colher quaisquer informações relacionadas a um
                indivíduo identificado ou identificável, inclusive nomes de
                conta e informações sobre usuários dos Serviços, a partir dos
                Serviços;
              </li>
              <li>
                Utilização dos Serviços para fins de solicitação comercial
                inapropriadas;
              </li>
              <li>
                Acesso a conteúdo nos Serviços através de qualquer tecnologia ou
                outros meios não fornecidos ou autorizados pelos Serviços;
              </li>
              <li>
                Envio aos Serviços ou à Global Divers Network de qualquer
                informação de identificação pessoal, exceto conforme necessário
                para o estabelecimento e a operação da sua conta;
              </li>
              <li>
                Envio aos Serviços ou à Global Divers Network de informações que
                possam ser protegidas contra divulgação pela legislação
                aplicável;
              </li>
              <li>
                Desconsideração das medidas que podemos usar para prevenir ou
                restringir o acesso aos Serviços, incluindo, sem limitação,
                recursos que previnem ou restringem o uso ou a cópia de qualquer
                conteúdo ou que impõem limitações ao uso dos Serviços ou de seu
                conteúdo;
              </li>
              <li>
                Violação de qualquer lei, estatuto, decreto ou regulamentação,
                ou promoção de qualquer conduta que constitua crime ou dê origem
                a uma responsabilidade civil;
              </li>
              <li>
                Remoção de avisos de direitos autorais, marca comercial ou
                outros direitos de propriedade contidos nos Serviços; ou
              </li>
              <li>
                Aplicação de qualquer forma de monitoramento de rede ou execução
                de um analisador de rede ou sniffer de pacote ou outra
                tecnologia para interceptar, decodificar, extrair ou exibir
                pacotes usados na comunicação entre os servidores dos Serviços
                ou quaisquer dados não destinados a você.
              </li>
            </ul>
            <p>
              Você recebe um direito limitado e não exclusivo de criar um
              hiperlink de texto aos Serviços somente para uso não comercial,
              contanto que tal link não represente a Global Divers Network ou
              qualquer um de seus produtos ou serviços de maneira falsa,
              enganosa, depreciativa ou, de alguma forma, difamatória, e
              contanto que o site vinculado não contenha material pornográfico,
              ilegal, ofensivo, perturbador ou questionável. A você também é
              concedido o direito de implementar quaisquer feeds RSS localizados
              nos Serviços para uso pessoal e não comercial, unicamente da forma
              descrita nos Serviços. Nós nos reservamos o direito de revogar
              essas licenças em geral, ou o seu direito de usar links ou feeds
              RSS específicos, a qualquer momento, com ou sem causa.
            </p>
            <p>
              Você compreende que o uso de determinados recursos dos Serviços
              poderá exigir que você adquira equipamentos ou materiais de
              terceiros (por exemplo, sistemas GPS). Embora a Global Divers
              Network possa recomendar equipamentos ou materiais de determinados
              fornecedores, a Global Divers Network não se responsabiliza por
              sua aquisição ou uso de equipamentos ou materiais de terceiros e
              não garante que equipamentos ou materiais de terceiros funcionarão
              com os Serviços ou não conterão erros.
            </p>
            <p>
              Você compreende que é responsável por quaisquer cobranças
              associadas ao envio de comunicações pelo seu dispositivo. Por este
              instrumento, você reconhece que tem o direito de se comunicar com
              seus contatos por meio dos Serviços.
            </p>
            <p>
              Você declara e garante que: (i) está autorizado(a) a criar a sua
              conta, seja individualmente ou em nome de uma organização; (ii)
              detém o Conteúdo publicado por você nos Serviços ou por meio
              deles, ou de outra forma tem o direito de conceder os direitos e
              licenças estabelecidos nestes Termos; (iii) a publicação e o uso
              do seu Conteúdo nos Serviços ou por meio deles não viola,
              apropria-se indevidamente ou infringe, agora ou futuramente, os
              direitos de terceiros, inclusive, sem limitação, direitos de
              privacidade e proteção de dados, direitos de publicidade, direitos
              autorais, marcas comerciais e/ou outros direitos de propriedade
              intelectual; e (iv) você concorda em pagar todos os royalties,
              taxas e outras quantias devidas por conta do Conteúdo que você
              publica nos Serviços ou por meio deles.
            </p>
            <h4>Interação com Usuários</h4>
            <p>
              Os Serviços funcionam como um ponto para conectar os Usuários em
              um ambiente virtual de informações. Como um facilitador neutro, a
              Global Divers Network não está diretamente envolvidas nas
              interações reais entre os Usuários que usam os Serviços.
              Consequentemente, a Global Divers Network não têm controle sobre a
              veracidade, precisão, qualidade, legalidade ou segurança das
              publicações feitas por usuários que usam os Serviços. A Global
              Divers Network não terá a responsabilidade de confirmar a
              identidade dos usuários. A Global Divers Network também não terá a
              responsabilidade de confirmar ou verificar as qualificações, o
              histórico ou as habilidades dos Usuários que usam os Serviços.
              Você deverá sempre usar o bom senso e agir com discernimento ao
              lidar com qualquer Usuário que use os Serviços.
            </p>
            <p>
              Se você optar por usar nossos recursos para informar seus contatos
              sobre os Serviços, acompanhar suas atividades pela Global Divers
              Network ou compartilhar suas informações com outras pessoas, a
              Global Divers Network poderá solicitar que você forneça
              informações de contato. A Global Divers Network poderá entrar em
              contato com seu amigo por e-mail ou mensagem de texto enviados uma
              única vez. Você garante que está autorizado a fornecer quaisquer
              informações de contato de terceiros que enviar à Global Divers
              Network, que está autorizado a usar tais informações para entrar
              em contato (inclusive para a Global Divers Network entrarem em
              contato em seu nome) com os terceiros e que a Global Divers
              Network pode processá-las de acordo com a{" "}
              <Link to="/policies/pt/politica-de-privacidade">
                {" "}
                Política de privacidade
              </Link>
              .
            </p>
            <h4>Terceiros</h4>
            <p>
              Os produtos e serviços de terceiros disponibilizados nos Serviços
              são desenvolvidos e oferecidos diretamente pelos respectivos
              terceiros. Quando você busca ou adquire tais produtos ou serviços,
              reconhece que está contratando diretamente os terceiros e não a
              Global Divers Network. Sua participação ou interação com promoções
              de terceiros encontradas nos Serviços ou por meio deles, inclusive
              o pagamento e fornecimento de bens ou serviços, e quaisquer outros
              termos, ocorrem unicamente entre você e os terceiros. Você não é
              obrigado a usar ou negociar com terceiros apresentados nos
              Serviços. VOCÊ CONCORDA QUE A GLOBAL Divers NETWORK NÃO SERÁ
              RESPONSABILIZADA POR PERDAS OU DANOS DE QUALQUER TIPO INCORRIDOS
              EM SUAS NEGOCIAÇÕES COM PROVEDORES DE SERVIÇOS TERCEIRIZADOS OU
              ANUNCIANTES DISPONÍVEIS POR MEIO DOS SERVIÇOS.
            </p>
            <p>
              A Global Divers Network ou terceiros poderão fornecer links a
              outros sites da Internet ou recursos por meio dos Serviços. A
              Global Divers Network não endossa e não se responsabiliza por
              qualquer conteúdo, informação, publicidade, produto ou outros
              materiais em tais sites ou recursos ou neles disponíveis. Você
              reconhece e concorda que a Global Divers Network não é responsável
              pela disponibilidade de tais sites ou recursos externos.
            </p>
            <h3>Comunicações Eletrônicas</h3>
            <p>
              Os Serviços poderão oferecer a você a capacidade de enviar ou
              publicar mensagens em fóruns ou salas de bate-papo, falar por meio
              de conexões de voz pela Internet ou enviar mensagens e
              comunicações semelhantes a provedores de serviços terceirizados,
              anunciantes, seus contatos pessoais, outros usuários e/ou a Global
              Divers Network. Você concorda em usar os métodos de comunicação
              disponíveis nos Serviços somente para enviar comunicações e
              materiais relacionados ao assunto para o qual a Global Divers
              Network forneceu o método de comunicação, e concorda que todas
              essas comunicações da sua parte serão consideradas seu Conteúdo e
              deverão se sujeitar e ser regidas pelos Termos e pela lei
              aplicável (incluindo as leis que regulam comunicações de marketing
              direto que você deve cumprir, conforme aplicável). Ao usar os
              métodos de comunicações disponíveis nos Serviços, você concorda
              que (a) todos os métodos de comunicações constituem um meio de
              comunicação público, e não privado, entre você e a outra parte ou
              partes, (b) as comunicações enviadas ou recebidas de provedores de
              serviços terceirizados, anunciantes ou outros terceiros não são
              endossadas, patrocinadas ou aprovadas pela Global Divers Network
              (a menos que declarado expressamente de outra forma pela Global
              Divers Network) e (c) as comunicações não são rotineiramente
              revisadas antes ou depois, selecionadas, arquivadas ou monitoradas
              pela Global Divers Network de maneira alguma, embora a Global
              Divers Network reserve-se o direito de fazê-lo a qualquer momento,
              a seu exclusivo critério. Você concorda que todas as notificações,
              divulgações e outras comunicações que fornecemos a você
              eletronicamente satisfarão qualquer requisito legal de que tal
              comunicação tenha sido feita por escrito.
            </p>
            <h4>Direitos Propriedade</h4>
            <p>
              Você reconhece e concorda que os Serviços, qualquer software
              necessário usado com os Serviços (se houver), quaisquer dados
              agregados baseados no Conteúdo dos Serviços e qualquer Conteúdo
              disponível ou disponibilizado nos Serviços contém informações
              confidenciais protegidas pelas leis de propriedade intelectual e
              outras leis aplicáveis. Exceto conforme expressamente permitido
              pela legislação aplicável ou autorizado pela Global Divers Network
              ou por provedores de serviços terceirizados ou anunciantes
              cabíveis, você concorda em não modificar, alugar, arrendar,
              emprestar, vender, distribuir ou criar trabalhos derivados com
              base nos Serviços, no software ou no Conteúdo disponível nos
              Serviços (com exceção do Conteúdo que você possa enviar), seja no
              todo ou em parte.
            </p>
            <p>
              A Global Divers Network concede a você, de forma pessoal,
              revogável, intransferível, não sublicenciável e não exclusiva, o
              direito e a licença para acessar e usar os Serviços, contanto que
              você não (e não permita que nenhum terceiro) copie, modifique,
              crie trabalho derivado, faça engenharia reversa, faça montagem
              reversa ou tente, de outra forma, descobrir qualquer código fonte,
              venda, atribua, sublicencie, conceda direito real de garantia ou
              de outra forma transfira qualquer direito nos Serviços, exceto
              onde permitido por lei. Você concorda em não acessar os Serviços
              de nenhuma outra forma a não ser pelas interfaces fornecidas pela
              Global Divers Network.
            </p>
            <p>
              O Global Divers Network, o logotipo Global Divers Network e outros
              logotipos e nomes de produtos e serviços da Global Divers Network
              são marcas comerciais exclusivas ou são de propriedade da Global
              Divers Network, e você não pode usar nem exibir tais marcas
              comerciais de forma alguma sem o consentimento prévio por escrito
              da Global Divers Network. Quaisquer marcas comerciais ou de
              serviço de terceiros exibidas nos Serviços pertencem aos seus
              respectivos proprietários.
            </p>
            <p>
              A Global Divers Network reservam-se todos os direitos não
              concedidos expressamente neste documento.
            </p>
            <h3>Reivindicações de Infração</h3>
            <p>
              A Lei dos Direitos Autorais do Milênio Digital (“DMCA”) oferece
              recurso para detentores de direitos autorais que acreditarem que o
              material exibido na Internet infringe seus direitos de acordo com
              a lei de direitos autorais dos Estados Unidos. A Global Divers
              Network também analisam alegações de infração de marca comercial.
              Se você acredita de boa fé que os materiais hospedados pela Global
              Divers Network infringem seus direitos de marca comercial ou
              direitos autorais, você (ou seu agente) pode nos enviar uma
              notificação solicitando que o material seja removido ou o acesso a
              ele seja bloqueado. A notificação deverá incluir as seguintes
              informações: (a) uma assinatura física ou eletrônica da pessoa
              autorizada a agir em nome do detentor de um direito exclusivo
              supostamente infringido; (b) identificação do trabalho com
              direitos autorais ou marca comercial supostamente infringido (ou,
              se vários trabalhos com direitos autorais ou marcas comerciais
              localizados nos Serviços estiverem incluídos em uma única
              notificação, uma lista representativa de tais trabalhos); (c)
              identificação do material que supostamente infringe ou é o objeto
              da atividade infratora, e informações suficientes para
              possibilitar que a Global Divers Network localize o material nos
              Serviços; (d) o nome, endereço, número de telefone e endereço de
              e-mail (se disponíveis) da parte requerente; (e) uma declaração de
              que a parte requerente acredita de boa fé que o uso do material da
              maneira alegada não é autorizado pelo detentor dos direitos
              autorais ou marca comercial, seu agente ou a lei; e (f) uma
              declaração de que as informações na notificação são precisas e,
              sob pena de perjúrio, que a parte requerente está autorizada a
              agir em nome do detentor de um direito exclusivo supostamente
              infringido. Esteja ciente de que a Global Divers Network não
              responderá a queixas que não atendam a esses requisitos. Caso a
              Global Divers Network determine que os materiais que você alegar
              que estão infringindo seus direitos autorais ou marca comercial
              não precisam ser removidos, a Global Divers Network somente
              removerá estes materiais por ordem judicial que declare que o
              conteúdo ou o uso dos materiais é ilegal.
            </p>
            <p>
              Se você acredita de boa fé que uma notificação de violação de
              direitos autorais foi indevidamente apresentada contra você, a
              DMCA permite que você nos envie uma contranotificação. As contra
              notificações devem incluir as seguintes informações: (a) seu nome,
              endereço e número de telefone; (b) a fonte do conteúdo que foi
              removido; (c) uma declaração, sob pena de perjúrio, de que você
              acredita de boa fé que o conteúdo foi indevidamente removido; (d)
              uma declaração de que você consente com a jurisdição do tribunal
              do distrito federal da comarca na qual seu endereço está
              localizado; ou, se seu endereço for fora dos Estados Unidos, de
              qualquer comarca em que a Global Divers Network se encontre, e que
              você aceitará a citação da pessoa que apresentou a queixa
              original; e (e) uma assinatura física ou eletrônica (por exemplo,
              a digitação do seu nome completo).
            </p>
            <p>
              Notificações com alegação de violação devem ser enviadas para:
            </p>
            <address>
              <strong>Global Divers Network</strong>
              <br />
              Rua Álamo, 148 - Balneário Cassino
              <br />
              Rio Grande - RS, Brasil
              <br />
              <strong>Email</strong>
              <br />
              <a href="mailto:#">contato@globaldiversnetwork.com</a>
            </address>
            <h3>Seus Comentários</h3>
            <p>
              Agradecemos seus comentários, feedback, sugestões e outras
              comunicações sobre os Serviços e as informações e serviços que
              disponibilizamos por meio dos Serviços (coletivamente,
              “Comentários”). Se enviar Comentários, você concede à Global
              Divers Network, por este instrumento, uma licença mundial, não
              exclusiva, transferível, atribuível, sublicenciável, perpétua,
              irrevogável, livre de royalties para copiar, distribuir, criar
              trabalhos derivados, exibir e reproduzir publicamente e de outra
              forma explorar tais Comentários e usar, fazer, mandar fazer,
              vender, pôr à venda, importar e exportar produtos e serviços
              baseados nestes Comentários. Por essa razão, pedimos que você não
              envie à Global Divers Network nenhum Comentário que não queira
              licenciar para nós conforme estabelecido acima.
            </p>
            <h3>Isenção de responsabilidade e garantias</h3>
            <p>
              AS INFORMAÇÕES, PROGRAMAS DE SOFTWARE, PRODUTOS, SERVIÇOS E
              CONTEÚDOS DISPONÍVEIS POR MEIO DOS SERVIÇOS SÃO FORNECIDOS A VOCÊ
              “NO ESTADO EM QUE SE ENCONTRAM” E SEM GARANTIA. A GLOBAL DIVERS
              NETWORK E SUAS SUBSIDIÁRIAS, AFILIADAS, EXECUTIVOS, FUNCIONÁRIOS,
              AGENTES, PARCEIROS E LICENCIADORES, PELO PRESENTE, REJEITAM TODAS
              AS GARANTIAS RELATIVAS A TAIS INFORMAÇÕES, PROGRAMAS DE SOFTWARE,
              PRODUTOS, SERVIÇOS E CONTEÚDOS INCLUSIVE, SEM LIMITAÇÃO, TODAS AS
              GARANTIAS IMPLÍCITAS E CONDIÇÕES DE COMERCIALIZAÇÃO, ADEQUAÇÃO A
              UM FIM ESPECÍFICO, TITULARIDADE E LICITUDE. A GLOBAL DIVERS
              NETWORK E SUAS SUBSIDIÁRIAS, AFILIADAS, EXECUTIVOS, FUNCIONÁRIOS,
              AGENTES, PARCEIROS E LICENCIADORES NÃO OFERECEM QUALQUER GARANTIA
              DE QUE: (a) O SERVIÇO ATENDERÁ SEUS REQUISITOS; (b) SEU CONTEÚDO
              ESTARÁ DISPONÍVEL OU O SERVIÇO SERÁ ININTERRUPTO, PRESTADO EM
              TEMPO HÁBIL, SEGURO OU SEM ERROS; (c) OS RESULTADOS QUE PODEM SER
              OBTIDOS COM O USO DO SERVIÇO SERÃO PRECISOS E CONFIÁVEIS; (d) A
              QUALIDADE DE QUAISQUER PRODUTOS, SERVIÇOS, INFORMAÇÕES OU OUTROS
              MATERIAIS ADQUIRIDOS OU OBTIDOS POR VOCÊ POR MEIO DO SERVIÇO
              ATENDERÃO SUAS EXPECTATIVAS; E (e) QUAISQUER ERROS NOS SERVIÇOS
              SERÃO CORRIGIDOS.
            </p>
            <p>
              VOCÊ CONCORDA EXPRESSAMENTE QUE A GLOBAL DIVERS NETWORK NÃO ESTÁ
              FORNECENDO ACONSELHAMENTO MÉDICO POR MEIO DOS SERVIÇOS. O CONTEÚDO
              FORNECIDO POR MEIO DOS SERVIÇOS, INCLUINDO TODO O TEXTO,
              FOTOGRAFIAS, IMAGENS, ILUSTRAÇÕES, GRÁFICOS, ÁUDIO, VÍDEO E CLIPES
              AUDIOVISUAIS, E OUTROS MATERIAIS, FORNECIDOS POR NÓS OU POR OUTROS
              USUÁRIOS QUE TENHAM UMA CONTA OU TERCEIROS NÃO SE DESTINA AO USO E
              NÃO DEVE SER USADO PARA SUBSTITUIR (a) ACONSELHAMENTO DO SEU
              MÉDICO OU DE OUTROS PROFISSIONAIS DE SAÚDE, (b) CONSULTA,
              TELEFONEMA OU EXAME COM O SEU MÉDICO OU OUTROS PROFISSIONAIS DE
              SAÚDE, OU (c) INFORMAÇÕES CONTIDAS EM EMBALAGENS OU RÓTULOS DE
              QUALQUER PRODUTO. CASO TENHA PERGUNTAS RELACIONADAS À SAÚDE, LIGUE
              PARA OU CONSULTE O SEU MÉDICO OU OUTRO PROFISSIONAL DE SAÚDE
              PRONTAMENTE. EM CASO DE EMERGÊNCIA, LIGUE PARA O SEU MÉDICO OU O
              190 IMEDIATAMENTE. VOCÊ NUNCA DEVE DESCONSIDERAR ORIENTAÇÕES
              MÉDICAS OU DEMORAR A BUSCAR ACONSELHAMENTO MÉDICO POR CAUSA DE
              QUALQUER CONTEÚDO APRESENTADO NOS SERVIÇOS, E VOCÊ NÃO DEVE USAR
              OS SERVIÇOS OU QUALQUER CONTEÚDO DOS SERVIÇOS PARA DIAGNOSTICAR OU
              TRATAR DE UM PROBLEMA DE SAÚDE. A TRANSMISSÃO E O RECEBIMENTO DO
              NOSSO CONTEÚDO, TOTAL OU PARCIAL, OU A COMUNICAÇÃO VIA INTERNET,
              E-MAIL OU OUTRO MEIO NÃO CONSTITUI NEM CRIA UM RELACIONAMENTO
              MÉDICO-PACIENTE, TERAPEUTA-PACIENTE OU OUTRO RELACIONAMENTO COM
              PROFISSIONAL DE SAÚDE ENTRE VOCÊ E A GLOBAL DIVERS NETWORK.
            </p>
            <p>
              VOCÊ CONCORDA EXPRESSAMENTE QUE SUAS ATIVIDADES ATLÉTICAS,
              RECREATIVAS OU PROFISSIONAIS (INCLUINDO, SEM LIMITAÇÃO, MERGULHO
              SCUBA, MERGULHO LIVRE, APNÉIA OU QUALQUER OUTRO TIPO DE ATIVIDADE
              INFORMADA EM NOSSOS SERVIÇOS) ENVOLVEM CERTOS RISCOS INERENTES E
              SIGNIFICATIVOS DE DANOS MATERIAIS, LESÕES CORPORAIS OU MORTE E QUE
              VOCÊ ASSUME TODOS OS RISCOS CONHECIDOS OU DESCONHECIDOS ASSOCIADOS
              A ESTAS ATIVIDADES AINDA QUE SEJAM CAUSADOS TOTAL OU PARCIALMENTE
              PELA AÇÃO, OMISSÃO OU NEGLIGÊNCIA DA GLOBAL DIVERS NETWORK OU PELA
              AÇÃO, OMISSÃO OU NEGLIGÊNCIA DE OUTROS.
            </p>
            <p>
              VOCÊ CONCORDA EXPRESSAMENTE QUE A GLOBAL DIVERS NETWORK NÃO
              ASSUMIRÃO RESPONSABILIDADE PELA INSPEÇÃO, SUPERVISÃO, PREPARAÇÃO
              OU REALIZAÇÃO DE QUALQUER ATIVIDADE DE MERGULHO, ESPORTIVA,
              RECREACIONAL, DESAFIO OU ATIVIDADE EM GRUPO QUE UTILIZE OS
              SERVIÇOS, INCLUSIVE QUALQUER UMA QUE SEJA ORGANIZADA POR USUÁRIOS
              DOS SERVIÇOS DA GLOBAL DIVERS NETWORK.
            </p>
            <p>
              VOCÊ CONCORDA EXPRESSAMENTE EM ISENTAR A GLOBAL DIVERS NETWORK,
              SUAS SUBSIDIÁRIAS, AFILIADAS, EXECUTIVOS, AGENTES, REPRESENTANTES,
              FUNCIONÁRIOS, PARCEIROS E LICENCIADORES (AS “PARTES ISENTAS”) DE
              TODA E QUALQUER RESPONSABILIDADE COM RELAÇÃO ÀS SUAS ATIVIDADES
              ATLÉTICAS, RECREATIVAS, AQUÁTICA E/OU USO DOS SITES, APLICATIVOS
              MÓVEIS, CONTEÚDO, SERVIÇOS OU PRODUTOS DA GLOBAL DIVERS NETWORK
              (INCLUSIVE, ENTRE OUTROS, QUALQUER ATIVIDADE DA GLOBAL DIVERS
              NETWORK), E PROMETE NÃO PROCESSAR AS PARTES ISENTAS POR QUAISQUER
              REIVINDICAÇÕES, AÇÕES, LESÕES, DANOS OU PERDAS ASSOCIADOS A ESSE
              USO. VOCÊ CONCORDA TAMBÉM QUE, EM CIRCUNST NCIA ALGUMA, AS PARTES
              ISENTAS SERÃO RESPONSÁVEIS PERANTE VOCÊ OU QUAISQUER TERCEIROS POR
              DANOS DIRETOS, INDIRETOS, PUNITIVOS, INCIDENTAIS, ESPECIAIS OU
              CONSEQUENTES DECORRENTES OU DE ALGUMA FORMA RELACIONADOS COM (a)
              SEU USO OU USO INADEQUADO DOS SERVIÇOS, (b) SEU USO OU USO
              INADEQUADO DE EQUIPAMENTOS OU PROGRAMAS CRIADOS OU LICENCIADOS
              PELA GLOBAL DIVERS NETWORK DURANTE A REALIZAÇÃO DE ATIVIDADES
              ATLÉTICAS, RECREATIVAS, AQUÁTICAS (c) SUAS NEGOCIAÇÕES COM
              PROVEDORES DE SERVIÇOS TERCEIRIZADOS OU ANUNCIANTES DISPONÍVEIS
              POR MEIO DOS SERVIÇOS, (d) QUALQUER ATRASO OU INCAPACIDADE DE USAR
              OS SERVIÇOS, OU (e) QUAISQUER INFORMAÇÕES, PROGRAMAS DE SOFTWARE,
              PRODUTOS, SERVIÇOS OU CONTEÚDOS OBTIDOS ATRAVÉS DOS SERVIÇOS, SEJA
              COM BASE EM CONTRATO, ATO ILÍCITO, RESPONSABILIDADE ESTRITA, ENTRE
              OUTROS, MESMO QUE A GLOBAL DIVERS NETWORK TENHA SIDO AVISADA DA
              POSSIBILIDADE DE DANOS.
            </p>
            <p>
              COMO ALGUNS ESTADOS/JURISDIÇÕES NÃO PERMITEM ALGUMAS OU TODAS AS
              EXCLUSÕES OU LIMITAÇÕES DE RESPONSABILIDADE ACIMA, TAIS COMO
              EXCLUSÕES DE DANOS CONSEQUENTES OU INCIDENTAIS, ALGUMAS DAS
              LIMITAÇÕES OU EXCLUSÕES ACIMA PODERÃO NÃO SE APLICAR A VOCÊ. AO
              FIRMAR ESSA ISENÇÃO, VOCÊ RECONHECE QUE LEU E COMPREENDEU, E
              EXPRESSAMENTE RENUNCIA AOS BENEFÍCIOS DA SEÇÃO 1542 DO CÓDIGO
              CIVIL DA CALIFÓRNIA (E DE QUALQUER LEI SEMELHANTE DE QUALQUER
              ESTADO, PAÍS OU TERRITÓRIO), QUE DETERMINA QUE: “UMA RENÚNCIA
              GERAL NÃO ABRANGE REIVINDICAÇÕES QUE O CREDOR NÃO CONHEÇA OU
              SUSPEITE EXISTIREM EM SEU FAVOR NO MOMENTO DA EXECUÇÃO DA
              RENÚNCIA, QUE, CASO FOSSEM CONHECIDAS, TERIAM AFETADO
              SUBSTANCIALMENTE SEU ACORDO COM O DEVEDOR”.
            </p>
            <p>
              A GLOBAL DIVERS NETWORK NÃO ENDOSSA O SEU CONTEÚDO NEM O CONTEÚDO
              DE QUALQUER OUTRA PESSOA E ESPECIFICAMENTE ISENTA-SE DE QUALQUER
              RESPONSABILIDADE PERANTE PESSOAS OU ENTIDADES POR QUALQUER PERDA,
              DANO (SEJA ELE REAL, CONSEQUENTE, INDIRETO, PUNITIVO OU OUTRO),
              LESÃO, REIVINDICAÇÃO, RESPONSABILIDADE OU OUTRA CAUSA DE QUALQUER
              TIPO OU CARÁTER BASEADA OU RESULTANTE DE QUALQUER CONTEÚDO DOS
              SERVIÇOS.
            </p>
            <p>
              A RESPONSABILIDADE MÁXIMA TOTAL DA EMPRESA PERANTE VOCÊ, DE ACORDO
              COM ESTES TERMOS, NÃO EXCEDERÁ (a) US$50 (CINQUENTA DÓLARES) OU
              (b) O TOTAL DE TAXAS PAGAS POR VOCÊ À GLOBAL DIVERS NETWORK NOS 12
              MESES ANTERIORES À AÇÃO, O QUE FOR MAIOR.
            </p>
            <p>
              SE VOCÊ FOR UM CONSUMIDOR RESIDENTE NA UNIÃO EUROPEIA, TERÁ
              RECURSOS JURÍDICOS SE CAUSARMOS PERDA POR NOSSA VIOLAÇÃO OU NÃO
              DESEMPENHO DE OBRIGAÇÕES LEGAIS, OU POR NOSSA NEGLIGÊNCIA, E ESTES
              TERMOS NÃO AFETAM ESSES RECURSOS ESTATUTÁRIOS.
            </p>
            <h3>Indenização</h3>
            <p>
              Você concorda em indenizar e isentar a Global Divers Network e
              suas subsidiárias, afiliadas, executivos, agentes, representantes,
              funcionários, parceiros e licenciadores de responsabilidade por
              qualquer reivindicação ou demanda, inclusive honorários
              advocatícios razoáveis incorridos por quaisquer terceiros devidos
              ao ou resultantes do Conteúdo enviado, publicado, transmitido ou
              disponibilizado por você por meio dos Serviços, do seu uso dos
              Serviços, das suas atividades atléticas, recreativas e aquáticas
              que geraram o Conteúdo que você publicou ou tentou publicar nos
              Serviços (inclusive, entre outros, atividades relacionadas com
              competições, mergulhos, atividades em grupo ou outros eventos que
              a Global Divers Network patrocine, organize, participe ou cujos
              Serviços sejam usados em conexão), sua conexão com os Serviços,
              sua violação dos Termos, sua violação de quaisquer leis de
              proteção ou privacidade de dados ou sua violação de quaisquer
              direitos de outra pessoa ou entidade. Os seus direitos com
              respeito à Global Divers Network não são modificados pela
              indenização referida, se as leis do seu país de residência,
              aplicáveis como resultado do seu uso dos nossos Serviços, não o
              permitirem.
            </p>
            <h4>Aviso para usuários da Califórnia</h4>
            <p>
              De acordo com o Código Civil da Califórnia, seção 1789.3, os
              usuários de Serviços da Califórnia têm o direito de receber o
              seguinte aviso específico sobre direitos do consumidor: a Unidade
              de assistência de reclamações da Divisão de serviços ao consumidor
              da Secretaria de Estado de relações com o consumidor pode ser
              contatada por escrito no endereço 1625 N. Market Blvd., Suite N
              112, Sacramento, Califórnia 95834, ou por telefone no número (800)
              952-5210.
            </p>
            <h3>Resolução de disputas</h3>
            <h4>Arbitragem</h4>
            <p>
              As partes se empenharão ao máximo para resolver qualquer disputa,
              reivindicação, dúvida ou divergência diretamente em negociações de
              boa fé, que serão um pré-requisito para qualquer uma das partes
              que iniciar uma ação legal ou arbitragem. Exceto as disputas
              relacionadas à propriedade intelectual da Global Divers Network
              (como marcas comerciais, identidade visual, nomes de domínio,
              segredos comerciais e patentes) ou se você renunciar a este acordo
              de arbitragem, todas as reivindicações decorrentes ou referentes a
              este Acordo e ao seu uso dos Serviços deverão ser resolvidas de
              forma definitiva por arbitragem vinculativa pela JAMS, conforme
              suas disposições e procedimentos para disputas relacionadas ao
              consumidor, excluindo quaisquer regras ou procedimentos que regem
              ou permitem ações coletivas. O juiz, e não um tribunal ou agência,
              terá autoridade exclusiva para resolver todas as disputas
              decorrentes de ou relacionadas a este Acordo, inclusive, sem
              limitação, qualquer reivindicação de que este Acordo é, no todo ou
              em parte, nulo ou anulável. O juiz terá o poder de conceder
              qualquer reparação que estaria disponível em um tribunal;
              contanto, porém, que o juiz não tenha autoridade para conceder
              indenizações, remediações ou adjudicações conflitantes com este
              Acordo. A sentença do juiz deverá ser vinculativa às partes e
              poderá ser incluída como julgamento em qualquer tribunal de
              jurisdição competente. Na extensão em que a taxa de apresentação
              de juizado exceder o custo de registro de uma ação, a Global
              Divers Network arcaram com os custos adicionais. Você, a Global
              Divers Network, por meio deste documento, renunciam expressamente
              ao direito a julgamento com júri. Você também concorda em não
              participar de reivindicações levadas a um advogado particular ou
              em capacidade representativa, ou reivindicações consolidadas
              envolvendo a conta de outra pessoa, caso a Global Divers Network
              seja uma das partes no processo. Esta disposição de resolução de
              disputas será regida pela Lei de Arbitragem Federal dos Estados
              Unidos. Esta disposição não se aplica aos usuários que residirem
              na União Europeia.
            </p>
            <p>
              As partes entendem que, na ausência desta disposição obrigatória,
              elas teriam direito à ação judicial e a recorrer ao tribunal do
              júri. Fica também entendido que, em alguns casos, os custos da
              arbitragem podem exceder os custos do litígio e que o direito ao
              acesso a evidências pode ser mais limitado na arbitragem do que na
              ação judicial.
            </p>
            <h3>Renúncia ao direito de ação coletiva</h3>
            <p>
              As partes também concordam que qualquer arbitragem será realizada
              somente em suas capacidades individuais, e não como uma ação
              coletiva ou outra ação de representação. Se um tribunal ou árbitro
              determinar que a renúncia ao direito à ação coletiva definida
              nesta seção é inválida ou inexequível por qualquer motivo, ou que
              uma arbitragem pode ser realizada com base na classe, a disposição
              de arbitragem definida anteriormente deverá ser considerada nula e
              inválida em sua totalidade e será considerado que as partes não
              concordaram em recorrer à arbitragem.
            </p>
            <h4>
              Exceção — Litígio de reivindicações em juizado especial de
              pequenas causas
            </h4>
            <p>
              Não obstante a decisão das partes de resolver todas as disputas
              por arbitragem, qualquer uma das partes também poderá buscar uma
              decisão em um tribunal de pequenas causas para as disputas ou
              reivindicações dentro do escopo dessa jurisdição do tribunal.
            </p>
            <h4>Prazo de 30 dias para cancelamento</h4>
            <p>
              Você tem o direito de optar por não recorrer à arbitragem ou não
              sujeitar-se às disposições da arbitragem e da renúncia à ação
              coletiva estabelecidas nesta seção enviando uma notificação por
              escrito da sua decisão ao seguinte endereço:
            </p>
            <address>
              <strong>Global Divers Network</strong>
              <br />
              Rua Álamo, 148 - Balneário Cassino
              <br />
              Rio Grande - RS, Brasil
              <br />
              <strong>Email</strong>
              <br />
              <a href="mailto:#">contato@globaldiversnetwork.com</a>
            </address>
            <p>
              A notificação deverá ser enviada até 30 dias após o cadastro para
              usar os Serviços. Caso contrário, você estará obrigado a
              participar em arbitragem de disputas de acordo com estes Termos.
              Caso você opte por não sujeitar-se às disposições sobre
              arbitragem, a Global Divers Network também não estará sujeita a
              elas. Além disso, se optar por não sujeitar-se a essas disposições
              sobre arbitragem, a Global Divers Network poderá cancelar o seu
              uso dos Serviços.
            </p>
            <h3>Limitação de tempo para reivindicações</h3>
            <p>
              Você concorda que qualquer reivindicação que você possa ter
              decorrente de ou referente ao seu relacionamento com a Global
              Divers Network e estes Termos será apresentada até um ano após o
              surgimento da reivindicação. Caso contrário, ela será
              permanentemente impedida. Esta disposição não se aplica se você
              estiver baseado na União Europeia. Nesse caso, as limitações de
              tempo serão determinadas de acordo com a lei regente para os
              usuários da UE, mencionada abaixo.
            </p>
            <h3>Eleição de legislação e foro</h3>
            <p>
              Qualquer ação referente aos Termos, Conteúdo, Serviços e seu
              relacionamento com a Global Divers Network será regida, definida e
              interpretada conforme as leis do estado do Rio Grande do Sul, no
              Brasil, sem considerar conflitos de princípios legais E
              ESPECIFICAMENTE NÃO SERÁ REGIDA PELAS CONVENÇÕES DAS NAÇÕES UNIDAS
              SOBRE CONTRATOS PARA A VENDA INTERNACIONAL DE MERCADORIAS, SE
              APLICÁVEL DE OUTRA FORMA. Conforme a Seção intitulada “Resolução
              de disputas”, as partes irrevogavelmente consentem em levar
              qualquer ação necessária para o cumprimento deste Acordo aos
              tribunais federal e estadual que incluam Porto Alegre, Rio Grande
              do sul, Brasil, e você consente com a jurisdição exclusiva dos
              tribunais federal ou estadual que incluam Porto Alegre, no Rio
              Grande do Sul, Brasil. Se uma das partes mover um processo ou ação
              contra a outra para reparação de danos, declaratória ou de outra
              forma, decorrente destes Termos, a parte vitoriosa obterá e
              receberá da outra parte, além de todas as custas e despesas
              processuais, a quantia determinada pelo tribunal como honorários
              advocatícios razoáveis. Exceto na extensão proibida pela
              legislação aplicável, as partes concordam que qualquer ação ou
              causa decorrente ou relacionada ao uso dos Serviços ou aos Termos
              deve ser apresentada dentro de um (1) ano do surgimento da ação ou
              causa, ou será permanentemente impedida. Este parágrafo não se
              aplica aos usuários que residirem na União Europeia.
            </p>
            <p>
              Se você for um usuário baseado na União Europeia, a lei irlandesa
              se aplicará a estes Termos e os tribunais irlandeses terão
              jurisdição exclusiva para as disputas que surgirem com relação aos
              Termos. Apesar disso, suas leis locais no seu Estado-membro da
              União Europeia podem permitir que você tome medidas legais contra
              a Global Divers Network em seu Estado-membro e invoque
              determinadas leis locais contra a Global Divers Network.
            </p>
            <h3>Operação no Brasil</h3>
            <p>
              Estes Serviços são controlados pela Global Divers Network em seu
              escritório no Brasil. A Global Divers Network não garante que o
              Conteúdo ou os Serviços são apropriados ou estão disponíveis para
              uso em outros locais. É proibido acessar ou usar o Conteúdo ou os
              Serviços em territórios nos quais tal acesso ou uso é ilegal.
              Aqueles que escolherem acessar os Serviços a partir de locais fora
              do Brasil o fazem por iniciativa própria e são responsáveis pela
              conformidade com as leis locais aplicáveis. Você não pode usar ou
              exportar o Conteúdo em violação das leis e regulamentos de
              exportação do Brasil.
            </p>
            <h3>Encerramento</h3>
            <p>
              Você concorda que a Global Divers Network pode, em determinadas
              circunstâncias graves e sem notificação prévia, suspender ou
              encerrar a sua conta e/ou acesso aos Serviços imediatamente. Os
              motivos para tal suspensão ou encerramento incluem, sem limitação,
              (a) violações dos Termos ou de outros acordos, políticas e
              diretrizes incorporados, (b) solicitações de autoridades legais ou
              outros órgãos governamentais, (c) solicitação feita por você
              (exclusões de conta por iniciativa própria), (d) interrupção ou
              modificação substancial dos Serviços (ou de qualquer parte deles),
              (e) problemas técnicos ou de segurança inesperados, (f) períodos
              prolongados de inatividade e/ou (g) falta de pagamento de
              quaisquer taxas devidas por você relativas aos Serviços. O
              encerramento da sua conta pode incluir (x) remoção do acesso a
              todas as opções oferecidas dentro dos Serviços, (y) exclusão das
              suas informações, arquivos e Conteúdos associados a sua conta e
              (z) impedimento da continuidade de uso dos Serviços. Além disso,
              você concorda que todas as suspensões ou os encerramentos com
              causa ocorrerão a exclusivo critério da Global Divers Network e
              que a Global Divers Network não será responsável perante você ou
              quaisquer terceiros pela suspensão ou encerramento da sua conta ou
              acesso aos Serviços. As seguintes Seções sobreviverão ao
              encerramento de sua conta e/ou dos Termos: Conteúdo e Conduta,
              Direitos de Propriedade, Seu Feedback, Isenção de Garantias e
              Responsabilidade, Indenização, Escolha de Lei e Fórum e Geral.
            </p>
            <h3>Geral</h3>
            <p>
              Você concorda que nenhuma relação de joint venture, parceria,
              controle conjunto, emprego ou agência existe entre você e a Global
              Divers Network como resultado dos Termos ou do seu uso dos
              Serviços. Os Termos constituem o acordo completo entre você e a
              Global Divers Network com relação ao seu uso dos Serviços. O não
              exercício ou imposição da Global Divers Network de qualquer
              direito ou disposição dos Termos não constituirá uma renúncia de
              tal direito ou disposição. Se qualquer disposição dos Termos for
              considerada por um tribunal de jurisdição competente como
              inválida, as partes concordarão, ainda assim, que o tribunal
              deverá se empenhar para dar efeito às intenções das partes
              conforme refletidas na disposição, e as outras disposições dos
              Termos permanecerão em pleno vigor e efeito. Você não pode
              atribuir, delegar ou, de alguma forma, transferir sua conta ou
              suas obrigações de acordo com estes Termos sem o consentimento
              prévio por escrito da Global Divers Network. A Global Divers
              Network tem o direito, a seu exclusivo critério, de transferir ou
              atribuir seus direitos, no todo ou em parte, de acordo com estes
              Termos e terá o direito de delegar ou utilizar contratados
              terceirizados para cumprir seus deveres e obrigações conforme
              estes Termos e com relação aos Serviços. A notificação da Global
              Divers Network para você por e-mail, correio ou avisos,
              publicações ou links nos Serviços constituirão uma notificação
              aceitável para você de acordo com os Termos. Uma versão impressa
              dos Termos e de qualquer notificação enviada em forma eletrônica
              será aceita em ações judiciais ou administrativas decorrentes de
              ou relativas aos Termos na mesma extensão e sujeito às mesmas
              condições de outros documentos e registros comerciais
              originalmente gerados e mantidos em forma impressa. Os títulos e
              cabeçalhos de seção nos Termos têm fins unicamente práticos e não
              têm efeito legal ou contratual. Quaisquer direitos não concedidos
              expressamente neste documento são reservados.
            </p>
            <h3>Modificação dos Termos e serviços</h3>
            <p>
              Determinadas disposições dos Termos poderão ser complementadas ou
              substituídas por termos ou avisos legais designados expressamente
              em páginas específicas nos Serviços. A Global Divers Network
              reservam-se o direito de atualizar os Termos a qualquer momento e
              por qualquer motivo, a seu exclusivo critério. A Global Divers
              Network notificarão você sobre quaisquer alterações substanciais
              nos Termos ou em qualquer serviço ou outros recursos dos Serviços.
              Ao continuar acessando ou usando os Serviços após fornecermos um
              aviso de modificação, você concorda em sujeitar-se aos Termos
              modificados. Se os Termos modificados não forem aceitáveis para
              você, seu único recurso é interromper o uso dos Serviços.
            </p>
            <p>
              A Global Divers Network e seus provedores de serviços
              terceirizados podem fazer aprimoramentos e/ou alterações nos
              Serviços, produtos, aplicativos móveis, recursos, programas e
              preços descritos a qualquer momento e por qualquer motivo, a seu
              exclusivo critério. O aplicativo móvel pode baixar e instalar
              upgrades, atualizações e recursos adicionais para melhorar,
              aprimorar e desenvolver ainda mais os Serviços e adicionar novas
              funcionalidades, tanto em aplicativos móveis quanto websites. A
              Global Divers Network reservam-se o direito de, a qualquer
              momento, modificar ou suspender, em caráter temporário ou
              permanente, os Serviços ou qualquer parte deles com ou sem
              notificação. Você concorda que a Global Divers Network não terá
              responsabilidade perante você ou quaisquer terceiros pela
              modificação, suspensão ou interrupção dos Serviços.
            </p>
            <h3>Suporte e dúvidas</h3>
            <p>
              Responderemos a quaisquer perguntas sobre os Serviços e estes
              Termos pelo email{" "}
              <a href="mailto:#">contato@globaldiversnetwork.com</a>
            </p>
            <p>© 2023 Global Divers Network</p>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default TermosDeUso;
