import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsCheck2Square } from "react-icons/bs";
import { Log, loggerInitialState, Size } from "../../interfaces/interfaces";
import ValidationModal from "../Modal/ValidationModal";

function ValidateLogBtn({
  log,
  className,
  size,
  iconSize,
}: {
  log: Log;
  className?: string;
  size?: Size;
  iconSize?: string;
}) {
  const [showValidationModal, setShowValidationModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="outline-dark"
        onClick={() => setShowValidationModal(true)}
        className="mb-2 w-100"
        size={size}
      >
        {" "}
        <BsCheck2Square
          size={iconSize ? iconSize : "30"}
          className="mx-1"
        />{" "}
        {t("validate") + " " + t("dive").toLocaleLowerCase()}
      </Button>

      <ValidationModal
        shouldShow={showValidationModal}
        log={log || loggerInitialState}
        setShouldShow={setShowValidationModal}
      ></ValidationModal>
    </>
  );
}

export default ValidateLogBtn;
