import { UserStatus } from "../interfaces/interfaces";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

export async function setUserStatus(userId: string, status: UserStatus) {
  const userDoc = doc(db, "users", userId);
  await updateDoc(userDoc, {
    status,
  });
}

export async function getUserById(
  userId: string | undefined,
  setLoading: Function
) {
  if (!userId) return;
  const userRef = doc(db, "users", userId);
  setLoading(true);

  try {
    const userDoc = await getDoc(userRef);
    return userDoc.data();
  } catch (err) {
    alert("Error");
  } finally {
    setLoading(false);
  }
}
