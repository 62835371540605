import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import RiseLoader from "react-spinners/RiseLoader";
import { useTranslation } from "react-i18next";
import ErrorModal from "../components/Modal/ErrorModal";
import { USER_AVATAR } from "../config/constants";
import { Helmet } from "react-helmet";
import PlacesInput from "../components/Autocomplete/PlacesInput";
import "../locales/i18n";
import "./register.css";
import GDNy from "../components/GDNbrand/GDNy";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [home, setHome] = useState({ home: " ", latLng: "" });
  const [check, setCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect-to");
  const id = searchParams.get("id");
  const { t } = useTranslation();

  function handleCheck(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    setCheck(value);
  }

  const registerWithEmailAndPassword = async (
    email: string,
    password: string,
    name: string
  ) => {
    setIsLoading(true);

    const userAvatar = USER_AVATAR;

    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await updateProfile(user, {
        displayName: name,
        photoURL: userAvatar,
      });

      const userInfo = {
        id: user.uid,
        email: email,
        displayName: name,
        dives: [],
        tags: [],
        photoUrl: userAvatar,
        home: home.home || "",
        latLng: home.latLng || "",
      };
      await setDoc(doc(db, "users", user.uid), userInfo);
    } catch (err: any) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const register = (e: FormEvent) => {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      alert("Por favor, preencha todos os campos antes de prosseguir.");
      return;
    }

    if (!check) {
      alert(
        "Você precisa aceitar os Termos de uso e a Política de Privacidade para prosseguir."
      );
      return;
    }

    registerWithEmailAndPassword(email, password, name);
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      if (redirectTo === "log") {
        navigate(`/log/${id}`);
        return;
      }
      if (redirectTo === "validate-log") {
        navigate(`/validate-log/${id}`);
        return;
      }
      navigate("/");
    }
  }, [user, loading]);

  return (
    <div className="register">
      <Helmet>
        <title>Global Divers Network</title>
        <meta
          name="description"
          content={t("register") + " - Global Divers Network" || ""}
        />
      </Helmet>
      <div className="text-center">
        <main className="form-signin w-100 m-auto">
          <GDNy />
          <hr></hr>
          <h1 className="h3 mb-3 fw-normal">{t("create_account")}</h1>
          <Form noValidate validated={validated} onSubmit={register} id="form">
            <FloatingLabel label={t("email")}>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FloatingLabel>
            <FloatingLabel label={t("password")}>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FloatingLabel>
            <FloatingLabel label={t("full_name")}>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FloatingLabel>

            <FloatingLabel label="Onde você mora?">
              <PlacesInput
                name="home"
                state={home}
                setState={setHome}
                className="form-control form-control-lg mt-2"
              ></PlacesInput>
            </FloatingLabel>

            <Form.Group className="my-2 text-start">
              <Form.Check
                type="checkbox"
                onChange={handleCheck}
                label={
                  <span className="form-text">
                    Declaro que li e estou de acordo com os <br />
                    <Link
                      to="/policies/pt/termos-de-uso"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Termos de uso
                    </Link>{" "}
                    e{" "}
                    <Link
                      to="/policies/pt/politica-de-privacidade"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Política de privacidade
                    </Link>
                    .
                  </span>
                }
              />
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              className="w-100 mt-2"
              disabled={isLoading}
            >
              {isLoading ? <RiseLoader size={5} color="#fff" /> : t("register")}
            </Button>
          </Form>
          <hr></hr>
          <div>
            {t("already_registered")}? <Link to="/login">{t("enter")}</Link>{" "}
            {t("now")}.
          </div>
        </main>
      </div>
      <ErrorModal shouldShow={error} redirectTo="/login"></ErrorModal>
    </div>
  );
}

export default Register;
