import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { times } from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Faq() {
  const numberOfQuestions = 9;
  const { t } = useTranslation(["faq"]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("faq") + " - GDN"}</title>
        <meta name="description" content={`${t("description")}`} />
      </Helmet>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/" className="no-style-link">
              {t("back")}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{t("faq")}</h3>
          </Col>
        </Row>
        <Accordion alwaysOpen className="mt-1">
          {times(numberOfQuestions, (i) => (
            <Accordion.Item eventKey={i.toString()}>
              <Accordion.Header>
                <strong>{t(`q${i + 1}`)}</strong>
              </Accordion.Header>
              <Accordion.Body className="text-box">
                {t(`a${i + 1}`)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
      <Footer />
    </>
  );
}

export default Faq;
