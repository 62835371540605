import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoInformationSharp } from "react-icons/io5";

function InfoModal({
  shouldShow,
  message,
  setShowInfoModal,
}: {
  shouldShow: boolean;
  message: string;
  setShowInfoModal: Function;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function redirect(url: string) {
    navigate(url);
  }

  return (
    <Modal
      centered
      show={shouldShow}
      onHide={() => {
        setShowInfoModal(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <Row className="mt-4 mb-4">
          <Col>
            <IoInformationSharp size={120} className="text-primary" />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>{message}</Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default InfoModal;
