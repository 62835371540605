import { LocationInterface } from "../interfaces/location";

export const LOCATIONS: Array<LocationInterface> = [
  {
    id: "ilhabela-SP",
    name: "Ilhabela",
    fullName: "Ilhabela - SP",
    state: "São Paulo",
    img: "/images/locations/ilhabela.jpg",
    description: {
      pt: "Ilhabela é um destino turístico muito popular e muitas pessoas visitam a ilha para aproveitar suas trilhas, praias e a natureza exuberante. Algumas das praias mais famosas da ilha incluem a Praia do Curral, Praia Grande, Praia da Feiticeira e Praia de Castelhanos.\nAlém das belas praias, é também um excelente destino para o mergulho. Alguns dos pontos de mergulho mais populares em Ilhabela incluem:\n \u2043 Ilha das Cabras: grande variedade de vida marinha, como tartarugas, arraias, polvos, peixes coloridos e até mesmo golfinhos;\n \u2043 Navio Velasquez: navio espanhol que afundou em 1896 e que está a cerca de 45 metros de profundidade; \n \u2043 Navio Aymoré: cargueiro brasileiro que afundou em 1972 e agora é um recife artificial para a vida marinha, em uma profundidade de cerca de 25 metros.\n",
      en: "",
    },
  },
  {
    id: "saoJoseDaCoroaGrande-PE",
    name: "São José da Coroa Grande",
    fullName: "São José da Coroa Grande - PE",
    state: "Pernambuco",
    img: "/images/locations/sao-jose-da-coroa-grande.jpg",
    description: {
      pt: "São José da Coroa Grande é uma cidade localizada no litoral sul do estado de Pernambuco, no Brasil. A cidade é conhecida por suas belas praias e pela prática de atividades aquáticas, como o mergulho e o surf. Além disso, a cidade também é conhecida pelo seu artesanato, especialmente as rendas e bordados produzidos pelas mulheres da comunidade local.\nA economia da cidade é baseada principalmente na pesca, no turismo, e na atividade do mergulho.\nApresentando água cristalina, é possível observar com perfeição o Naufrágio do Pescador em seus 34 metros de profundidade, envolto em grandes cardumes, e também inúmeros cabeços que contam com moradores especiais: raias, tubarões, polvos, lagostas, lulas, peixes e, não raro, enormes Meros, expécie de grande porte, infelizmente ameaçada de extinção.",
      en: "",
    },
  },
  {
    id: "buzios-RJ",
    name: "Armação dos Búzios",
    fullName: "Armação dos Búzios - RJ",
    state: "Rio de Janeiro",
    img: "/images/locations/buzios.jpeg",
    description: {
      pt: "Armação dos Búzios é uma charmosa cidade localizada na região dos Lagos, no Rio de Janeiro, sendo famosa por suas belas praias, gastronomia e vida noturna. A cidade é frequentada por turistas do mundo todo e oferece uma ampla variedade de atividades, desde relaxar em praias paradisíacas até explorar a rica cultura local.\nCom águas cristalinas, a cidade oferece uma variedade de locais para mergulho: são 5 pontos mapeados, sendo o mais frequente a Ilha da Âncora, um Santuário de Tartarugas.\nA profundidade máxima neste ponto é de até 24 metros, com a temperatura da água normalmente variando entre 20°C e 23°C e visibilidade média de 20 metros, mas podendo passar dos 30 metros.",
      en: "",
    },
  },
  {
    id: "portoSeguro-BA",
    name: "Porto Seguro",
    fullName: "Porto Seguro - BA",
    state: "Bahia",
    img: "/images/locations/porto-seguro.jpg",
    description: {
      pt: "Porto Seguro é uma cidade encantadora localizada na costa sul da Bahia, no Brasil. Fundada em 1535, a cidade é um importante destino turístico, famosa por suas praias paradisíacas, clima quente e sua história rica e culturalmente diversa.\nA cidade é conhecida por ser o local onde os portugueses desembarcaram pela primeira vez no Brasil, em 1500. O centro histórico de Porto Seguro abriga uma série de monumentos históricos, incluindo igrejas e museus, que contam a história da região.\nAs praias de Porto Seguro são um verdadeiro tesouro, com águas cristalinas, areia branca e muita vegetação exuberante. As praias mais populares da cidade são a Praia do Mutá, a Praia do Taperapuã, a Praia de Coroa Vermelha e a Praia do Rio da Barra.\nA cidade também é conhecida por sua animada vida noturna, com bares e restaurantes que servem pratos típicos da deliciosa culinária baiana",
      en: "",
    },
  },
];

// ##### BELOW LOCATIONs CONTRACT IS TO BE CONFIRMED ...
// export const LOCATIONS = [
//   {
//     id: "Recife-PE",
//     name: "Recife",
//     fullName: "Recife - PE",
//     state: "Pernambuco",
//     subtitle: "a capital brasileira dos naufrágios",
//     img: "/images/places/recife.jpeg",
//   },
// ];
