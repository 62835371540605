import React, { useState } from "react";
import { getAuth, User } from "firebase/auth";
import { getUserById } from "../../model/UserModel";
import { getUserLogs } from "../../model/LogModel";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const omitDeep = require("omit-deep-lodash");

function DownloadInfo({ label }: { label: string }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function downloadData() {
    try {
      setLoading(true);
      const auth = getAuth();
      const authUser = auth.currentUser;
      if (!authUser) {
        alert("User not found");
        return;
      }

      const profile = fetchAuthUserData(authUser);
      const geo = await fetchUserData(authUser.uid);
      const logs = await fetchDives(authUser.uid);
      const rawObj = { profile, geo, logs };
      const filter = omitDeep(rawObj, ["logId", "userId", "id", "uid"]);

      const dataStr = JSON.stringify(filter);
      const blob = new Blob([dataStr], { type: "text/plain" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.download = "user-info.json";
      link.href = url;
      link.click();
    } catch (err) {
      alert(t("generic_error"));
      navigate("/");
    } finally {
      setLoading(false);
    }
  }

  function fetchAuthUserData(user: User) {
    const profile = {
      email: user.email,
      "Verified email": user.emailVerified,
      "Display name": user.displayName,
      "Is anonymous": user.isAnonymous,
      "Photo URL": user.photoURL,
      "Created at": user.metadata.creationTime,
      "Last login at": user.metadata.lastSignInTime,
    };

    return profile;
  }

  async function fetchUserData(uid: string) {
    try {
      const user = await getUserById(uid, setLoading);
      if (!user) return;
      const location = {
        location: user.home,
      };

      return location;
    } catch (err) {
      alert(t("generic_error"));
      navigate("/");
    }
  }

  async function fetchDives(uid: string) {
    try {
      const everyone = await getUserLogs(uid, "everyone");
      const owner = await getUserLogs(uid, "owner");
      const followers = await getUserLogs(uid, "followers");
      const logs = {
        "Logs Visibility": {
          everyone,
          owner,
          followers,
        },
      };

      return logs;
    } catch (err) {
      alert(t("generic_error"));
      navigate("/");
    }
  }

  return (
    <Button variant="link" onClick={downloadData} disabled={loading}>
      {label}
    </Button>
  );
}

export default DownloadInfo;
