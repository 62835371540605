import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";

function SalesContract() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="text-justify mt-4">
        <Helmet>
          <title>Contrato de prestação de serviço - GDN</title>
          <meta
            name="description"
            content="Contrato de prestação de serviço e venda de mergulhos e cursos de mergulhos pela Global Divers Network."
          />
        </Helmet>
        <Row>
          <Col>
            <h1>Contrato de prestação de serviço</h1>
            <small className="text-mutted">
              Versão 1.0 - Data de vigência: 11 de Abril de 2023
            </small>
            <hr></hr>
            <h5>IDENTIFICAÇÃO DAS PARTES CONTRATANTES:</h5>
            <ul>
              <li>
                <strong>CONTRATANTE:</strong> cliente autenticado em compras no
                site da Global Divers Network
              </li>
              <li>
                <strong>CONTRATADA:</strong> Global Divers Network, doravante
                designada <strong>GDN</strong>, com sede na rua Álamo, 148,
                Balneário Cassino, Rio Grande/RS, CEP: 96208-110, inscrita no
                CNPJ sob o nº 49.049.826/0001-34
              </li>
            </ul>
            <p>
              A confirmação da compra/reserva caracteriza imediata e total
              aceitação dos termos abaixo descritos.
            </p>
            <h5>OBRIGAÇÕES DO CONTRATANTE</h5>
            <p>
              <strong>Cláusula 1ª</strong>. O<strong> CONTRATANTE </strong>
              deverá fornecer à<strong> CONTRATADA </strong> todas as
              informações necessárias à realização do serviço, devendo
              especificar os detalhes necessários à perfeita e segura execução
              do mesmo.
            </p>
            <p>
              <strong>Cláusula 2ª</strong>. O<strong> CONTRATANTE </strong>
              deverá informar e comprovar à<strong> CONTRATADA </strong> os
              dados sobre formações de mergulho (quando for o caso),
              questionário de saúde, tamanho/tipo de equipamento a ser reservado
              e demais informações necessárias que venham a ser solicitadas
              visando a segurança e a prestação de serviço adequadamente.
            </p>
            <p>
              <strong>Parágrafo único: </strong>as atividades de mergulho serão
              agendadas com no mínimo 72 (setenta e duas) horas de antecedência,
              e o início de cursos com no mínimo 57 (cinquenta e sete) dias
              corridos de antecedência.
            </p>
            <p>
              <strong>Cláusula 3ª</strong>. Menores de 18 anos devem estar
              acompanhados pelo responsável legal ou apresentar cópia do
              documento e autorização dos responsáveis legais, redigida a
              próprio punho, permitindo a prática da atividade.
            </p>
            <p>
              <strong>Cláusula 4ª</strong>. É necessário observar a idade mínima
              permitida para cada tipo de atividade e curso, bem como o estado
              de saúde para a prática das atividades escolhidas, a ser avaliado
              pela operadora de mergulho.
            </p>
            <p>
              <strong>Cláusula 5ª</strong>. O<strong> CONTRATANTE </strong>é
              única e exclusivamente responsável por fornecer informações sobre
              estado de saúde, doenças cardíacas, respiratórias, neurológicas ou
              de qualquer outra origem, assim como o uso de remédios controlados
              e/ou de uso contínuo e não ter cirurgia recente; a operadora pode
              solicitar atestado médico quando julgar necessário comprovando
              aptidão para a atividade.
            </p>
            <p>
              <strong>Parágrafo único: </strong>Segundo recomendações para o
              mergulho autônomo recreativo, recomenda-se aguardar no mínimo 24
              horas após o mergulho para realizar vôos.
            </p>
            <p>
              <strong>Cláusula 6ª</strong>. A<strong> CONTRATADA </strong> é
              isenta de qualquer responsabilidade sobre qualquer dano aos
              equipamentos utilizados nas atividades de mergulho e/ou
              cursos/treinamentos, sendo de dever do{" "}
              <strong>CONTRATANTE </strong>zelar pelo cuidado e uso adequado.
            </p>
            <h5>OBRIGAÇÕES DA CONTRATADA</h5>
            <p>
              <strong>Cláusula 7ª</strong>. É dever da
              <strong> CONTRATADA </strong> fornecer ao
              <strong> CONTRATANTE </strong>a cópia do presente instrumento,
              quando solicitada, contendo todas as especificidades da prestação
              de serviço contratada.
            </p>
            <p>
              <strong>Cláusula 8ª</strong>. É dever da
              <strong> CONTRATADA </strong> verificar junto às operadoras as
              informações necessárias e agendar as atividades conforme
              solicitado pelo<strong> CONTRATANTE </strong>e repassá-las através
              de e-mail/contato telefônico.
            </p>
            <h5>DOS SERVIÇOS PRESTADOS</h5>
            <p>
              <strong>Cláusula 9ª</strong>. Estão inclusos os seguintes
              equipamentos e serviços necessários sem custo adicional ao
              cliente: cilindro, lastro, lanche (em saídas embarcadas) e
              instrutor exclusivo guiando toda a atividade (em caso de
              batismo/discovery) ou número de instrutores/divemaster compatível
              com o grupo em atividade (em caso de mergulhos credenciados).
              Quando se tratar de batismos, discovery e curso de open water,
              estão inclusos roupa, máscara, nadadeiras e colete equilibrador;
              para demais serviços há opção para aluguel de equipamentos quando
              o cliente necessitar, salvo os casos em que no serviço de mergulho
              informar como bônus o aluguel desses equipamentos.
            </p>
            <p>
              <strong>Parágrafo único: </strong>O uso de nitrox, trimix e
              quaisquer outros itens que não citados no objeto de compra do site
              poderá ser negociado diretamente com a operadora de mergulho, bem
              como a contratação de fotos e vídeos no mergulho (quando for o
              caso).
            </p>
            <p>
              <strong>Cláusula 10ª</strong>. A<strong> CONTRATADA </strong> se
              reserva no direito de suspender os serviços de mergulho
              contratados com devolução de valores pagos em até 48h caso não
              haja viabilidade ou segurança para a execução dos mesmos, deixando
              a cargo do
              <strong> CONTRATANTE </strong>decidir se poderá ser mantido
              crédito na operadora para utilização posterior.
            </p>
            <p>
              <strong>Parágrafo primeiro:</strong> O cancelamento se dará por
              avaliação somente do staff da operação em função de condições
              climáticas que impeçam navegação e/ou mergulho e coloquem em risco
              a embarcação e as pessoas envolvidas, ou por questões
              operacionais, considerando ainda a inviabilidade financeira pelo
              número mínimo de pessoas necessárias.
            </p>
            <p>
              <strong>Parágrafo segundo:</strong> Caso o
              <strong> CONTRATANTE </strong>deseje cancelar a atividade
              contratada, o valor somente será devolvido se o pedido ocorrer até
              72h antes da operação. Em um período menor a 72h, serão repassados
              20% do valor à operadora a título de cobertura de custos.
            </p>
            <p>
              <strong>Parágrafo terceiro:</strong> Caso o
              <strong> CONTRATANTE </strong>não se apresente no dia e horário
              previamente agendados para a atividade de mergulho (no-show), não
              haverá devolução de quaisquer valores.
            </p>
            <p>
              <strong>Cláusula 11ª</strong>. A<strong> CONTRATADA </strong> se
              reserva no direito de suspender os serviços de cursos contratados
              com devolução de valores pagos caso não haja viabilidade para a
              execução dos mesmos. Os valores deverão ser estornados
              integralmente em até 72h à <strong>CONTRATANTE</strong>.
            </p>
            <p>
              <strong>Parágrafo primeiro:</strong> Caso o cancelamento se dê
              pelos motivos acima descritos, o valor pago deverá ser estornado
              integralmente em até 72h à <strong>CONTRATANTE</strong>, ou usado
              como crédito no mesmo local caso a <strong>CONTRATANTE</strong>
              assim optar.
            </p>
            <p>
              <strong>Parágrafo segundo:</strong> Caso o
              <strong> CONTRATANTE </strong>deseje cancelar o curso contratado,
              o valor somente será devolvido se o pedido ocorrer até 7 (sete)
              dias corridos antes do início previsto (registro e aulas). Em um
              período menor a 7 (sete) dias corridos, serão repassados 10% do
              valor à operadora a título de cobertura de custos.
            </p>
            <p>
              <strong>Parágrafo terceiro:</strong> Caso o
              <strong> CONTRATANTE </strong>não se apresente no dia e horário
              previamente agendados para o curso e atividades do mesmo
              (no-show), não haverá devolução de quaisquer valores.
            </p>
            <h5>DO ARTIGO 49 DO CÓDIGO DE DEFESA DO CONSUMIDOR</h5>
            <p>
              <strong>Cláusula 12ª</strong>. Tendo em vista que os serviços de
              mergulho e os cursos/treinamentos possuem custos de operação
              prévia, como preparação de equipamento, alocação de staff, entre
              outras atividades imprescindíveis à boa execução dos serviços,
              qualquer cancelamento sem ônus com base no artigo 49 do Código de
              Defesa do Consumidor poderá ser realizado até:
              <ol>
                <li>
                  72 (setenta e duas) horas antes do horário previamente
                  agendado pela operadora, para atividades de mergulho;
                </li>
                <li>
                  7 (sete) dias corridos antes do horário previamente agendado
                  pela operadora, para cursos/treinamentos.
                </li>
              </ol>
            </p>
            <p>
              <strong>Parágrafo único: </strong>O Código de Defesa do Consumidor
              é aplicável quando o prazo limite do mesmo não estiver dentro do
              período de preparação informado acima, de modo que não cause
              prejuízo aos parceiros.
            </p>
            <h5>DA CONFIDENCIALIDADE</h5>
            <p>
              <strong>Cláusula 13ª</strong>. As partes comprometem-se a
              respeitar a privacidade e a proteção de Dados Pessoais conforme
              previsto nas leis e regulamentos aplicáveis para Dados Pessoais, e
              estar em conformidade com a Lei Geral de Proteção de Dados
              Pessoais (LGPD), Lei n° 13.709/2018.
            </p>
            <h5>DO FORO</h5>
            <p>
              <strong>Cláusula 14ª</strong>. As partes elegem de comum acordo, o
              foro da Comarca de Rio Grande/RS para dirimir quaisquer dúvidas ou
              controvérsias oriundas do presente instrumento, com exclusão de
              qualquer outro, por privilegiado que seja.
            </p>
            <p>Rio Grande, 11 de abril de 2023.</p>
            <hr></hr>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SalesContract;
