import React, { useEffect, useState } from "react";
import { DocumentData } from "firebase/firestore";
import { Container, Col, Row, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LoadingModal from "../components/Modal/LoadingModal";
import NavBar from "../components/NavBar/NavBar";
import { getUserLogs } from "../model/LogModel";
import { getUserById } from "../model/UserModel";
import { Log } from "../interfaces/interfaces";
import LogCard from "../components/DashBoard/LogCard";
import { Helmet } from "react-helmet";
import { auth } from "../config/firebase";

function DiverPage() {
  const { id } = useParams();
  const [diver, setDiver] = useState<DocumentData | undefined>();
  const [logs, setLogs] = useState<DocumentData | undefined>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<DocumentData | undefined>();

  async function fetchData() {
    if (!id) return;
    const user = await getUserById(id, setLoading);
    setDiver(user);
    const dives = await getUserLogs(id, "everyone");
    setLogs(dives);
  }

  useEffect(() => {
    fetchData();
    const currentUser = auth.currentUser;
    if (currentUser) setUser(currentUser);
  }, []);

  const logCards = () =>
    logs?.map((log: Log, i: number) => (
      <LogCard log={log} i={i} key={i}></LogCard>
    ));

  return (
    <>
      <NavBar></NavBar>
      <Helmet>
        <title>Página do Mergulhador - GDN</title>
        <meta
          name="description"
          content="Página destinada ao perfil do mergulhador, com suas informações e mergulhos registrados."
        />
      </Helmet>
      <Container>
        <Row>
          <Col lg={3}>
            <Card className="mt-3">
              <Card.Img variant="top" src={diver?.photoUrl}></Card.Img>
              <Card.Body className="text-center">
                <Card.Title>{diver?.displayName}</Card.Title>
                <Card.Subtitle>{diver?.home}</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>{logs && logs.length !== 0 ? logCards() : ""}</Col>
          <Col lg={3}></Col>
        </Row>
      </Container>

      <LoadingModal shouldShow={loading}></LoadingModal>
    </>
  );
}

export default DiverPage;
