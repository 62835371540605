import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
import NavBar from "../../components/NavBar/NavBar";
import { useNavigate } from "react-router-dom";

function DiveCenterSummary() {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <NavBar></NavBar>
      <Container>
        <Row className="mt-3">
          <Col md={6}>
            <Card>
              <Card.Body>
                <h2>{state.name}</h2>
                <small className="text-muted">
                  Criada por {state.createdBy.userName} em
                  {moment(state.createdAt).format(
                    " D [de] MMMM [de] yyyy [às] HH:MM:SS"
                  )}
                </small>
                <hr></hr>
                <p>
                  <strong>Endereço: </strong>
                  {state.address}
                </p>
                <p>
                  <strong>WhatsApp: </strong>
                  {state.phone}
                </p>
                <p>
                  <strong>Email: </strong>
                  {state.email}
                </p>
                <p>
                  <strong>Instagram: </strong>
                  {state.instagram}
                </p>
                <p>
                  <strong>Facebook: </strong>
                  {state.facebook}
                </p>
                <p>
                  <strong>Youtube: </strong>
                  {state.youtube}
                </p>
                <p>
                  <strong>TripAdvisor: </strong>
                  {state.tripAdvisor}
                </p>
                <hr></hr>
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    navigate("/dive-center/registration", { state })
                  }
                >
                  Editar
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            {state.image && (
              <Card>
                <Card.Body>
                  <Card.Img
                    variant="bottom"
                    src={state.image}
                    className="img-fluid mb-2"
                  />
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DiveCenterSummary;
