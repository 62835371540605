import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { Form } from "react-bootstrap";

function TextBoard({ initValue, label }: { initValue: string; label: string }) {
  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState(initValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    setValue(val);
  };

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + 3 + "px";
    }
  }, [value]);

  return (
    <>
      <Form.Text muted className="ms-2">
        {label}
      </Form.Text>
      <Form.Control
        as="textarea"
        value={value}
        ref={textRef}
        onChange={onChange}
        readOnly
        disabled
        className="text-justify bg-light no-resize"
      />
    </>
  );
}
export default TextBoard;
