import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { BsCalendar3, BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Course } from "../../interfaces/course";

function CourseLocationCard({ course }: { course: Course }) {
  const navigate = useNavigate();

  return (
    <Card className="bg-light border-light same-height-imgs h-100 shadow">
      <img
        src={course.locationPhoto}
        alt={course.location}
        className="rounded-2 card-img-top"
      />
      <strong className="mx-2 fs-5">{course.location}</strong>
      <Card.Body>
        <div className="text-center mt-3">
          <img
            src={`/images/logos/${course.certifiedBy.toLowerCase()}.png`}
            height="50px"
            alt={course.certifiedBy}
          />
          <div className="fw-bold fs-4">{course.name}</div>
        </div>
        <small className="m-1">Inclui:</small>
        <ListGroup variant="flush" className="rounded-2">
          <ListGroup.Item>
            <BsCheck size="25" />
            Todo o equipamento necessário
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Todo o material teórico de estudo
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Aulas teóricas de mergulho
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Aulas práticas em piscina
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Mergulhos no oceano
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Navegação até os pontos de mergulho
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Comida e bebida na embarcação
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Certificado de mergulhador*
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Carteira de mergulhador*
          </ListGroup.Item>
          <ListGroup.Item>
            <BsCheck size="25" />
            Habilição vitalícia*
          </ListGroup.Item>
        </ListGroup>

        <div className="text-center my-3">
          <div className="display-3 mb-3">
            <small className="fs-5">R$</small>
            {course.reservationPrice}
            <small className="text-muted fs-5">,00</small>
            <div className="small text-muted fs-6">
              para fazer uma reserva <br />
              investimento total&#185; <strong>R${course.price},00</strong>
            </div>
          </div>
          <Button
            size="lg"
            variant="outline-dark"
            onClick={() => {
              navigate(`/course/${course.id}`);
            }}
          >
            <BsCalendar3 size="30" />
            <span className="ms-2">verifique a disponibilidade</span>
          </Button>
        </div>
        <span className="small text-muted">
          &#185; Oferecemos diversas opções de pagamento
          <br />
          *Ao concluir o curso com sucesso e ser aprovado
        </span>
      </Card.Body>
    </Card>
  );
}

export default CourseLocationCard;
