import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import NavBar from "../components/NavBar/NavBar";
import { some } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getLog } from "../model/LogModel";
import LoadingModal from "../components/Modal/LoadingModal";
import { Log } from "../interfaces/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import ValidateLogBtn from "../components/LogValidation/ValidateLogBtn";
import { Helmet } from "react-helmet";
import { auth } from "../config/firebase";

function ValidateLog() {
  const [log, setLog] = useState<Log>();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  moment().locale("pt");

  async function fetchLog() {
    if (id) {
      const logData = await getLog(id, setLoading);
      if (logData) setLog(logData);
    }
  }

  useEffect(() => {
    if (!auth.currentUser) navigate("/login");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchLog();
  }, []);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser?.uid === log?.userId && log?.logId) {
      alert(t("cant_validate_own_log"));
      navigate(`/log/${log?.logId}`);
      return;
    }
    if (some(log?.validatedBy, { id: currentUser?.uid })) {
      alert(t("dive_already_validated"));
      navigate(`/log/${log?.logId}`);
      return;
    }
  }, [log]);

  return (
    <>
      <NavBar></NavBar>
      <Container className="my-3">
        <Helmet>
          <title>Validação de mergulho - GDN</title>
          <meta
            name="description"
            content="Esta página possibilita a duplas de mergulho, dive masters e instrutores de mergulho, validarem os mergulhos dos quais participaram."
          />
        </Helmet>
        <Row>
          <Col xl={2}></Col>
          <Col xl={8}>
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={12} className="m-1">
                    <h4>{`${t("validate_dive_from")} ${
                      log?.userName || ""
                    }`}</h4>
                    <hr></hr>
                    <Row>
                      <Col>
                        <dl className="row">
                          <dt className="col-sm-3">
                            {t("date")}
                            <span className="text-danger">*</span>
                          </dt>
                          <dd className="col-sm-9">{log?.date}</dd>

                          <dt className="col-sm-3">
                            {t("location")}
                            <span className="text-danger">*</span>
                          </dt>
                          <dd className="col-sm-9">{log?.location}</dd>

                          <dt className="col-sm-3">
                            {t("dive_site")}
                            <span className="text-danger">*</span>
                          </dt>
                          <dd className="col-sm-9">{log?.diveSite}</dd>

                          <dt className="col-sm-3">
                            {t("max_depth")}{" "}
                            <span className="text-danger">*</span>
                          </dt>
                          <dd className="col-sm-9 mb-0">
                            {log?.maximumDepth
                              ? log?.maximumDepth + "m"
                              : t("not_informed")}
                          </dd>

                          <span className="form-text mb-1">
                            <span className="text-danger">* </span>
                            {t("required_info")}
                          </span>
                          <hr></hr>

                          <dt className="col-sm-3">{t("average_depth")}</dt>
                          <dd className="col-sm-9">
                            {log?.averageDepth
                              ? log?.averageDepth + "m"
                              : t("not_informed")}
                          </dd>

                          <dt className="col-sm-3">{t("time")}</dt>
                          <dd className="col-sm-9">
                            {log?.diveTime
                              ? log?.diveTime + "min"
                              : t("not_informed")}
                          </dd>

                          <dt className="col-sm-3">{t("deco_time")}</dt>
                          <dd className="col-sm-9">
                            {log?.noDecoTime
                              ? log?.noDecoTime + "min"
                              : t("not_informed")}
                          </dd>

                          <dt className="col-sm-3">{t("temperature")}</dt>
                          <dd className="col-sm-9">
                            {log?.temperature
                              ? log?.temperature + "°C"
                              : t("not_informed")}
                          </dd>

                          <dt className="col-sm-3">{t("water_temperature")}</dt>
                          <dd className="col-sm-9">
                            {log?.waterTemperature
                              ? log?.waterTemperature + "°C"
                              : t("not_informed")}
                          </dd>

                          <dt className="col-sm-3">{t("visibility")}</dt>
                          <dd className="col-sm-9">
                            {log?.visibility
                              ? log?.visibility + "m"
                              : t("not_informed")}
                          </dd>
                        </dl>
                      </Col>
                    </Row>
                    <Row>{log && <ValidateLogBtn log={log} />}</Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Container>

      <LoadingModal shouldShow={loading}></LoadingModal>
    </>
  );
}

export default ValidateLog;
