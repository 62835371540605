import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Log, Size } from "../../interfaces/interfaces";

function EditLogBtn({
  log,
  className,
  size,
  iconSize,
}: {
  log: Log;
  className?: string;
  iconSize?: string;
  size?: Size;
}) {
  const { t } = useTranslation();

  return (
    <Link className="no-style-link" to="/logger" state={{ log: log }}>
      <Button
        variant="outline-dark"
        className={className}
        size={size ? size : undefined}
      >
        <FiEdit size={iconSize ? iconSize : "30"} className="mx-1" />{" "}
        {t("edit") + " " + t("dive").toLocaleLowerCase()}
      </Button>
    </Link>
  );
}

export default EditLogBtn;
