import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { filter } from "lodash";
import { COURSES } from "../../data/courses";
import { Course } from "../../interfaces/course";
import CourseCard from "../../components/Cards/CourseCard";
import CourseBookingInfo from "../../components/BookingInfo/CourseBookingInfo";

function CoursesIn() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [courses, setCourses] = useState<Array<Course> | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const coursesList = filter(COURSES, (c) => c.locationId === id);
    if (!coursesList || coursesList.length < 1) {
      navigate("/");
      return;
    }
    setCourses(coursesList);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [courses]);

  return (
    <>
      <Helmet>
        <title>
          {courses && courses[0].location
            ? `Cursos de mergulho em ${courses[0]?.location} - GDN`
            : `Cursos de mergulho - GDN`}
        </title>
        <meta
          name="description"
          content={
            courses && courses[0].location
              ? `Cursos de mergulho em ${courses[0]?.location}`
              : "Mergulhe com a GDN"
          }
        />
      </Helmet>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/where-we-are" className="no-style-link">
              {" "}
              {t("where_we_are")}
            </Link>{" "}
            /{" "}
            <Link to={`/where-we-are/${id}`} className="no-style-link">
              {courses && courses[0].location ? `${courses[0].location}` : ""}
            </Link>{" "}
            / Cursos
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              Cursos de mergulho em{" "}
              {courses && courses[0].location ? `${courses[0].location}` : ""}
            </h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={2} xl={3} className="gx-4 gy-3 mb-5">
          {courses?.map((c, i) => (
            <Col>
              <CourseCard course={c} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xl={12}>
            <CourseBookingInfo />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default CoursesIn;
