import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import NavBar from "../components/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import {
  getDocs,
  collection,
  query,
  limit,
  orderBy,
  startAfter,
  QueryDocumentSnapshot,
  DocumentData,
  where,
} from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { concat, sortBy } from "lodash";
import LogCard from "../components/DashBoard/LogCard";
import LoadingModal from "../components/Modal/LoadingModal";
import ErrorModal from "../components/Modal/ErrorModal";
import InfiniteScroll from "react-infinite-scroller";
import { Log } from "../interfaces/interfaces";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import { Helmet } from "react-helmet";
import { TbBookUpload } from "react-icons/tb";
import { t } from "i18next";
import moment from "moment";
import "moment/locale/pt";

function Dashboard() {
  moment().locale("pt");
  const [logs, setLogs] = useState<any | null>(null);
  const [usr, setUsr] = useState<any>();
  const [initialLoading, setInitialLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const navigate = useNavigate();

  async function fetchNext() {
    try {
      if (!lastVisible) {
        setHasMore(false);
        setInitialLoading(false);
        return;
      }

      const next = query(
        collection(db, "logbook"),
        where("logVisibility", "!=", "deleted"),
        orderBy("logVisibility"),
        orderBy("created", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const documentSnapshot = await getDocs(next);

      const lastVisibleDoc =
        documentSnapshot.docs[documentSnapshot.docs.length - 1];

      setLastVisible(lastVisibleDoc);

      const rawDocs = documentSnapshot.docs;
      const logsList = rawDocs.map((d) => d.data());
      const logsSorted = sortBy(logsList, ["created"]).reverse();

      const concatLogs = concat(logs, logsSorted);
      setLogs(concatLogs);
    } catch (err) {
      setError(true);
    } finally {
    }
  }

  const fetchLogbook = async () => {
    try {
      setInitialLoading(true);

      const first = query(
        collection(db, "logbook"),
        where("logVisibility", "!=", "deleted"),
        orderBy("logVisibility"),
        orderBy("created", "desc"),
        limit(5)
      );
      const documentSnapshots = await getDocs(first);

      const lastVisibleDoc =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setLastVisible(lastVisibleDoc);

      const rawDocs = documentSnapshots.docs;
      const logsList = rawDocs.map((d) => d.data());
      const logsSorted = sortBy(logsList, ["created"]).reverse();
      setLogs(logsSorted);
    } catch (err) {
      setError(true);
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate("/login");
      return;
    }
    setUsr(user);
    fetchLogbook();
  }, []);

  const logCards = () =>
    logs.map((log: Log, i: number) => (
      <LogCard log={log} i={i} key={i}></LogCard>
    ));

  return (
    <div>
      <NavBar></NavBar>
      <Container>
        <Row>
          <CookieConsent></CookieConsent>
          <Helmet>
            <title>GDN - Mergulhos em destaque</title>
            <meta
              name="description"
              content="Página principal da rede social para mergulhadores Global Divers Network onde são mostrados os mergulhos mais destacados e recentes."
            />
          </Helmet>
          <Col lg={3} className="mt-3"></Col>
          <Col lg={6} className="mx-2">
            <div className="card mt-3 text-center">
              <div className="card-body">
                <h5 className="card-title">Logbook GDN </h5>
                <p className="card-text">
                  Crie seu logbook com fotos, videos e compartilhe seus
                  mergulhos.
                </p>
                <Button variant="danger" onClick={() => navigate("/logger")}>
                  <TbBookUpload size="35" className="me-1" /> Registrar mergulho
                </Button>
              </div>
            </div>

            {logs ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchNext}
                hasMore={hasMore}
                loader={
                  <div className="text-center my-2" key={0}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                {logCards()}
              </InfiniteScroll>
            ) : (
              ""
            )}
            {hasMore ? (
              ""
            ) : (
              <div className="mt-3 text-center">
                {t("nothing_else_to_load")}
              </div>
            )}
          </Col>
          <Col lg={3}></Col>
        </Row>
      </Container>

      <LoadingModal shouldShow={initialLoading}></LoadingModal>
      <ErrorModal shouldShow={error} redirectTo="/404"></ErrorModal>
    </div>
  );
}

export default Dashboard;
