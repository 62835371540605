import React from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { auth } from "../../config/firebase";
import { logout } from "../../authentication/Logout";
import { BsGlobe } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import "./navbar.css";

function NavBar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Navbar
      collapseOnSelect
      className="border border-bottom fixed-top bg-white-opacity gdn"
      expand="lg"
    >
      <Container>
        <Navbar.Brand className="text-danger gdn">
          <Link className="no-style-link" to="/">
            <BsGlobe
              size={45}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            />
            <span className="fs-5 ms-1">GDN</span>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end gdn">
          <Nav>
            <NavDropdown
              title={<span className="text-danger fs-5">Mergulhe conosco</span>}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={NavLink} to="/where-we-are/buzios-RJ/dive">
                Búzios
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/ilhabela-SP/dive"
              >
                Ilhabela
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/saoJoseDaCoroaGrande-PE/dive"
              >
                São José da Coroa Grande
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/portoSeguro-BA/dive"
              >
                Porto Seguro
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to="/dive/baptism">
                Batismo de mergulho
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={
                <span className="text-danger fs-5">Cursos e treinamentos</span>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/buzios-RJ/courses"
              >
                Búzios
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/ilhabela-SP/courses"
              >
                Ilhabela
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/saoJoseDaCoroaGrande-PE/courses"
              >
                São José da Coroa Grande
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/where-we-are/portoSeguro-BA/courses"
              >
                Porto Seguro
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              id="hamburguer"
              title={<HiMenu />}
              align="end"
              className="fs-5"
            >
              {auth.currentUser ? (
                <>
                  <NavDropdown.Item
                    onClick={() => navigate("/manage-profile/edit-profile")}
                  >
                    {t("profile")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => {
                      logout();
                    }}
                  >
                    {t("signoff")}
                  </NavDropdown.Item>
                </>
              ) : (
                <NavDropdown.Item onClick={() => navigate("/register")}>
                  {t("create_account")}
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
