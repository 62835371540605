import React from "react";
import { Row, Col } from "react-bootstrap";
import Tags from "../Tags/Tags";
import { DIVER_TAGS } from "../Tags/data";
import { useUser } from "../../pages/ManageProfile";

function DiverProfile() {
  const { user } = useUser();

  return (
    <>
      <Row>
        <Col lg={12}>
          <h1 className="display-6 fs-2">Diver Profile</h1>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Tags tagList={DIVER_TAGS} checkedTags={user.tags}></Tags>
        </Col>
      </Row>
    </>
  );
}
export default DiverProfile;
