import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { LOCATIONS } from "../../data/locations";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LocationInterface } from "../../interfaces/location";
import { filter, slice } from "lodash";
import { DIVELIST } from "../../data/dives";
import { Dive } from "../../interfaces/dive";
import DiveCard from "../../components/Cards/DiveCard";
import { COURSES } from "../../data/courses";
import { Course } from "../../interfaces/course";
import CourseCardSm from "../../components/Cards/CourseCardSm";
import { Experience } from "../../interfaces/experience";
import { EXPERIENCES } from "../../data/experiences";
import ExperienceCard from "../../components/Cards/ExperienceCard";

function Location() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [place, setPlace] = useState<LocationInterface | null>(null);
  const [dives, setDives] = useState<Array<Dive> | null>(null);
  const [courses, setCourses] = useState<Array<Course> | null>(null);
  const [experiences, setExperiences] = useState<Array<Experience> | null>(
    null
  );
  const lang = i18next.language === "pt" ? "pt" : "en";
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const placeExists = LOCATIONS.some((p) => p.id === id);
    if (!placeExists) navigate("/where-we-are");
    setPlace(LOCATIONS.filter((p) => p.id === id)[0]);
    const diveList = slice(
      filter(DIVELIST, (d) => d.locationId === id),
      0,
      3
    );
    setDives(diveList);
    const coursesList = slice(
      filter(COURSES, (c) => c.locationId === id),
      0,
      3
    );
    setCourses(coursesList);
    const experiencesList = slice(
      filter(EXPERIENCES, (x) => x.locationId === id),
      0,
      3
    );
    if (experiencesList) setExperiences(experiencesList);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("dive_in") + " " + place?.name + " - GDN"}</title>
        <meta
          name="description"
          content={`Faça seu mergulho de batismo, agende mergulhos como credenciado, passeios de barco e snorkel em ${place?.name}`}
        />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/where-we-are" className="no-style-link">
              {" "}
              {t("where_we_are")}
            </Link>{" "}
            / {place?.name}
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{place?.fullName}</h3>
          </Col>
        </Row>
        <Row>
          <Col className="mb-2">
            <Card className="shadow">
              <Card.Img
                src={place?.img}
                variant="top"
                className="rounded-2 img-cover"
              />
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h3>Mergulhe em {place?.name}</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3">
          {dives?.map((d, i) => (
            <Col>
              <DiveCard dive={d} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <Button
              size="lg"
              variant="danger"
              className="w-100"
              onClick={() => {
                navigate(`/where-we-are/${place?.id}/dive`);
              }}
            >
              Veja todas as opções de mergulho em {place?.name}
            </Button>
          </Col>
        </Row>
        <hr className="my-5" />
        <Row className="mb-2">
          <Col>
            <h3>Cursos de mergulho oferecidos em {place?.name}</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3">
          {courses?.map((c, i) => (
            <Col>
              <CourseCardSm course={c} />
            </Col>
          ))}
        </Row>
        {courses && courses?.length === 3 ? (
          <Row>
            <Col className="text-center mt-4">
              <Button
                size="lg"
                className="w-100"
                onClick={() => {
                  navigate(`/where-we-are/${place?.id}/courses`);
                }}
              >
                Veja outros cursos de mergulho em {place?.name}
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}

        {experiences && experiences?.length > 0 && (
          <>
            <hr className="my-5" />
            <Row className="mb-2">
              <Col>
                <h3>Outras experiências em {place?.name}</h3>
              </Col>
            </Row>
            <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3">
              {experiences?.map((x, i) => (
                <Col>{<ExperienceCard experience={x} />}</Col>
              ))}
            </Row>
          </>
        )}

        <hr className="my-5" />

        <Row>
          <Col>
            <Card className="p-3 text-box">
              <Card.Body>
                <h5>Conheça {place?.name}</h5>
                <Card.Text>{place?.description[lang]}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default Location;
