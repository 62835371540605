import React, { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

function PlacesInput({
  className,
  name,
  state,
  setState,
  types,
  placeholder,
}: {
  className?: string;
  name: string;
  state: Object;
  setState: Function;
  types?: Array<string>;
  placeholder?: string;
}) {
  const autoCompleteRef = useRef<any>();
  const inputRef = useRef<any>();
  const options = {
    fields: ["formatted_address", "geometry"],
    strictBounds: false,
    types: types
      ? types
      : [
          "locality",
          "sublocality",
          "colloquial_area",
          "natural_feature",
          "archipelago",
        ],
  };

  const loader = new Loader({
    //@ts-ignore
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  useEffect(() => {
    loader
      .load()
      .then((google) => {
        //@ts-ignore
        autoCompleteRef.current = new google.maps.places.Autocomplete(
          inputRef.current,
          options
        );
        autoCompleteRef.current.addListener("place_changed", () => {
          const place = autoCompleteRef.current.getPlace();
          setState({
            ...state,
            [name]: place?.formatted_address,
            latLng: place.geometry?.location?.toUrlValue() || "",
          });
        });
      })
      .catch((e) => {});
  }, []);

  function handleChange(e: any) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      latLng: "",
    });
  }

  return (
    <input
      type="text"
      ref={inputRef}
      className={className}
      //@ts-ignore
      value={state[name]}
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      required
    />
  );
}

export default PlacesInput;
