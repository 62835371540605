import React, { useState } from "react";
import { Col, Row, Card, Badge } from "react-bootstrap";
import { values } from "lodash";
import { GiClownfish } from "react-icons/gi";
import { HiBadgeCheck } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAuth } from "firebase/auth";
import { Log } from "../../interfaces/interfaces";
import LikeButton from "../Likes/LikeButton";
import LikeCounter from "../Likes/LikeCounter";
import CommentsAccordion from "../Comments/CommentsAccordion";
import ShareLogSmBtn from "../Buttons/ShareLogSmBtn";
import moment from "moment";
import TextBoard from "../TextBoard/TextBoard";

function LogCard({ log, i }: { log: Log; i: number }) {
  const [likesState, setLikesState] = useState(log.likes || []);
  const { t } = useTranslation();

  return (
    <Row className="mt-3" key={i}>
      <Col lg={12} className="px-0">
        <Card className="mb-3 pb-3">
          <Card.Body>
            <Row>
              <Col xs={2}>
                <Link
                  className="no-style-link"
                  to={{ pathname: `/diver/${log.userId}` }}
                >
                  {getAuth().currentUser?.photoURL ? (
                    <img
                      src={log.userPicture}
                      className="circle-cropper border border-light"
                      width="65px"
                      height="65px"
                      alt=""
                    />
                  ) : (
                    <GiClownfish
                      size={45}
                      className="bg-light rounded-circle text-danger border border-dark border-2"
                    />
                  )}
                </Link>
              </Col>
              <Col className="text-align-left px-2" xs={8}>
                <Link
                  className="no-style-link"
                  to={{ pathname: `/diver/${log.userId}` }}
                >
                  <span className="text-align-left text-wrap">
                    <strong className="align-top mx-2">{log.userName}</strong>
                  </span>
                </Link>
                <small className="text-muted">
                  {moment(log.date).fromNow()}
                </small>
                <Card.Text className="text-align-left">
                  <Link
                    className="no-style-link"
                    to={{ pathname: `/log/${log.logId}` }}
                  >
                    <strong className="ms-2 me-2 fs-4 text-wrap">
                      {log.title}
                    </strong>
                  </Link>

                  <div className="text-muted mx-2 fs-6 text-wrap">
                    {log.diveSite}
                    {log.diveSite && log.location ? "," : ""} {log.location}{" "}
                  </div>
                </Card.Text>
              </Col>
              <Col xs={2} className="text-end px-3">
                {log.validatedBy?.length > 0 && (
                  <div>
                    <HiBadgeCheck size="30" className="text-success" />
                  </div>
                )}

                {log.userId === getAuth().currentUser?.uid ? (
                  ""
                ) : (
                  <div>
                    <LikeButton
                      resourceId={log.logId}
                      resourceUserId={log.userId}
                      resourceLikes={likesState}
                      setLikesState={setLikesState}
                    ></LikeButton>
                  </div>
                )}
                <div>
                  <ShareLogSmBtn resource={log}></ShareLogSmBtn>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {values(log.tags).map((tag, i) => (
                  <Badge key={i} bg="warning" text="dark" className="fs-6 m-1">
                    {t(tag)}
                  </Badge>
                ))}
              </Col>
            </Row>
          </Card.Body>
          {log.video?.length > 0 ? (
            <iframe
              width="100%"
              height="420"
              src={`https://www.youtube.com/embed/${log.video}?modestbranding=1&showinfo=0&autoplay=1&loop=1&playlist=${log.video}&mute=1`}
              title="YouTube video player"
              frameBorder="0"
              data-allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <Link
              className="no-style-link"
              to={{ pathname: `/log/${log.logId}` }}
            >
              <Card.Img
                variant="bottom"
                src={log.photo}
                className="img-fluid mb-2"
              />
            </Link>
          )}
          {log?.notes && (
            <Row>
              <Col className="mx-3">
                <TextBoard initValue={log.notes} label={t("notes")}></TextBoard>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="ms-3">
              <div className="position-absolute left-0 z-index-1">
                {likesState && <LikeCounter likes={likesState}></LikeCounter>}
              </div>

              <CommentsAccordion
                logId={log.logId}
                comments={log.comments || []}
                opened={false}
              ></CommentsAccordion>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default LogCard;
