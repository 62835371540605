import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar/NavBar";
import { updateProfile } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { auth, db } from "../config/firebase";
import SuccessModal from "../components/Modal/SuccessModal";
import ErrorModal from "../components/Modal/ErrorModal";
import LoadingModal from "../components/Modal/LoadingModal";
import { isNil, omitBy, values } from "lodash";
import { DIVER_TAGS } from "../components/Tags/data";
import ProfileMenu from "../components/ManageProfile/ProfileMenu";
import { Helmet } from "react-helmet";

type ContextType = {
  user: any | null;
  setUser: any | null;
  handleChange: any;
  photo: any;
  setPhoto: any;
};

function ManageProfile() {
  const [user, setUser] = useState<any | null>(null);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const photoInitalState = user ? user.photo : "";
  const [photo, setPhoto] = useState(photoInitalState);

  async function fetchUser(uid: string) {
    try {
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      setUser(docSnap.data());
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  useEffect(() => {
    const authUser = auth.currentUser;
    if (!authUser) {
      navigate("/login");
      return;
    }
    fetchUser(authUser.uid);
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setUser({
      ...user,
      [(e.target as HTMLInputElement).name]: value,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }

    setIsLoading(true);

    const checkedTags = () => {
      const firstEl = DIVER_TAGS[0];
      const element = document.getElementById(firstEl) as HTMLInputElement;
      if (!element) return;
      const res = values(
        omitBy(
          DIVER_TAGS.map((t) =>
            (document.getElementById(t) as HTMLInputElement).checked
              ? (document.getElementById(t) as HTMLInputElement).value
              : null
          ),
          isNil
        )
      );
      return res;
    };

    try {
      const currentUser: any = auth.currentUser;
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        ...user,
        photoUrl: photo || user.photoUrl || "",
      });
      await updateProfile(currentUser, {
        photoURL: photo || user.photoUrl,
        displayName: user.displayName,
      });
      setSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <NavBar></NavBar>
      <Container>
        <Helmet>
          <title>Gerenciamento de Perfil - GDN</title>
          <meta
            name="description"
            content="Página dedicada ao gerencimento do perfil de usuário dentro da rede social de mergulho Global Divers Network."
          />
        </Helmet>
        <Row className="my-4">
          <Col lg={3} className="mb-4">
            {<ProfileMenu></ProfileMenu>}
          </Col>
          <Col lg={6}>
            <Container className="p-5 border rounded-2">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Outlet context={{ user, setUser, handleChange, setPhoto }} />
              </Form>
            </Container>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>

      <SuccessModal shouldShow={success}></SuccessModal>
      <ErrorModal shouldShow={error} redirectTo="/"></ErrorModal>
      <LoadingModal shouldShow={isLoading}></LoadingModal>
    </>
  );
}

export function useUser() {
  return useOutletContext<ContextType>();
}

export default ManageProfile;
