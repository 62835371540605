import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsWhatsapp } from "react-icons/bs";
import { Log, Size } from "../../interfaces/interfaces";
import moment from "moment";

function WhatsAppBtn({
  variant,
  resource,
  label,
  size,
  iconSize,
  className,
}: {
  variant: string;
  resource: Log;
  label?: string;
  size?: Size;
  iconSize?: string;
  className?: string;
}) {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  function formatMessage() {
    if (variant === "validation") {
      const url = `https://globaldiversnetwork.com/?id=${resource.logId}%26redirect-to=validate-log`;
      const date = moment(resource.date).format("DD/MM/yyyy ");
      const text =
        t("validate_dive_on") +
        date +
        t("at_dive_site") +
        resource.diveSite +
        "? " +
        t("here_is_the_link") +
        url +
        " ." +
        t("thanks") +
        "!";
      const formattedText = text.replace(/\s/g, "%20");
      setMessage(formattedText);
      return;
    }

    if (variant === "share-log") {
      const url = `https://globaldiversnetwork.com/?redirect-to=log%26id=${resource.logId}`;
      const text = t("check_out_dive") + url;
      const formattedText = text.replace(/\s/g, "%20");
      setMessage(formattedText);
      return;
    }
  }

  useEffect(() => {
    formatMessage();
  }, []);

  return (
    <a href={`https://wa.me/?text=${message}`} target="_blank" rel="noreferrer">
      <Button
        size={size ? size : "lg"}
        variant="success"
        className={`w-100 ${className}`}
      >
        <strong className="me-1">
          <BsWhatsapp size={iconSize ? iconSize : "35"} className="ms-1 me-3" />
          {label ? label : "WhatsApp"}
        </strong>
      </Button>
    </a>
  );
}

export default WhatsAppBtn;
