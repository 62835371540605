import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../config/firebase";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { auth } from "../../config/firebase";
import {
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  DocumentData,
} from "firebase/firestore";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";

function MpCheckout() {
  const createMpPreference = httpsCallable(functions, "createMpPreference");
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const preferenceId = searchParams.get("preference_id");
  const [updating, setUpdating] = useState(true);
  const [order, setOrder] = useState<DocumentData | null>(null);
  const navigate = useNavigate();

  async function createOrder(orderInfo: any) {
    try {
      await setDoc(doc(db, "orders", orderInfo.id), orderInfo);
      window.location.replace(orderInfo.preference.init_point);
    } catch (err) {
      navigate("/404");
    }
  }

  useEffect(() => {
    if (!state) return;
    createMpPreference(state)
      .then((result: any) => {
        const preferenceRes = result.data.preference;
        const orderInfo = {
          id: preferenceRes.id,
          userId: auth.currentUser?.uid,
          status: "open",
          lastChange: serverTimestamp(),
          paymentGateway: "MercadoPago",
          productInfo: state.productInfo,
          preference: {
            id: preferenceRes.id,
            collector_id: preferenceRes.collector_id,
            date_created: preferenceRes.date_created,
            external_reference: preferenceRes.id,
            init_point: preferenceRes.init_point,
          },
        };
        createOrder(orderInfo);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, []);

  async function fetchOrder(preferenceId: string) {
    try {
      setUpdating(true);
      const orderRef = doc(db, "orders", preferenceId);
      const orderSnap = await getDoc(orderRef);
      if (orderSnap.exists()) setOrder(orderSnap.data());
    } catch (err) {
      navigate("/404");
    } finally {
      setUpdating(false);
    }
  }

  useEffect(() => {
    if (!status) return;
    if (!preferenceId) return;
    fetchOrder(preferenceId);
  }, [status, preferenceId]);

  return (
    <>
      {!status && (
        <Container>
          <div className="text-center">
            <Spinner animation="border" variant="danger" />
            <div className="mt-1">
              Você será redirecionado para o MercadoPago
              <br />
              para efetuar o pagamento com total segurança...
            </div>
          </div>
        </Container>
      )}
      {status && (
        <>
          <NavBar />
          <Container className="mt-0 p-3">
            <Row>
              <Col className="small text-mutted fw-lighter">
                <Link to="/" className="no-style-link">
                  {" "}
                  voltar ao início
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Status do pedido:</h3>
              </Col>
            </Row>
            {updating ? (
              <Row>
                <Col>
                  <div className="text-center">
                    <Spinner animation="border" variant="danger" />
                    <div className="mt-1">Atualizando seu pedido...</div>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                <Row className="mb-2">
                  <Col lg={6}>
                    {order && (
                      <img
                        src={order.productInfo.img}
                        alt={order.productInfo.title}
                        className="img-fluid rounded-2 shadow"
                      />
                    )}
                  </Col>
                  <Col lg={6}>
                    <Card className="p-3 bg-light shadow h-100">
                      <Card.Body>
                        <Card.Title className="mb-3">
                          {status === "approved" && <h1>Pagamento aprovado</h1>}
                          {status === "rejected" && <h1>Pagamento recusado</h1>}
                          {status === "in_process" && (
                            <h1>Pagamento sendo processado</h1>
                          )}
                          {status === "pending" && <h1>Pagamento pendente</h1>}
                          {status === "null" && <h1>Erro no pagamento</h1>}
                        </Card.Title>
                        <Card.Subtitle className="mb-5">
                          {status === "approved" && (
                            <span>
                              Seu pagamento foi aprovado. Obrigado!
                              <br />
                              Em breve, você receberá instrucões para concluir o
                              processo de agendamento e verificação de
                              credenciais.
                            </span>
                          )}
                          {status === "rejected" && (
                            <span>
                              O pagamento foi rejeitado e por este motivo seu
                              pedido não foi aprovado.
                            </span>
                          )}
                          {status === "rejected" && order && (
                            <span>
                              <br />
                              Clique{" "}
                              <a href={order.preference.init_point}>
                                aqui
                              </a>{" "}
                              para tentar novamente.
                            </span>
                          )}
                          {status === "in_process" && (
                            <span>
                              O pagamento do seu pedido está sendo processado e
                              não é necessário nenhuma ação adicional neste
                              momento. <br />
                              Em breve, você receberá um email com todos os
                              detalhes do seu pedido atualizado e instruções
                              para concluir o agendamento. Obrigado.
                            </span>
                          )}
                          {status === "pending" && (
                            <span>
                              O pagamento do seu pedido está pendente. Por
                              favor, conclua o pagamento para confirmar seu
                              pedido. <br />
                              Você receberá um email com todos os detalhes do
                              pedido atualizados ao concluir o pagamento.
                              Obrigado.
                            </span>
                          )}
                          {status === "null" && (
                            <span>
                              Houve um erro no momento do pagamento e não foi
                              possível realizar seu pedido.
                              <br />
                              Clique{" "}
                              <a href={order?.preference.init_point}>
                                aqui
                              </a>{" "}
                              para tentar novamente.
                            </span>
                          )}
                        </Card.Subtitle>

                        {order && (
                          <>
                            <h5>Detalhes do pedido:</h5>
                            <p className="text-box">
                              {order.productInfo.productDescription.replaceAll(
                                ".",
                                "\n"
                              )}
                            </p>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </Container>
          <Footer />
        </>
      )}
    </>
  );
}

export default MpCheckout;
