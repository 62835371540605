import React, { ChangeEventHandler, useEffect } from "react";
import { Row, Col, FloatingLabel, Form } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import { LoggerProps } from "../../interfaces/interfaces";
import PlacesInput from "../Autocomplete/PlacesInput";

function LoggerBasicForm({
  handleChange,
  state,
  setState,
}: {
  handleChange: ChangeEventHandler;
  state: LoggerProps;
  setState: Function;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const dateElement = document.getElementById(
      "dateInput"
    ) as HTMLInputElement;
    if (dateElement) dateElement.value = moment().format("YYYY-MM-DD");
  }, []);

  return (
    <>
      <Row>
        <Col md={6}>
          <FloatingLabel label={t("title")}>
            <Form.Control
              type="text"
              className="mt-2"
              name="title"
              value={state.title}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col md={6}>
          <FloatingLabel label={t("location")}>
            <PlacesInput
              className="form-control form-control-lg mt-2"
              name="location"
              state={state}
              setState={setState}
            ></PlacesInput>
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FloatingLabel label={t("dive_site")}>
            <Form.Control
              type="text"
              className="mt-2"
              name="diveSite"
              value={state.diveSite}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel label={t("date")}>
            <Form.Control
              id="dateInput"
              type="date"
              className="mt-2"
              name="date"
              value={state.date}
              onChange={handleChange}
              max={new Date().toISOString().split("T")[0]}
              required
            />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel label={t("max_depth") + " (m)"}>
            <Form.Control
              min={0}
              max={9999}
              type="number"
              className="mt-2"
              name="maximumDepth"
              value={state.maximumDepth}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel label={t("dive_time") + " (min)"}>
            <Form.Control
              min={0}
              type="number"
              className="mt-2"
              name="diveTime"
              value={state.diveTime}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}

export default LoggerBasicForm;
