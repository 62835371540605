import React, { useEffect, useState } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Experience } from "../../interfaces/experience";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { MAX_BOOKING_ADVANCE } from "../../data/dives";
import { useTranslation } from "react-i18next";
import { isNil, sum, sumBy, toString } from "lodash";
import TicketsCounter from "./TicketsCounter";
import { BookableTicket } from "../../interfaces/ticket";
import { MAX_TICKETS_ON_SALE } from "../../config/constants";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function TicketsBooker({ experience }: { experience: Experience }) {
  const lang = i18next.language === "pt" ? "pt" : "en";
  const minDaysInAdvance = 3;
  const minDate = new Date(Date.now() + minDaysInAdvance * 24 * 60 * 60 * 1000);
  const [date, setDate] = useState<Date | null>(minDate);
  const { t } = useTranslation(["ticketsBooker"]);
  const [tickets, setTickets] = useState<Array<BookableTicket> | null>();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const initialTicketsState = experience.tickets.map((t) => ({
      type: t.type[lang],
      price: t.price.value,
      quantity: 0,
    }));
    setTickets(initialTicketsState);
  }, [lang]);

  useEffect(() => {
    setTotal(sum(tickets?.map((t) => t.price * t.quantity)));
  }, [tickets]);

  const handleSubmit = () => {
    const people =
      toString(
        tickets
          ?.map((t) => {
            if (t.quantity > 0) {
              return ` ${t.quantity}x ${t.type}`;
            }
            return null;
          })
          .filter((x) => !isNil(x))
      ) + ".";

    const productDescription =
      experience?.productDescription[lang] +
      " " +
      t("date") +
      ": " +
      moment(date).format("DD-MM-YYYY") +
      ". " +
      people;

    const items = [
      {
        id: experience?.id,
        title: productDescription,
        description: productDescription,
        picture_url:
          "https://lh6.googleusercontent.com/f2dWePJVIYrpCfCm746TtfpbGphbZA2DAyLhje-1xmWxFWZ2lgQuVCNEEhHtSq1bZBs=w2400",
        currency_id: "BRL",
        category_id: experience.categories[0],
        quantity: 1,
        unit_price: total,
      },
    ];

    navigate("/login?redirect-to=checkout/mp", {
      state: {
        items,
        productInfo: {
          id: experience.id,
          category: experience.categories[0],
          date: moment(date).format(),
          totalPrice: total,
          img: experience.img,
          productDescription,
          title: experience?.title[lang],
        },
      },
    });
  };

  return (
    <Card className="bg-light border-light shadow mb-3">
      <Card.Body className="px-4 pt-4 pb-2">
        <Row>
          <Col>
            <div className="fw-bold fs-4">
              {experience.title[lang]}
              <span className="ms-1 text-muted fs-6">
                {" "}
                {experience.location}
              </span>
            </div>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="text-center">
            <span className="form-text">{t("pick_the_date")}</span>
            <DatePicker
              selected={date}
              minDate={minDate}
              maxDate={
                new Date(Date.now() + MAX_BOOKING_ADVANCE * 24 * 60 * 60 * 1000)
              }
              onChange={(date) => setDate(date)}
              inline
            />
          </Col>
          <Col lg={5} className="text-center">
            <span className="form-text">{t("number_of_people")}</span>
            {tickets?.map((t, i) => (
              <Card className="p-2 mb-1">
                <Card.Title className="text-small fs-6">
                  <small className="form-text">{`${t.type}:`}</small>
                </Card.Title>
                <TicketsCounter
                  state={tickets}
                  setState={setTickets}
                  type={t.type}
                  index={i}
                />
                <small className="form-text">{`R$${t.price},00`}</small>
              </Card>
            ))}

            <div className="form-text text-danger">
              {sumBy(tickets, "quantity") === 0 && t("at_least_one")}
              {sumBy(tickets, "quantity") >= MAX_TICKETS_ON_SALE &&
                t("max_reached")}
            </div>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col>
            <div className="p-3">
              <ListGroup variant="flush">
                {tickets?.map((t) => {
                  if (t.quantity > 0)
                    return (
                      <ListGroup.Item className="d-flex justify-content-between align-items-start">
                        <span className="me-auto">
                          {t.quantity}
                          <small className="text-muted me-2">x </small>
                          {t.type}
                        </span>
                        <span>R${t.quantity * t.price},00</span>
                      </ListGroup.Item>
                    );
                  return "";
                })}

                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <strong className="me-auto">{t("total")}</strong>
                  <span>R${total},00</span>
                </ListGroup.Item>
              </ListGroup>
              <Button
                variant="danger"
                size="lg"
                className="mt-3 w-100"
                disabled={total === 0}
                onClick={() => handleSubmit()}
              >
                {t("buy")}
              </Button>
              <div className="me-5 mt-2 text-small">
                {t("contract_disclaimer")}{" "}
                <Link to="/policies/pt/sales-contract">{t("contract")}</Link>.
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TicketsBooker;
