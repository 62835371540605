import React from "react";
import { Button, Card } from "react-bootstrap";
import { BsCalendar3 } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Course } from "../../interfaces/course";

function CourseCard({ course }: { course: Course }) {
  const navigate = useNavigate();

  return (
    <Card className="bg-light border-light same-height-imgs h-100 shadow">
      <Link to={`/course/${course.id}`} className="no-style-link">
        <img
          src={course.photo}
          alt={course.name}
          className="rounded-2 card-img-top"
        />
      </Link>
      <Card.Body className="d-flex flex-column">
        <div className="text-center mt-2 mb-auto">
          <img
            src={`/images/logos/${course.certifiedBy.toLowerCase()}.png`}
            height="50px"
            alt={course.certifiedBy}
          />
          <div className="fw-bold fs-4 mt-2">
            <Link to={`/course/${course.id}`} className="no-style-link">
              {course.name}
            </Link>
          </div>
        </div>
        <hr />
        <div className="text-center my-2 d-flex flex-column">
          <div className="display-3 mb-3">
            <small className="fs-5">R$</small>
            {course.reservationPrice}
            <small className="text-muted fs-5">,00</small>
            <div className="small text-muted fs-6">
              para fazer uma reserva <br />
              investimento total <strong>R${course.price},00</strong>
            </div>
          </div>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              navigate(`/course/${course.id}`);
            }}
          >
            <BsCalendar3 size="30" />
            <span className="ms-2"> Verifique a disponibilidade</span>
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CourseCard;
