import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { find } from "lodash";
import { EXPERIENCES } from "../../data/experiences";
import { Experience as XP } from "../../interfaces/experience";
import i18next from "i18next";
import TicketsBooker from "../../components/Booker/TicketsBooker";
import DiveBookingInfo from "../../components/BookingInfo/DiveBookingInfo";
import FeaturesList from "../../components/BookingInfo/FeaturesList";
import ContactCard from "../../components/Cards/ContactCard";

function Experience() {
  const [experience, setExperience] = useState<XP | null>(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18next.language === "pt" ? "pt" : "en";

  useEffect(() => {
    const findExperience = find(EXPERIENCES, { id });
    findExperience ? setExperience(findExperience) : navigate("/not-found");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [experience]);

  return (
    <>
      <Helmet>
        <title>{experience?.title[lang]}</title>
        <meta name="description" content={experience?.description[lang]} />
        <meta
          name="keywords"
          content={`${experience?.location}, ${JSON.stringify(
            experience?.categories
          )}`}
        ></meta>
      </Helmet>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/where-we-are" className="no-style-link">
              {" "}
              {t("where_we_are")}
            </Link>{" "}
            /{" "}
            <Link
              to={`/where-we-are/${experience?.locationId}`}
              className="no-style-link"
            >
              {experience?.location}
            </Link>{" "}
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{experience?.title[lang]}</h3>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={6}>
            <img
              src={experience?.img}
              alt={experience?.title[lang]}
              className="img-fluid rounded-2 shadow mb-4"
            />
          </Col>
          <Col lg={6}>
            {experience && <TicketsBooker experience={experience} />}
          </Col>
        </Row>
        {experience?.features && (
          <FeaturesList features={experience?.features} />
        )}
        <DiveBookingInfo />
        <ContactCard />
      </Container>
      <Footer />
    </>
  );
}

export default Experience;
