import i18n from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { CONTACT_US_PT } from "./pt/contactUs";
import { FAQ_PT } from "./pt/faq";
import { DIVE_BOOKING_INFO_PT } from "./pt/diveBookingInfo";
import { COURSE_BOOKING_INFO_PT } from "./pt/courseBookingInfo";
import { TICKETS_BOOKER } from "./pt/ticketsBooker";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      // eslint-disable-next-line no-unused-vars
      format: function (value, format, lng) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      pt: {
        translation: TRANSLATIONS_PT,
        contactUs: CONTACT_US_PT,
        faq: FAQ_PT,
        diveBookingInfo: DIVE_BOOKING_INFO_PT,
        courseBookingInfo: COURSE_BOOKING_INFO_PT,
        ticketsBooker: TICKETS_BOOKER,
      },
    },
  });

i18n.changeLanguage("pt");
