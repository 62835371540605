import React from "react";
import { Button, Card } from "react-bootstrap";
import { RxCalendar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { Course } from "../../interfaces/course";

function CourseCardSm({ course }: { course: Course }) {
  const navigate = useNavigate();

  return (
    <Card className="same-height-imgs h-100 shadow">
      <Card.Body>
        <div className="text-center mt-3">
          <img
            src={`/images/logos/${course.certifiedBy.toLowerCase()}.png`}
            height="50px"
            alt={course.certifiedBy}
          />
          <div className="fw-bold fs-4">{course.name}</div>
        </div>

        <div className="text-center my-3">
          <div className="display-3 mb-3">
            <small className="fs-5">R$</small>
            {course.reservationPrice}
            <small className="text-muted fs-5">,00</small>
            <div className="small text-muted fs-6">
              para fazer uma reserva <br />
              investimento total <strong>R${course.price},00</strong>
            </div>
          </div>
          <Button
            className="rounded-2"
            onClick={() => {
              navigate(`/course/${course.id}`);
            }}
          >
            <RxCalendar size="30" />
            <span className="ms-2">verificar disponibilidade</span>
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CourseCardSm;
