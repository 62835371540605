import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { filter } from "lodash";
import { DIVELIST } from "../../data/dives";
import { Dive } from "../../interfaces/dive";
import DiveCard from "../../components/Cards/DiveCard";

function DivingIn() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [dives, setDives] = useState<Array<Dive> | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const diveList = filter(DIVELIST, (d) => d.locationId === id);
    if (!diveList || diveList.length < 1) {
      navigate("/");
      return;
    }
    setDives(diveList);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>
          {dives && dives[0].location
            ? `Mergulhe em ${dives[0]?.location} - GDN`
            : `${t("mergulhe")} - GDN`}
        </title>
        <meta
          name="description"
          content={
            dives && dives[0].location
              ? `Agende mergulho de batismo ou mergulho certificado em ${dives[0]?.location}`
              : "Mergulhe com a GDN"
          }
        />
      </Helmet>
      <NavBar />
      <Container className="mt-0 p-3">
        <Row>
          <Col className="small text-mutted fw-lighter">
            <Link to="/where-we-are" className="no-style-link">
              {" "}
              {t("where_we_are")}
            </Link>{" "}
            /{" "}
            <Link to={`/where-we-are/${id}`} className="no-style-link">
              {dives && dives[0].location ? `${dives[0].location}` : ""}
            </Link>{" "}
            / Mergulhe
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              {t("dive_in")}{" "}
              {dives && dives[0].location ? `${dives[0].location}` : ""}
            </h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} className="gx-4 gy-3">
          {dives?.map((d, i) => (
            <Col>
              <DiveCard dive={d} />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default DivingIn;
