export const DIVE_BOOKING_INFO_PT = {
  payment_info: "Pagamento e cancelamentos:",
  h1: "Garanta o melhor dia para você",
  i1: "Reserve online com antecedência em nosso site, e cuidaremos de toda a preparação para você.",
  h2: "Parcele em até 12x",
  i2: "Pague em 12x com até 2 cartões de crédito, débito ou pix.",
  h3: "Em caso de clima não favorável/não alcance do número mínimo de pessoas necessárias",
  i3: "Nós cancelamos a saída com antecedência, reagendando ou reembolsando o valor.",
  h4: "Reagendamento/ Cancelamento grátis antecipado",
  i4: "Reagende ou cancele com até 72h de antecedência com reembolso (com cobrança de taxa administrativa de 5%).",
  h5: "Direito ao arrependimento de compra",
  i5: "Tendo em vista que os serviços de mergulho e os cursos/treinamentos possuem custos de operação prévia, como preparação de equipamento, alocação de staff, entre outras atividades imprescindíveis à boa execução dos serviços, qualquer cancelamento sem ônus com base no artigo 49 do Código de Defesa do Consumidor poderá ser realizado até 72 (setenta e duas) horas antes do horário previamente agendado pela operadora, para atividades de mergulho. O Código de Defesa do Consumidor é aplicável quando o prazo limite do mesmo não estiver dentro do período de preparação informado acima, de modo que não cause prejuízo aos parceiros.",
};
