import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BiUser } from "react-icons/bi";
import { VscEdit } from "react-icons/vsc";
import { AiOutlineCamera } from "react-icons/ai";

function ProfileMenu() {
  const menuActiveStyle = {
    color: "black",
  };

  return (
    <>
      <ButtonGroup className="w-100 border border-light">
        <Button
          variant="outline-dark"
          className="border border-grey text-secondary"
        >
          <NavLink
            to="profile"
            style={({ isActive }) => (isActive ? menuActiveStyle : undefined)}
            className="no-style-link"
          >
            <div>
              <BiUser size="30"></BiUser>
            </div>
          </NavLink>
        </Button>
        <Button
          variant="outline-dark"
          className="border border-grey text-secondary"
        >
          <NavLink
            to="edit-profile"
            style={({ isActive }) => (isActive ? menuActiveStyle : undefined)}
            className="no-style-link"
          >
            <div>
              <VscEdit size="30"></VscEdit>
            </div>
          </NavLink>
        </Button>
        <Button
          variant="outline-dark"
          className="border border-grey text-secondary"
        >
          <NavLink
            to="profile-photo"
            style={({ isActive }) => (isActive ? menuActiveStyle : undefined)}
            className="no-style-link"
          >
            <div>
              <AiOutlineCamera size="30"></AiOutlineCamera>
            </div>
          </NavLink>
        </Button>
        {/* <Button variant="outline-dark" className="border border-grey">
          <NavLink
            to="diver-profile"
            style={({ isActive }) => (isActive ? menuActiveStyle : undefined)}
            className="no-style-link"
          >
            <div>
              <TbScubaMask size="34"></TbScubaMask>
            </div>
            <div>{t("diver_profile")}</div>
          </NavLink>
        </Button> */}
      </ButtonGroup>
    </>
  );
}

export default ProfileMenu;
