import { concat, filter } from "lodash";
import { Course } from "../interfaces/course";

export const courses = [
  {
    title: "Open water scuba diver",
    text: "",
    img: "/images/courses/open-water-course-arraial-do-cabo.jpeg",
    level: "basic",
    link: "/courses/open-water",
  },
  {
    title: "Advanced open water diver",
    text: "",
    img: "/images/courses/advanced-open-water-course-turtle.jpeg",
    level: "advanced",
  },
  {
    title: "Underwater photographer",
    text: "",
    img: "/images/courses/specialities-digital-underwater-photograher.jpg",
    level: "specialty",
  },

  {
    title: "Deep diver",
    text: "",
    img: "/images/courses/deep-diver-course.JPG",
    level: "advanced",
  },
  {
    title: "Divemaster",
    text: "",
    img: "/images/courses/divemaster-professional-course.jpg",
    level: "professional",
  },
  {
    title: "Night diver",
    text: "",
    img: "/images/courses/night-diver-course.jpeg",
    level: "advanced",
  },
  {
    title: "Underwater Navigation",
    text: "",
    img: "/images/courses/underwater-navigation-diver-course-y320.jpg",
    level: "advanced",
  },
  {
    title: "Search and Recovery",
    text: "",
    img: "/images/courses/search-and-recovery-diver-course-y320.png",
    level: "advanced",
  },
  {
    title: "Wreck diver",
    text: "",
    img: "/images/courses/wreck-diver-course.jpg",
    level: "advanced",
  },
  {
    title: "Rescue diver",
    text: "",
    img: "/images/courses/rescue-diver-course-y320.jpg",
    level: "specialty",
  },
  {
    title: "Peak performance buoyancy",
    text: "",
    img: "/images/courses/buoyancy-course.jpg",
    level: "specialty",
  },
  {
    title: "Underwater Videographer",
    text: "",
    img: "/images/courses/underwater-videographer-course-y320.jpg",
    level: "specialty",
  },
];

export const owPrerequisites = [
  "Idade mínima: 10 anos",
  "É obrigatório a apresentação de um atestado médico liberando a pessoa intressada para a prática do mergulho.",
];

export const mergulhoMagico: Array<Course> = [
  {
    id: "mergulhoMagico-PADI-OW",
    name: "Open Water Diver",
    category: "Open Water",
    locationId: "saoJoseDaCoroaGrande-PE",
    location: "São José da Coroa Grande - PE",
    locationPhoto: "/images/locations/sao-jose-da-coroa-grande.jpg",
    certifiedBy: "PADI",
    diveCenter: "Mergulho Mágico",
    price: 2200,
    reservationPrice: 220,
    description:
      "Curso de mergulho autônomo em águas abertas, homologado internacionalmente pela PADI e ministrado em São José da Coroa Grande - PE",
    photo: "/images/courses/PADI/PADI-open-water.jpg",
  },
  {
    id: "mergulhoMagico-PADI-AOW",
    name: "Advanced Open Water Diver",
    category: "Advanced Open Water",
    locationId: "saoJoseDaCoroaGrande-PE",
    location: "São José da Coroa Grande - PE",
    locationPhoto: "/images/locations/sao-jose-da-coroa-grande.jpg",
    certifiedBy: "PADI",
    diveCenter: "Mergulho Mágico",
    price: 2500,
    reservationPrice: 250,
    description:
      "Curso avançado de mergulho autônomo em águas abertas, homologado internacionalmente pela PADI e ministrado em São José da Coroa Grande - PE",
    photo: "/images/courses/PADI/PADI-advanced-open-water.jpg",
  },
  {
    id: "mergulhoMagico-PADI-rescue",
    name: "Rescue Diver",
    category: "Rescue",
    locationId: "saoJoseDaCoroaGrande-PE",
    location: "São José da Coroa Grande - PE",
    locationPhoto: "/images/locations/sao-jose-da-coroa-grande.jpg",
    certifiedBy: "PADI",
    diveCenter: "Mergulho Mágico",
    price: 3500,
    reservationPrice: 350,
    description:
      "Curso para mergulhador de resgate (Rescue Diver), homologado internacionalmente pela PADI e ministrado em São José da Coroa Grande - PE",
    photo: "/images/courses/PADI/PADI-rescue.jpg",
  },
];

export const buziosDivers: Array<Course> = [
  {
    id: "buziosDivers-PADI-OW",
    name: "Open Water Diver",
    category: "Open Water",
    locationId: "buzios-RJ",
    location: "Armação dos Búzios - RJ",
    locationPhoto: "/images/locations/buzios.jpeg",
    certifiedBy: "PADI",
    diveCenter: "Búzios Divers",
    price: 2000,
    reservationPrice: 200,
    description:
      "Curso de mergulho autônomo em águas abertas (Open Water Diver), homologado internacionalmente pela PADI e ministrado em Armação dos Búzios - RJ",
    photo: "/images/courses/PADI/PADI-open-water.jpg",
  },
  {
    id: "buziosDivers-SDI-OW",
    name: "Open Water Scuba Diver",
    category: "Open Water",
    locationId: "buzios-RJ",
    location: "Armação dos Búzios - RJ",
    locationPhoto: "/images/locations/buzios.jpeg",
    certifiedBy: "SDI",
    diveCenter: "Búzios Divers",
    price: 2000,
    reservationPrice: 200,
    description:
      "Curso de mergulho autônomo em águas abertas (Open Water Scuba Diver), homologado internacionalmente pela SDI e ministrado em Armação dos Búzios - RJ",
    photo: "/images/courses/SDI/SDI-open-water.jpg",
  },
  {
    id: "buziosDivers-PADI-AOW",
    name: "Advanced Open Water Diver",
    category: "Advanced Open Water",
    locationId: "buzios-RJ",
    location: "Armação dos Búzios - RJ",
    locationPhoto: "/images/courses/PADI/PADI-open-water.jpg",
    certifiedBy: "PADI",
    diveCenter: "Búzios Divers",
    price: 2000,
    reservationPrice: 200,
    description:
      "Curso avançado de mergulho autônomo em águas abertas (Advanced Open Water Diver), homologado internacionalmente pela PADI e ministrado em Armação dos Búzios - RJ",
    photo: "/images/courses/PADI/PADI-advanced-open-water.jpg",
  },
  {
    id: "buziosDivers-SDI-AOW",
    name: "Advanced Adventure Diver",
    category: "Advanced Open Water",
    locationId: "buzios-RJ",
    location: "Armação dos Búzios - RJ",
    locationPhoto: "/images/locations/buzios.jpeg",
    certifiedBy: "SDI",
    diveCenter: "Búzios Divers",
    price: 2000,
    reservationPrice: 200,
    description:
      "Curso avançado de mergulho autônomo em águas abertas (Advanced Adventure Diver), homologado internacionalmente pela SDI e ministrado em Armação dos Búzios - RJ",
    photo: "/images/courses/SDI/SDI-advanced-adventure-diver.jpg",
  },
];

export const prados: Array<Course> = [
  {
    id: "prados-SSI-OW",
    name: "Open Water Diver",
    category: "Open Water",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 2350,
    reservationPrice: 235,
    description:
      "Curso de mergulho autônomo em águas abertas, homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-open-water-diver.jpeg",
  },
  {
    id: "prados-SSI-equipment-techniques",
    name: "Equipment Techniques",
    category: "Equipment Techniques",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 950,
    reservationPrice: 95,
    description:
      "Especialidade em técnicas de equipamento (Equipment techniques), homologada internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-equipment-techniques.jpeg",
  },
  {
    id: "prados-SSI-navigation",
    name: "Navigation",
    category: "Navigation",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 950,
    reservationPrice: 95,
    description:
      "Especialidade técnica de Navegação (Navigation), homologada internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-navigation.jpeg",
  },
  {
    id: "prados-SSI-boat-diving",
    name: "Boat Diving",
    category: "Boat Diving",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 1050,
    reservationPrice: 105,
    description:
      "Especialidade técnica de mergulho Boat Diving, homologada internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-boat-diving.jpeg",
  },
  // {
  //   id: "prados-SSI-dive-master",
  //   name: "Dive Master",
  //   category: "Dive Master",
  //   locationId: "ilhabela-SP",
  //   location: "Ilhabela - SP",
  //   locationPhoto: "/images/locations/ilhabela.jpg",
  //   certifiedBy: "SSI",
  //   diveCenter: "Prado's",
  //   price: ,
  //   reservationPrice: ,
  //   description:
  //     "Curso de Dive Master, homologada internacionalmente pela SSI e ministrado em Ilhabela - SP",
  // },
  {
    id: "prados-SSI-dive-guide",
    name: "Dive Guide",
    category: "Dive Guide",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 5000,
    reservationPrice: 500,
    description:
      "Curso de Dive Guide, homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-dive-guide.jpeg",
  },
  {
    id: "prados-SSI-night-diver",
    name: "Night & Limited Visibility",
    category: "Night Diver",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 1050,
    reservationPrice: 150,
    description:
      "Curso de mergulhador Noturno e de baixa luminosidade (Night & Limited Visibility), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-night-&-limited-visibility.jpeg",
  },
  {
    id: "prados-SSI-nitrox",
    name: "Enriched Air Nitrox",
    category: "Nitrox",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 650,
    reservationPrice: 65,
    description:
      "Curso de Nitrox (Enriched Air Nitrox), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-enriched-air-nitrox.jpeg",
  },
  {
    id: "prados-SSI-rescue",
    name: "Diver Stress & Rescue + React Right",
    category: "Rescue",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 2350,
    reservationPrice: 235,
    description:
      "Curso de mergulhador de resgate (Diver Stress & Rescue + React Right), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-rescue.jpeg",
  },
  {
    id: "prados-SSI-sidemount",
    name: "Recreational Sidemount Diving",
    category: "Sidemount",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 1550,
    reservationPrice: 155,
    description:
      "Curso de mergulho com Sidemount (Recreational Sidemount Diving), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-recreational-sidemount-diving.jpeg",
  },
  {
    id: "prados-SSI-search-and-recovery",
    name: "Search & Recovery",
    category: "Search and Recovery",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 950,
    reservationPrice: 95,
    description:
      "Curso de busca e recuperação de objetos (Search & Recovery), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-deep-diving.jpeg",
  },
  {
    id: "prados-SSI-wreck",
    name: "Wreck Diving",
    category: "Wreck",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 1050,
    reservationPrice: 105,
    description:
      "Curso de mergulho em Naufrágio (Wreck Diving), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-wreck-diving.jpeg",
  },
  {
    id: "prados-SSI-buoyancy",
    name: "Perfect Buoyancy",
    category: "Buoyancy",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 950,
    reservationPrice: 95,
    description:
      "Curso de especialização em flutuabilidade (Perfect Buoyancy), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-perfect-buoyancy.jpeg",
  },
  {
    id: "prados-SSI-marine-ecology",
    name: "Marine Ecology",
    category: "Ecology",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 750,
    reservationPrice: 75,
    description:
      "Curso de Ecologia Marinha (Marine Ecology), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-science-of-diving.jpeg",
  },
  {
    id: "prados-SSI-snorkel-instructor",
    name: "Snorkel Instructor",
    category: "Snorkel",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 550,
    reservationPrice: 55,
    description:
      "Curso de Instrutor de Esnórquel (Snorkel Instructor), homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-snorkel-instructor.jpeg",
  },
  {
    id: "prados-SSI-classified-buddy",
    name: "Classified Diving + Classified Buddy",
    category: "Classified Diving",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "SSI",
    diveCenter: "Prado's Dive",
    price: 5000,
    reservationPrice: 500,
    description:
      "Classified Diving + Classified buddy, homologado internacionalmente pela SSI e ministrado em Ilhabela - SP",
    photo: "/images/courses/SSI/SSI-equipment-techniques.jpeg",
  },
  {
    id: "prados-CMAS-one-star",
    name: "One Star Diver",
    category: "Open Water",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "CMAS",
    diveCenter: "Prado's Dive",
    price: 1850,
    reservationPrice: 185,
    description:
      "Curso One Star Diver, homologado internacionalmente pela CMAS e ministrado em Ilhabela - SP",
    photo: "/images/courses/CMAS/CMAS-one-star-diver.png",
  },
  {
    id: "prados-CMAS-two-stars",
    name: "Two Stars Diver",
    category: "Advanced Open Water",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "CMAS",
    diveCenter: "Prado's Dive",
    price: 2350,
    reservationPrice: 235,
    description:
      "Curso Two Stars Diver, homologado internacionalmente pela CMAS e ministrado em Ilhabela - SP",
    photo: "/images/courses/CMAS/CMAS-two-stars-diver.png",
  },
  {
    id: "prados-CMAS-three-stars",
    name: "Three Stars Diver",
    category: "Advanced Open Water",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "CMAS",
    diveCenter: "Prado's Dive",
    price: 5000,
    reservationPrice: 500,
    description:
      "Curso Three Stars Diver, homologado internacionalmente pela CMAS e ministrado em Ilhabela - SP",
    photo: "/images/courses/CMAS/CMAS-three-stars-diver.png",
  },
  {
    id: "prados-CMAS-four-stars",
    name: "Four Stars Diver",
    category: "Advanced Open Water",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "CMAS",
    diveCenter: "Prado's Dive",
    price: 1000,
    reservationPrice: 100,
    description:
      "Curso Four Stars Diver, homologado internacionalmente pela CMAS e ministrado em Ilhabela - SP",
    photo: "/images/courses/CMAS/CMAS-four-stars-diver.png",
  },
  {
    id: "prados-CMAS-one-star-instructor",
    name: "Instructor One Star Diver",
    category: "Instructor",
    locationId: "ilhabela-SP",
    location: "Ilhabela - SP",
    locationPhoto: "/images/locations/ilhabela.jpg",
    certifiedBy: "CMAS",
    diveCenter: "Prado's Dive",
    price: 8000,
    reservationPrice: 800,
    description:
      "Curso Instructor One Star Diver, homologado internacionalmente pela CMAS e ministrado em Ilhabela - SP",
    photo: "/images/courses/CMAS/CMAS-one-star-instructor.png",
  },
];

export const beachBahia: Array<Course> = [
  {
    id: "beachBahia-PADI-OW",
    name: "Open Water Diver",
    category: "Open Water",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 2440,
    reservationPrice: 244,
    description:
      "Curso de mergulho autônomo em águas abertas, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-open-water.jpg",
  },
  {
    id: "beachBahia-PADI-AOW",
    name: "Advanced Open Water Diver",
    category: "Advanced Open Water",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 2440,
    reservationPrice: 244,
    description:
      "Curso avançado de mergulho autônomo em águas abertas, com introdução às especialidades: Deep Diver; Underwater Navigation; Search and Recovery; Night Diver; Wreck Diver. Homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-advanced-open-water.jpg",
  },
  {
    id: "beachBahia-PADI-sealTeam",
    name: "PADI Seal Team",
    category: "Youth",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 500,
    reservationPrice: 50,
    description: "Curso PADI Seal Team ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-seal-team.jpg",
  },
  {
    id: "beachBahia-PADI-rescue",
    name: "Rescue Diver",
    category: "Rescue",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1950,
    reservationPrice: 195,
    description:
      "Curso para mergulhador de resgate (Rescue Diver), homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-rescue.jpg",
  },
  {
    id: "beachBahia-PADI-oxigen-provider",
    name: "EPROX - Emergency Oxygen Provider",
    category: "Rescue",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 800,
    reservationPrice: 80,
    description:
      "Curso PADI: EPROX - Emergency Oxygen Provider. Homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-oxigen-provider.jpg",
  },
  {
    id: "beachBahia-PADI-nitrox",
    name: "Enriched Air (Nitrox) Diver",
    category: "Nitrox",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1100,
    reservationPrice: 110,
    description:
      "Curso de mergulho com Nitrox, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-nitrox.jpg",
  },
  {
    id: "beachBahia-PADI-emergencyFirstResponse",
    name: "Emergency First Response",
    category: "Rescue",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1100,
    reservationPrice: 110,
    description:
      "Curso PADI Emergency First Response (Primeiros socorros), homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-emergency-first-response.jpg",
  },
  {
    id: "beachBahia-PADI-deepDiver",
    name: "Deep Diver",
    category: "Deep Diver",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso de mergulho Deep Diver, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-deep-diver.jpg",
  },
  {
    id: "beachBahia-PADI-divemaster",
    name: "Dive Master",
    category: "Dive Master",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 5200,
    reservationPrice: 520,
    description:
      "Curso para Dive Master, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-divemaster.jpg",
  },
  {
    id: "beachBahia-PADI-nightDiver",
    name: "Night Diver",
    category: "Night Diver",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso de mergulho noturno (Night Diver), homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-night-diver.jpg",
  },
  {
    id: "beachBahia-PADI-underwaterNavigation",
    name: "Underwater Navigation",
    category: "Navigation",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso Underwater Navigation, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-underwater-navigation.jpg",
  },
  {
    id: "beachBahia-PADI-searchAndRecovery",
    name: "Search and Recovery",
    category: "Search and Recovery",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso Search and Recovery, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-search-and-recovery.jpg",
  },
  {
    id: "beachBahia-PADI-wreckDiver",
    name: "Wreck Diver",
    category: "Wreck",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso de mergulho PADI Wreck Diver, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-wreck-diver.jpg",
  },
  {
    id: "beachBahia-PADI-buoyancy",
    name: "Peak Performance Buoyancy",
    category: "Buoyancy",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso de mergulho PADI Peak Performance Buoyancy, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-buoyancy.jpg",
  },
  {
    id: "beachBahia-PADI-underwaterPhotogtapher",
    name: "Digital Underwater Photographer",
    category: "Photo",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 1500,
    reservationPrice: 150,
    description:
      "Curso Digital Underwater Photographer, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-photographer.jpg",
  },
  {
    id: "beachBahia-PADI-reActivate",
    name: "ReActivate - Scuba Refresh",
    category: "Refresh",
    locationId: "portoSeguro-BA",
    location: "Porto Seguro - BA",
    locationPhoto: "/images/locations/porto-seguro.jpg",
    certifiedBy: "PADI",
    diveCenter: "Beach Bahia",
    price: 450,
    reservationPrice: 45,
    description:
      "Curso ReActivate - Scuba Refresh, homologado internacionalmente pela PADI e ministrado em Porto Seguro - BA",
    photo: "/images/courses/PADI/PADI-reActivate.jpg",
  },
];

export const COURSES = concat(mergulhoMagico, buziosDivers, prados, beachBahia);

export const openWater = filter(COURSES, (c) => c.category === "Open Water");
