import React, { useEffect } from "react";
import { values } from "lodash";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";

function Tags({
  tagList,
  checkedTags,
}: {
  tagList: Array<string>;
  checkedTags: Array<string>;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (checkedTags) {
      values(checkedTags).forEach((t: string) => {
        const element = document.getElementById(t) as HTMLInputElement;
        if (element && !element.checked) element.click();
      });
    }
  }, []);

  const tags = tagList.map((tag, i) => (
    <ToggleButtonGroup type="checkbox">
      <ToggleButton
        key={i}
        id={tag}
        name="tags"
        value={tag}
        variant="outline-dark"
        className="m-1"
      >
        {t(tag)}
      </ToggleButton>
    </ToggleButtonGroup>
  ));

  return <>{tags}</>;
}

export default Tags;
