import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./cookieConsent.css";

function CookieConsent() {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const getLS = localStorage.getItem("trmm-lcl-strg");
    if (!getLS) return;
    setShow(false);
  }, []);

  const alert = (
    <Alert
      variant="primary"
      className="text-center small fixed-bottom bottom-compensation"
      onClose={() => {
        localStorage.setItem("trmm-lcl-strg", JSON.stringify("true"));
        setShow(false);
      }}
      dismissible
    >
      {t("use_cookies")}. Ao prosseguir você atesta que concorda com nossa{" "}
      <Link
        to="/policies/pt/politica-de-cookies"
        target="_blank"
        rel="noreferrer"
      >
        política.
      </Link>
    </Alert>
  );

  return show ? alert : <></>;
}

export default CookieConsent;
