import React from "react";
import { Button, Card } from "react-bootstrap";
import { RxCalendar } from "react-icons/rx";
import { Dive } from "../../interfaces/dive";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import DiveIconProps from "../DiveDetails/DiveIconProps";

function DiveCard({
  dive,
  showLocation,
}: {
  dive: Dive;
  showLocation?: boolean;
}) {
  const lang = i18next.language === "pt" ? "pt" : "en";
  const navigate = useNavigate();

  return (
    <Card className="bg-light same-height-imgs h-100 shadow">
      <Link to={`/dive/${dive.id}`} className="no-style-link">
        <Card.Img
          className="border-0 card-img-top"
          variant="top"
          src={dive.img}
        />
      </Link>
      <Card.Title className="mb-auto px-2 mt-1">
        <Link to={`/dive/${dive.id}`} className="no-style-link">
          {showLocation ? (
            <div className="text-muted mb-1">{dive.location}</div>
          ) : (
            ""
          )}
          <strong className="fs-5">{dive.title[lang]}</strong>
        </Link>
      </Card.Title>
      <div className="ps-2">
        <DiveIconProps dive={dive} />
      </div>
      <Card.Body className="d-flex flex-column">
        <div className="text-center mt-auto">
          <small className="text-muted">a partir de </small>
          <small className="text-muted">R$</small>
          <small className="h2 display-6">{dive.price}</small>
          <small className="fs-6 text-muted ">,00 </small>
          <small className="text-muted ">em até 12x</small>
          <br />
          <small className="text-muted ">
            utilize mais de um cartão ou pague via PIX ou boleto
          </small>
        </div>
      </Card.Body>
      <Button
        variant="danger"
        className="rounded-0 mt-2 w-100"
        onClick={() => navigate(`/dive/${dive.id}`)}
      >
        <RxCalendar size="35" /> Verificar disponibilidade
      </Button>
    </Card>
  );
}

export default DiveCard;
