import { BsCamera, BsClock, BsMap } from "react-icons/bs";
import { DiveCenter } from "../interfaces/diveCenter";
import { Experience } from "../interfaces/experience";
import { LocationId } from "../interfaces/location";
import { MdOutlineBeachAccess, MdOutlineHotel } from "react-icons/md";
import { concat } from "lodash";

export const portoSeguro: Array<Experience> = [
  {
    id: "portoSeguro-buggy-from-cabralia",
    title: {
      pt: "Passeio de buggy com guia em Santa Cruz Cabralia",
      en: "TO-DO",
    },
    description: {
      pt: "Passeio de Buggy com guia pelo centro historico de Santa Cruz Cabralia saindo de Coroa Vermelha ou Santa Cruz Cabralia",
      en: "TO-DO",
    },
    location: "Porto Seguro - BA",
    locationId: "portoSeguro-BA" as LocationId,
    diveCenter: "Beach Bahia" as DiveCenter,
    img: "/images/experiences/portoSeguro/buggy-2.jpg",
    productDescription: {
      pt: "Passeio de Buggy com guia pelo centro historico de Santa Cruz Cabralia saindo de Coroa Vermelha ou Santa Cruz Cabralia.",
      en: "TO-DO",
    },
    categories: ["buggy", "tours", "guided visit"],
    tickets: [
      {
        type: { pt: "Adultos", en: "TO-DO" },
        price: { value: 150, currency: "BRL" },
      },
      {
        type: { pt: "Crianças (de 4 até 7 anos)", en: "TO-DO" },
        price: { value: 80, currency: "BRL" },
      },
    ],
    features: [
      {
        title: { pt: "Visita guiada" },
        description: {
          pt: "Passeio pelo centro histórico de Santa Cruz Cabralia, com visita guiada, contando um pouco de sua história.",
        },
        icon: <BsMap size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Paradas em praias" },
        description: {
          pt: "Durante o passeio de buggy são feitas algumas paradas em praias e um mirante, para fotos. Dependendo da maré, se torna possível também curtir o caminho de Moisés.",
        },
        icon: <MdOutlineBeachAccess size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Fotos" },
        description: {
          pt: "Após o passeio, serão enviadas as fotos realizadas durante o passeio sem nenhum custo adicional.",
        },
        icon: <BsCamera size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Saída e chegada no local de sua hospedagem" },
        description: {
          pt: "Para sua maior comodidade, o passeio tem início e fim no local da sua hospedagem. Buscamos você no seu hotel ou pousada em Coroa Vermelha ou Santa Cruz Cabralia, com retorno ao final do passeio, sem nenhum custo adicional. Após a confirmação de pagamento, entraremos em contato para combinar o local e o melhor horário.",
        },
        icon: <MdOutlineHotel size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Duração" },
        description: {
          pt: "O passeio tem duração aproximada de 3h a 3h30min, a depender do ponto de saída.",
        },
        icon: <BsClock size="30" className="ms-2 mt-1" />,
      },
    ],
  },
  {
    id: "portoSeguro-buggy",
    title: {
      pt: "Passeio de buggy com guia em Santa Cruz Cabralia a partir de Porto Seguro",
      en: "TO-DO",
    },
    description: {
      pt: "Passeio de Buggy com guia pelo centro historico de Santa Cruz Cabralia saindo de Porto Seguro",
      en: "TO-DO",
    },
    location: "Porto Seguro - BA",
    locationId: "portoSeguro-BA" as LocationId,
    diveCenter: "Beach Bahia" as DiveCenter,
    img: "/images/experiences/portoSeguro/buggy.jpg",
    productDescription: {
      pt: "Passeio de Buggy com guia pelo centro historico de Santa Cruz Cabralia saindo de Porto Seguro.",
      en: "TO-DO",
    },
    categories: ["buggy", "tours", "guided visit"],
    tickets: [
      {
        type: { pt: "Adultos", en: "TO-DO" },
        price: { value: 190, currency: "BRL" },
      },
      {
        type: { pt: "Crianças (de 4 até 7 anos)", en: "TO-DO" },
        price: { value: 95, currency: "BRL" },
      },
    ],
    features: [
      {
        title: { pt: "Visita guiada" },
        description: {
          pt: "Passeio pelo centro histórico de Santa Cruz Cabralia, com visita guiada, contando um pouco de sua história.",
        },
        icon: <BsMap size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Paradas em praias" },
        description: {
          pt: "Durante o passeio de buggy são feitas algumas paradas em praias e um mirante, para fotos. Dependendo da maré, se torna possível também curtir o caminho de Moisés.",
        },
        icon: <MdOutlineBeachAccess size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Fotos" },
        description: {
          pt: "Após o passeio, serão enviadas as fotos realizadas durante o passeio sem nenhum custo adicional.",
        },
        icon: <BsCamera size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Saída e chegada no local de sua hospedagem" },
        description: {
          pt: "Para sua maior comodidade, o passeio tem início e fim no local da sua hospedagem. Buscamos você no seu hotel ou pousada em Porto Seguro, com retorno ao final do passeio, sem nenhum custo adicional. Após a confirmação de pagamento, entraremos em contato para combinar o local e o melhor horário.",
        },
        icon: <MdOutlineHotel size="30" className="ms-2 mt-1" />,
      },
      {
        title: { pt: "Duração" },
        description: {
          pt: "O passeio tem duração aproximada de 3h a 3h30min, a depender do ponto de saída.",
        },
        icon: <BsClock size="30" className="ms-2 mt-1" />,
      },
    ],
  },
];

export const EXPERIENCES: Array<Experience> = concat(portoSeguro);
