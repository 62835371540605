import React, { FormEvent, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { LOG_MSG_MAX_LENGTH } from "../../config/constants";
import { getAuth } from "firebase/auth";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { Comment } from "../../interfaces/interfaces";
import { db } from "../../config/firebase";

function CommentTxtArea({
  logId,
  setCommentsState,
  commentsState,
}: {
  logId: string;
  setCommentsState: Function;
  commentsState: Comment[];
}) {
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }

    if (message.trim().length === 0) {
      setMessage("");
      return;
    }

    setLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      navigate("/login");
      return;
    }

    const comment: Comment = {
      userId: user.uid,
      userName: user.displayName || "",
      userPicture: user.photoURL || "",
      messageText: message,
      length: message.length,
      timeStamp: Date.now(),
    };

    try {
      const logRef = doc(db, "logbook", logId);
      await updateDoc(logRef, {
        comments: arrayUnion(comment),
      });
      setCommentsState([...commentsState, comment]);
      setMessage("");
    } catch (err) {
      console.log(err);
      alert(t("request_error"));
    } finally {
      setLoading(false);
      setValidated(false);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Control
        as="textarea"
        style={{ height: "100px" }}
        maxLength={LOG_MSG_MAX_LENGTH}
        value={message}
        onChange={(e) => {
          setMessage(e.currentTarget.value);
        }}
        //@ts-ignore
        placeholder={t("type_your_message")}
        className="resize-none"
        required
      />
      <Form.Text className="position-absolute ms-2" muted>
        {(LOG_MSG_MAX_LENGTH - message.length).toString() +
          " " +
          t("chars_left")}
      </Form.Text>

      <div className="text-end pt-2 pe-2">
        <Button type="submit" disabled={loading} variant="success">
          {loading ? <RiseLoader size={5} color="#fff" /> : t(`send`)}
        </Button>
      </div>
    </Form>
  );
}

export default CommentTxtArea;
