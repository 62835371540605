import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Like } from "../../interfaces/interfaces";

function LikesUsersList({ likes }: { likes: Like[] }) {
  const list: any = likes.map((l) => (
    <ListGroup.Item>
      <Link className="no-style-link" to={{ pathname: `/diver/${l.userId}` }}>
        <img
          src={l?.userPicture}
          width="50px"
          height="50px"
          alt=""
          className="circle-cropper"
        ></img>
        <span className="ms-2">{l.userName}</span>
      </Link>
    </ListGroup.Item>
  ));

  return <ListGroup variant="flush">{list}</ListGroup>;
}

export default LikesUsersList;
