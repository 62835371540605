import React from "react";
import { Row, Col } from "react-bootstrap";
import { GiJellyfish } from "react-icons/gi";
import { useUser } from "../../pages/ManageProfile";

function Profile() {
  const { user } = useUser();

  return (
    <>
      <Row>
        <Col>
          <h1 className="display-6 fs-2">{user?.displayName}</h1>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col className="px-4" xs={12}>
          <div className="mb-3 mt-2 text-center">
            {user?.photoUrl ? (
              <img
                src={user?.photoUrl}
                id="imgPreview"
                //width="200px"
                height="200px"
                className="img-thumbnail"
                alt=""
              ></img>
            ) : (
              <GiJellyfish
                size={190}
                className="bg-primary border text-white border-white border-2 rounded-circle  mb-5 me-1 my-4 mb-2"
              />
            )}
          </div>

          <div className="text-center mt-2">{user?.home}</div>
        </Col>
        {/* <Col xs={2} className="text-end p-3"> */}
        {/* <Link
                className="no-style-link ms-2"
                to={{ pathname: `/edit-log/${diver.docId}` }}
              >
                {diver.userId === userContext.uid ? (
                  <BiEditAlt size={30}></BiEditAlt>
                ) : (
                  " "
                )}
              </Link> */}
        {/* </Col> */}
      </Row>
    </>
  );
}

export default Profile;
