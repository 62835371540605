import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../config/firebase";
import { onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "../locales/i18n";
import "./reset-password.css";
import GDNy from "../components/GDNbrand/GDNy";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  onAuthStateChanged(auth, (usr) => {
    if (usr) navigate("/dashboard");
  });

  const sendPasswordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert(t("password_reset_sent"));
    } catch (err: any) {
      alert(t("request_error"));
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("reset_password")} - Global Divers Network</title>
        <meta
          name="description"
          content={t("recovery_password_description") || ""}
        />
      </Helmet>
      <div className="reset">
        <div className="text-center">
          <main className="form-signin w-100 m-auto">
            <div className="mb-4">
              <GDNy />
            </div>
            <hr></hr>
            <h1 className="h3 mb-3 fw-normal">{t("forgot_password")}</h1>
            <p className="text-box">{t("password_reset_instructions")}</p>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                value={email}
                id="floatingEmail"
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="form-text">{t("will_send_reset_instructions")}</p>
              <label htmlFor="floatingEmail">{t("registered_email")}</label>
            </div>
            <button
              id="reset-btn"
              className="w-100 btn btn-lg btn-dark mt-3"
              onClick={() => {
                sendPasswordReset(email);
              }}
            >
              {t("send_password_reset")}
            </button>
            <Button
              variant="primary"
              className="w-100 btn btn-lg btn-dark mb-1 mt-2 d-none"
              id="reset-btn-disabled"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">{t("loading")}...</span>
            </Button>{" "}
            <hr></hr>
            <div>
              {t("no_account")} <Link to="/register">{t("register")}</Link>{" "}
              {t("now")}.
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
